import React, { useEffect, useState } from "react";
import {
  block_delete_ContentApi,
  getContentApi,
} from "../../../../services/cms";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import findSerialNumber from "../../../../utils/findSerialNumber";
import { CapitalizeFirstWord } from "../../../../utils/CapitalizeFirstWord";
import { dateFormat } from "../../../../utils/dateFormat";
import NotFound from "../../../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import { useSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddLanguage from "./AddLanguage";
import AddIcon from "@mui/icons-material/Add";
import Switch from "../../../../components/Switch/Switch";
import ModalBox from "../../../../components/modal/ModalBox";
import { toastMessage } from "../../../../utils/toastMessage";
import useButtonLoader from "../../../../hooks/UseButtonLoader";

const Language = ({
  userType,
  type,
  title,
  contentData,
  setContentData,
  total,
  setTotal,
}) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [languageId, setLanguageId] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  let activePage = +searchParam.get("page") || 1;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const getCmsDetails = async () => {
    try {
      showLoader();
      const { data } = await getContentApi({ contentType: type, userType });
      console.log(data, "Data");
      if (data?.code === 200) {
        setContentData(data?.result.list);
        setTotal(data?.result?.total);
       
      } else {
        setContentData([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Change Language Status Handler
  const changeLanguageStatusHandler = async () => {
    try {
      buttonLoader(true);
      const res = await block_delete_ContentApi({
        languageId: languageId.languageId,
        status: (!languageId.status).toString(),
        contentType: type,
      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "languageStatus");
        setStatusModal(false);
        setLanguageId({});
        getCmsDetails();
      } else {
        toastMessage(res?.data?.message, "error", "languageStatus");
        setLanguageId({});
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    getCmsDetails();
  }, []);
  return (
    <>
      <div className="TitleBox mt-4">
        <h4 className="Title">Language</h4>
        <div className="block_user_button">
          <button onClick={() => setAddModal(true)}>
            <AddIcon />
            Add Language
          </button>
        </div>
      </div>
      <div className="content-table">
        <table>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Title</th>
              <th className="text-center"> Action </th>
            </tr>
          </thead>
          <tbody>
            {contentData.length > 0 ? (
              contentData.map((user, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{findSerialNumber(index, activePage, 10)}</td>
                      <td>{user?.title}</td>
                      <td>
                        <div className="action_button">
                          <Switch
                            checked={user.status}
                            onChange={() => {
                              setStatusModal(true);
                              setLanguageId({
                                languageId: user._id,
                                status: user.status,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <NotFound
                cols={9}
                msg="User not found!"
                // icon={NOT_FOUND}
                loader={loader}
              />
            )}
          </tbody>
        </table>
      </div>
      {total > 10 ? (
        <div className="pagination-wrapper">
          <div className="Pagination">
            <Pagination
              activePage={activePage}
              previousLabel={"previous"}
              nextLabel={"next"}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      ) : null}

      <AddLanguage
        setAddModal={setAddModal}
        addModal={addModal}
        getCmsDetails={getCmsDetails}
        userType={userType}
        type={type}
      />
      <ModalBox
        status={statusModal}
        onClose={() => setStatusModal(false)}
        title={"Change Status"}
        description={"Are you sure want to change status of this language?"}
        action={changeLanguageStatusHandler}
        ref={buttonRef}
      />
    </>
  );
};

export default Language;
