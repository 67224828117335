import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-music-listing--------------------------
export const getCmsDetailApi = async (data) => {
  const endPoint =
    `/admin/cms` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// // ---------------------add-category---------------------------
export const updateCmsApi = async (data) => {
  const endPoint = `/admin/cms/add_update`
  return await axios.post(endPoint, data, setJwtToken())
}

// -------------------------Add-Content---------------------------
export const getContentApi=async(data)=>{
  const endPoint= `/admin/cms/list_content` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint,setJwtToken())
}

export const addContentApi=async(data)=>{
  const endPoint= `/admin/cms/add_content`
  return await axios.post(endPoint,data,setMultiPartHeader())
}

export const editContentApi=async(data)=>{
  const endPoint= `/admin/cms/edit_content`
  return await axios.put(endPoint,data,setMultiPartHeader())
}

// 
export const block_delete_ContentApi=async(data)=>{
  const endPoint= `/admin/cms/block_delete`
  return await axios.put(endPoint,data,setJwtToken())
}
