import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { Input, Select } from "../../components/Form";
import ReactDatePicker from "react-datepicker";
import { dateFormatUtc } from "../../utils/dateFormat";
import { addDiscountApi } from "../../services/discountManagement";
import useButtonLoader from "../../hooks/UseButtonLoader";

const AddCoupon = ({
  couponModal,
  setCouponModal,
  fetchDiscountHandler,
  buttonType,
}) => {
  const [error, setError] = useState({});
  const [couponField, setCouponField] = useState({});
  const [image, setImage] = useState("");
  const { imageFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  const modalClose = () => {
    setError({});
    setImage("");
    setCouponField({});
    setCouponModal(false);
  };

  const validation = () => {
    let status = true;
    let error = {};

    if (!couponField?.title) {
      error["title"] = "Title is required *";
      status = false;
    }
    if (!couponField?.couponDiscount) {
      error["couponDiscount"] = "Coupon Discount is required *";
      status = false;
    }
    if (!couponField?.couponStartDate) {
      error["couponStartDate"] = "Start date is required *";
      status = false;
    }
    if (!couponField?.couponEndDate) {
      error["couponEndDate"] = "End date is required *";
      status = false;
    }
    if (!image && !image?.name) {
      error["membershipDiscountImage"] = "Image is required *";
      status = false;
    }
    if (!couponField?.couponType) {
      error["couponType"] = "Coupon type is required *";
      status = false;
    }
    setError(error);
    return status;
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  const addCouponHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", couponField.title);
        formData.append("type", buttonType);
        formData.append("couponDiscount", couponField.couponDiscount);
        formData.append("couponType", couponField?.couponType);
        formData.append(
          "couponStartDate",
          dateFormatUtc(couponField.couponStartDate)
        );
        formData.append(
          "couponEndDate",
          dateFormatUtc(couponField.couponEndDate)
        );
        if (image && image?.name) {
          formData.append("couponImage", image);
        }
        const { data } = await addDiscountApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addCoupon");
          fetchDiscountHandler();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addCoupon");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={couponModal}
      onClose={modalClose}
      title={"Add Coupon"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addCouponHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={couponField.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setCouponField({ ...couponField, ["title"]: sanitizedText });
            }}
            placeholder="Coupon Name"
            className={`form-control`}
            label={"Coupon Name"}
            error={!couponField.title && error.title}
            maxLength={30}
          />
        </div>

        <div className="form-group mb-2">
          <Select
            name="couponType"
            value={couponField.couponType}
            required
            onChange={(e) => {
              setCouponField({
                ...couponField,
                couponType: e.target.value,
              });
            }}
            placeholder="Vastu Category Title"
            className={`form-control`}
            label={"Plan Type"}
            error={!couponField.couponType && error.couponType}
            maxLength={40}
          >
            <option value="">Select Type</option>
            <option value="COUPON">Coupon</option>
            <option value="PROMOCODE">Promocode</option>
          </Select>
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="couponDiscount"
            value={couponField.couponDiscount || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setCouponField({
                ...couponField,
                ["couponDiscount"]: sanitizedText,
              });
            }}
            placeholder="Coupon Discount"
            className={`form-control`}
            label={"Coupon Discount"}
            error={!couponField.couponDiscount && error.couponDiscount}
            maxLength={5}
          />
        </div>
        <div className="form-group mb-2">
          <label className="my-1 text-dark w-100">Start Date</label>
          <ReactDatePicker
            selected={couponField.couponStartDate}
            onChange={(date) => {
              setCouponField({ ...couponField, ["couponStartDate"]: date });
            }}
            className={`form-control multi_search_date`}
            isClearable
            dateFormat="dd-MMM-yyyy"
            placeholderText="dd-MMM-yyyy"
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            minDate={new Date()}
          />
          {error.couponStartDate && (
            <span className="invalid-feedback d-block">
              {error.couponStartDate}
            </span>
          )}
        </div>
        <div className="form-group mb-2">
          <label className="my-1 text-dark w-100">End Date</label>
          <ReactDatePicker
            selected={couponField.couponEndDate}
            onChange={(date) => {
              setCouponField({ ...couponField, ["couponEndDate"]: date });
            }}
            className={`form-control multi_search_date`}
            isClearable
            dateFormat="dd-MMM-yyyy"
            placeholderText="dd-MMM-yyyy"
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            minDate={new Date()}
          />
          {error.couponEndDate && (
            <span className="invalid-feedback d-block">
              {error.couponEndDate}
            </span>
          )}
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="membershipDiscountImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Image"
            className={`form-control `}
            label={"Image"}
            error={!image && error.membershipDiscountImage}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddCoupon;
