import { Avatar } from '@mui/material'
import ModalBox from '../modal/ModalBox'
import { charLimit } from '../../utils/charLimit'

const ImagePreviewModel = ({ show, close, imageUrl, title }) => {
  return (
    <ModalBox
      status={show}
      onClose={close}
      title={charLimit(title, 32)}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <button className="btn_size" onClick={close}>
          Close
        </button>
      }
    >
      <div className="mt-3">
        <Avatar
          variant="square"
          sx={{
            width: '100%',
            borderRadius: 1,
            height: 300,
            border: '1px solid #FF8989',
          }}
          src={imageUrl}
          className="image-preview"
        />
      </div>
    </ModalBox>
  )
}
export default ImagePreviewModel
