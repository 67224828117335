import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import useDebounce from '../../hooks/useDebounce'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import {
  Avatar,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material'
import Switch from '../../components/Switch/Switch'
import NotFound from '../../components/not-found/NotFound'
import { category, image } from '../../routes/route'
import common from '../../routes/common'

import { fetchBannerApi } from '../../services/bannerManagement'
import { toastMessage } from '../../utils/toastMessage'
import findSerialNumber from '../../utils/findSerialNumber'
import { charLimit } from '../../utils/charLimit'

import EditIcon from '@mui/icons-material/Edit'

// import showFullSizeImage from '../../utils/showFullSizeImage'
import scrollToUp from '../../utils/scrollToUp'
import Pagination from 'react-js-pagination'
import { CapitalizeFirstWord } from '../../utils/CapitalizeFirstWord'
import { getLocalStorage, setLocalStorage } from '../../config/config'
import { fetchImageListApi } from '../../services/imageManagement'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { dateFormatWithTime } from '../../utils/dateFormat'

// import common from '../../routes/common'

const { IMAGE_DETAIL } = image
const { NOT_FOUND } = common

const ImageList = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1

  let buttonKey = searchParam.get('type') || 'profile'
  const [search, setSearch] = useState(
    getLocalStorage('imageList', 'search') || ''
  )
  const debounceValue = useDebounce(search || '', 300)
  const [addBannerModel, setAddBannerModel] = useState(false)
  const [total, setTotal] = useState(0)
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [updateModal, setUpdateModal] = useState(false)
  // const [buttonKey, setButtonKey] = useState('user')
  const [imageList, setImageList] = useState([])
  const [selectedBannerDetail, setSelectedBannerDetail] = useState(null)

  useEffect(() => {
    setLocalStorage('imageList', 'search', search)
  }, [search])

  // handle all hooks

  const [loader, showLoader, hideLoader] = useFullPageLoader()

  // handle all function

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }
  // update banner handler
  const updateBannerHandler = (data) => {
    setSelectedBannerDetail(data || null)
    setUpdateModal(true)
  }

  // banner type handler

  const bannerTypeHandler = (type) => {
    searchParam.set('type', type)
    searchParam.set('page', 1)
    setSearchParam(searchParam)
  }

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  // limit handler

  const limitHandler = (value) => {
    searchParam.set('limit', value)
    searchParam.set('page', 1)
    setSearchParam(searchParam)
  }
  // close model

  const closeModel = () => {
    setStatusModal(false)
    setUpdateModal(false)
    setAddBannerModel(false)
    setStatusValue('')
    setStatusId('')
  }

  // //------------------Banner - listing-Api----------------------------------
  const fetchImageList = async (type) => {
    try {
      setImageList([])
      showLoader()
      const sendData = {
        page: activePage,
        limit: 10,
        galleryType: type,
      }
      if (debounceValue) {
        sendData.page = 1
        sendData.search = debounceValue
        searchParam.delete('page')
        setSearchParam(searchParam)
      }
      const { data } = await fetchImageListApi(sendData)
      if (data.code === 200) {
        setImageList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setImageList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'imageList')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  useEffect(() => {
    // if (debounceValue) {
    //   searchParam.set('query', debounceValue)
    // } else {
    //   searchParam.delete('query')
    // }
    if (activePage === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    fetchImageList(buttonKey)
  }, [searchParam, debounceValue])

  const notFoundMessage =
    buttonKey === 'profile'
      ? 'Gallery Image not found!'
      : buttonKey === 'live'
      ? 'Live Image not found!'
      : 'Not-Found'

  // useEffect(() => {
  //   fetchImageList(buttonKey)
  // }, [buttonKey])
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">
              {buttonKey === 'profile'
                ? 'Gallery Image'
                : buttonKey === 'live'
                ? 'Live Image'
                : null}
            </h4>

            <div className="banner_wrapper">
              <div className="banner_button_wrapper">
                <button
                  className={`_button ${buttonKey === 'profile' && 'banner_active'}`}
                  onClick={() => bannerTypeHandler('profile')}
                >
                  Gallery
                </button>
                <button
                  className={`_button ${buttonKey === 'live' && 'banner_active'}`}
                  onClick={() => bannerTypeHandler('live')}
                >
                  Live
                </button>
              </div>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>

          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Profile</th>
                  <th>Date and Time</th>
                  <th>Total Images</th>
                  <th>Status</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {imageList && imageList.length > 0 ? (
                  imageList.map((image, index) => {
                    return (
                      <React.Fragment key={image._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{charLimit(image.name, 25) || '---'}</td>
                          <td>
                            <Avatar
                              variant="square"
                              id={image._id}
                              alt={image.name}
                              src={image.profileImageImage}
                              sx={{
                                width: 50,
                                height: 50,
                                borderRadius: 3,
                                cursor: 'pointer',
                              }}
                              // onClick={() => showFullSizeImage(image._id)}
                            />
                          </td>
                          <td>
                            {dateFormatWithTime(image.createdAt) || '---'}
                          </td>
                          <td>{image.totalImage || '---'}</td>
                          <td>{image.status ? 'Approve' : 'New Request'}</td>
                          <td>
                            <div className="action_button">
                              <Link
                                to={`${IMAGE_DETAIL}/${buttonKey}/${image.consultantId}`}
                              >
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg={notFoundMessage}
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>

          {total > 10 && (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          )}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {/* {addBannerModel && (
          <AddBanner
            show={addBannerModel}
            close={closeModel}
            userType={buttonKey}
            imageList={() => fetchImageList(buttonKey)}
            // listing={fatchSubCategory}
          />
        )} */}
        {/* {statusModal && (
          <BannerStatus
            show={statusModal}
            close={closeModel}
            setListing={setImageList}
            status={statusValue}
            id={statusId}
          />
        )} */}
        {/* {updateModal && (
          <EditBanner
            show={updateModal}
            close={closeModel}
            bannerDetail={selectedBannerDetail}
            imageList={() => fetchImageList(buttonKey)}
            setBannerDetail={setSelectedBannerDetail}
          />
        )} */}
      </div>
    </>
  )
}

export default ImageList
