import React, { useState } from "react";
import { TextArea } from "../../../../components/Form";
import { optimizeFunction } from "../../../../utils/regex";
import { toastMessage } from "../../../../utils/toastMessage";
import ModalBox from "../../../../components/modal/ModalBox";
import { editContentApi } from "../../../../services/cms";
import useButtonLoader from "../../../../hooks/UseButtonLoader";

const EditNotice = ({
  setEditModal,
  editModal,
  selectedNotice,
  setSelectedNotice,
  setContentData,
  contentData,
  type,
}) => {
  const [buttonRef, buttonLoader] = useButtonLoader("Save");
  const [error, setError] = useState("");

  const modalClose = () => {
    setError("");
    setEditModal(false);
  };

  const validation = () => {
    if (!selectedNotice?.title) {
      setError("Title is mandatory");
      return false;
    }
    return true;
  };

  const editNoticeHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData=new FormData();
        formData.append("title",selectedNotice?.title);
        formData.append("contentType",type);
        formData.append("noticeId",selectedNotice?.id);
        const { data } = await editContentApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "editNotice");
          setContentData(
            contentData.map((data) => {
              if (data.id === selectedNotice.id) {
                return selectedNotice;
              } else {
                return data;
              }
            })
          );

          modalClose();
        } else {
          toastMessage(data.message, "error", "editNotice");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={editModal}
      onClose={modalClose}
      title={"Edit Notice"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonRef}
          onClick={editNoticeHandler}
        >
          Save
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <TextArea
            type="text"
            name="title"
            value={selectedNotice?.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = e.target.value
              setSelectedNotice({
                ...selectedNotice,
                ["title"]: sanitizedText,
              });
            }}
            placeholder="Enter Notice..."
            className={`form-control`}
            label={"Title"}
            error={!selectedNotice?.title && error}
            // maxLength={40}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default EditNotice;
