import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category } from "../../routes/route";
import common from "../../routes/common";
// import AddCategory from './AddCategory'
import { fatchCategoryApi } from "../../services/categoryManagement";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchGiftApi } from "../../services/giftManagement";
import EditIcon from "@mui/icons-material/Edit";
import GiftStatus from "./GiftStatus";
import AddGift from "./AddGift";
import EditGift from "./EditGift";
import AddIcon from '@mui/icons-material/Add';
// import CategoryStatus from './CategoryStatus'
const { ADD_MUSIC } = category;
const { NOT_FOUND } = common;

const GiftManagement = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [search, setSearch] = useState(searchParam.get("query") || "");
  const debounceValue = useDebounce(search || "", 300);
  const [addGiftModal, setAddGiftModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [giftList, setGiftList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedGift, setSelectedGift] = useState({});

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  //   ********Edit data*************//
  const editHandler = (data) => {
    setSelectedGift(data);
    setEditModal(true);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // close model
  const closeModel = () => {
    setStatusValue("");
    setStatusId("");
    setStatusModal(false);
  };

  // //------------------listing-Category-Api----------------------------------
  const fetchGiftHandler = async () => {
    try {
      const sendData = {};
      setGiftList([]);
      showLoader();
    //   sendData.limit = "1";
      sendData.page = activePage;
      setSearchParam(searchParam);
      const res = await fetchGiftApi(sendData);
      if (res.data.code === 200) {
        setGiftList(res?.data?.result?.list || []);
        setTotal(res?.data?.result?.total || 0);
      } else {
        setGiftList([]);
        setTotal(0);
        toastMessage(res.data.message, "error", "giftList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);

    fetchGiftHandler(activePage);
  }, [searchParam]);

  useEffect(() => {
    fetchGiftHandler();
  }, []);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Gift Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddGiftModal(true)}><AddIcon/>Add Gift</button>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Gift Id</th>
                  <th>Gift Name</th>
                  <th>Icon</th>
                  <th>Coins</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {giftList.length > 0 ? (
                  giftList.map((gift, index) => {
                    return (
                      <React.Fragment key={gift._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{gift._id}</td>
                          <td>{charLimit(gift.title, 40) || "---"}</td>
                          <td>
                            <Avatar
                              alt={gift.title}
                              src={gift.giftIcon}
                              sx={{
                                width: 50,
                                height: 50,
                              }}
                            />
                          </td>
                          <td>{gift.coin}</td>
                          <td>
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => editHandler(gift)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={gift.status}
                                onChange={() =>
                                  statusUpdateHandler(gift._id, gift.status)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Gift not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
        {addGiftModal && (
          <AddGift
            show={addGiftModal}
            close={() => setAddGiftModal(false)}
            listing={fetchGiftHandler}
          />
        )}
        {statusModal && (
          <GiftStatus
            show={statusModal}
            close={closeModel}
            listing={giftList}
            setListing={setGiftList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editModal && (
          <EditGift
            setEditModal={setEditModal}
            editModal={editModal}
            selectedGift={selectedGift}
            setSelectedGift={setSelectedGift}
            fetchGiftHandler={fetchGiftHandler}
          />
        )}
      </div>
    </>
  );
};

export default GiftManagement;
