import React, { Fragment, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { optimizeFunction, regex } from "../../utils/regex";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { checkImageDimensions } from "../../utils/checkImageDimension";
import commonMessages from "../../utils/commonMessages";
import { updateVastuCategoryApi } from "../../services/vastuCompassCategory";

const {
  VASTU_COMPASS_CATEGORY_TITLE,
  VASTU_COMPASS_CATEGORY,
  VASTU_COMPASS_CATEGORY_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages;
const { imageFileRegex } = regex;

const EditVastuCompass = ({
  show,
  close,
  selectedVastuDetail,
  setSelectedVastuDetail,
  vastuListing,
  vastuCategory,
  setVastuCategory,
  vastuTitle,
  setVastuTitle,
}) => {
  // handle all state

  const [image, setImage] = useState("");
  const [error, setError] = useState({});
  const [updateVastuCompassCategory, setUpdateVastuCompassCategory] = useState({
    vastuCategoryId: selectedVastuDetail._id,
    title: selectedVastuDetail.title,
    vastuCompassCategoryImage: selectedVastuDetail.vastuCompassCategoryImage,
    category:selectedVastuDetail.category
  });

  console.log(selectedVastuDetail, "selectedVAstu Detail");
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Update");

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!updateVastuCompassCategory.title) {
      errorMessage["vastuCategoryTitle"] = VASTU_COMPASS_CATEGORY_TITLE;
      status = false;
    }
    if (!updateVastuCompassCategory.category) {
      errorMessage["vastuCategory"] = VASTU_COMPASS_CATEGORY;
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  // image handler function
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(e.target.files[0]);
        if (validSize) {
          setImage(e.target.files[0]);
        } else {
          e.target.value = "";
          setImage("");
        }
      } else {
        setImage("");
        e.target.value = "";
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };
  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "title") {
      updatedValue = optimizeFunction(value);
    }
    setUpdateVastuCompassCategory({
      ...updateVastuCompassCategory,
      [name]: updatedValue,
    });
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage("");
    setSelectedVastuDetail({});
    setError({});
    close();
  };
  //--------------Update-music-category------------------------
  const updateMusicCategoryHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append(
          "vastuCategoryId",
          updateVastuCompassCategory.vastuCategoryId
        );
        formData.append("title", updateVastuCompassCategory.title);
        formData.append("category", updateVastuCompassCategory.category);
        if (image && image.name) {
          formData.append("vastuCompassCategoryImage", image);
        }
        const { data } = await updateVastuCategoryApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "updateVastuCompassCategory");
          vastuListing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "updateVastuCompassCategory");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Edit Vastu Compass Category"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateMusicCategoryHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
        <Select
            name="title"
            value={updateVastuCompassCategory.title}
            required
            onChange={(e) => {
              // const sanitizedText = optimizeFunction(e.target.value);
              // setVastuCategoryTitle(sanitizedText);
              setUpdateVastuCompassCategory({
                ...updateVastuCompassCategory,
                title: e.target.value,
              });
            }}
            placeholder="Vastu Category Title"
            className={`form-control`}
            label={"Vastu Title"}
            error={!updateVastuCompassCategory.title && error.title}
            maxLength={40}
          >
            <option>Select Title</option>
            {vastuTitle.map((data, index) => {
              return (
                <Fragment key={++index}>
                  <option value={data}>{data}</option>
                </Fragment>
              );
            })}
          </Select>
        </div>
        <div className="form-group mb-2">
        <Select
            value={updateVastuCompassCategory.category}
            required
            onChange={(e) => {
              // const sanitizedText = optimizeFunction(e.target.value);
              // setVastuCategoryTitle(sanitizedText);
              setUpdateVastuCompassCategory({
                ...updateVastuCompassCategory,
                category: e.target.value,
              });
            }}
            className={`form-control`}
            label={"Vastu category"}
            error={!updateVastuCompassCategory.category && error.category}
            maxLength={40}
          >
            <option>Select Title</option>
            {vastuCategory.map((data, index) => {
              return (
                <Fragment key={++index}>
                  <option value={data}>{data}</option>
                </Fragment>
              );
            })}
          </Select>
          </div>

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Banner Image"
            className={`form-control `}
            label={"Category Image"}
            // error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={
                  image && image.name
                    ? URL.createObjectURL(image)
                    : updateVastuCompassCategory.vastuCompassCategoryImage
                }
              />
            </>
          }
        </div>
      </div>
    </ModalBox>
  );
};

export default EditVastuCompass;
