// import React, { useState } from 'react'
// import ModalBox from '../../../components/modal/ModalBox'
// import { Input, TextArea } from '../../../components/Form'
// import useButtonLoader from '../../../hooks/UseButtonLoader'
// import { regex, optimizeFunction } from '../../../utils/regex'
// import { toastMessage } from '../../../utils/toastMessage'
// import configData from '../../../config/configData'
// import { addMeditationApiHandler } from '../../../services/meditationManagement'
// import VideoThumbnail from 'react-video-thumbnail'
// import { TbPlayerPlayFilled } from 'react-icons/tb'
// import VideoPreview from '../../../components/videoPreview/VideoPreview'
// import AudioPreview from '../../../components/audioPreview/AudioPreview'
// import ImagePreview from '../../../components/imagePreview/ImagePreview'
// import commonMessages from '../../../utils/commonMessages'
// import {
//   checkImageAspectRatio,
//   checkImageDimensions,
// } from '../../../utils/checkImageDimension'
// const { IMAGE_GIF_FORMAT, MESSAGE_SIZE, IMAGE_FORMAT } = commonMessages

// const { imageFileRegex, audioFileRegex, videoFileRegex } = regex

// const AddMeditation = ({ show, close, listing, meditationId }) => {
//   // handle all state
//   const [meditationTitle, setMeditationTitle] = useState('')
//   const [meditationDescription, setMedicationDescription] = useState('')
//   const [meditationVideo, setMeditationVideo] = useState({
//     video: '',
//     thumbnailImage: '',
//   })
//   const [image, setImage] = useState('')
//   const [meditationImage, setMeditationImage] = useState('')
//   const [music, setMusic] = useState('')
//   const [mantra, setMantra] = useState('')
//   const [error, setError] = useState({})
//   const [showVideo, setShowVideo] = useState(false)

//   // handle all hooks
//   const [buttonref, buttonLoader] = useButtonLoader('Save')

//   // handle all function

//   // console.log("AddSubCategory",input)
//   //----------vaidation------------
//   const validation = () => {
//     let errorMessage = {}
//     let status = true

//     if (!meditationTitle) {
//       errorMessage['title'] = 'Please add meditation title'
//       status = false
//     }
//     if (!image.name) {
//       errorMessage['video'] = 'Please upload meditation GIF'
//       status = false
//     }
//     if (!music.name) {
//       errorMessage['music'] = 'Please upload meditation music'
//       status = false
//     }
//     if (!mantra.name) {
//       errorMessage['mantra'] = 'Please upload meditation mantra'
//       status = false
//     }

//     if (!meditationImage.name) {
//       errorMessage['image'] = 'Please upload meditation image'
//       status = false
//     }

//     if (!meditationDescription) {
//       errorMessage['description'] = 'Please add meditation description'
//       status = false
//     }

//     setError(errorMessage)
//     return status
//   }

//   const resetInputAndMusic = (e, name) => {
//     e.target.value = ''
//     if (name === 'meditationMantra') {
//       setMantra('')
//     } else if (name === 'meditationMusic') {
//       setMusic('')
//     }
//   }

//   const resetInputAndVideo = (e) => {
//     e.target.value = ''
//     setMeditationVideo({
//       video: '',
//       thumbnailImage: '',
//     })
//   }

//   // file handler

//   const fileHandler = (e, name, rest) => {
//     e.preventDefault()

//     const maxFileSizeInBytes =
//       name === 'music' ? configData.audio_legth : configData.video_legth
//     const maxDurationInSeconds =
//       name === 'music' ? configData.audio_duration : configData.video_duration

//     const file = e.target.files[0]

//     if (!file) {
//       name === 'music' && resetInputAndMusic(e, rest)
//       name === 'meditationVideo' && resetInputAndVideo(e)
//       return
//     }

//     const fileType = name === 'music' ? 'audio' : 'video'
//     const validFileTypes =
//       fileType === 'audio' ? audioFileRegex : videoFileRegex

//     if (!validFileTypes.test(file.name)) {
//       name === 'music' && resetInputAndMusic(e, rest)
//       name === 'meditationVideo' && resetInputAndVideo(e)
//       toastMessage(
//         `Please select a valid ${fileType} file (${
//           fileType === 'audio' ? 'MP3, WAV, or OGG' : 'MP4, AVI, or MOV'
//         }).`,
//         'error',
//         'addfile'
//       )
//       return
//     }

//     if (file.size > maxFileSizeInBytes) {
//       name === 'music' && resetInputAndMusic(e, rest)
//       name === 'meditationVideo' && resetInputAndVideo(e)
//       toastMessage(
//         'File size exceeds the limit. Please select a smaller file.',
//         'error',
//         'addfile'
//       )
//       return
//     }

//     const element = document.createElement(fileType)
//     element.src = URL.createObjectURL(file)
//     element.onloadedmetadata = () => {
//       if (element.duration > maxDurationInSeconds) {
//         name === 'music' && resetInputAndMusic(e, rest)
//         name === 'meditationVideo' && resetInputAndVideo(e)
//         toastMessage(
//           'file duration exceeds the limit. Please select a shorter file.',
//           'error',
//           'addfile'
//         )
//       } else {
//         if (name === 'music') {
//           if (rest === 'meditationMantra') {
//             setMantra(file)
//           } else if (rest === 'meditationMusic') {
//             setMusic(file)
//           }
//         }
//         if (name === 'meditationVideo') {
//           // console.log(file)
//           setMeditationVideo({ video: file, thumbnailImage: '' })
//           setShowVideo(false)
//         }
//       }
//     }
//     element.onerror = () => {
//       name === 'music' && resetInputAndMusic(e, rest)
//       name === 'meditationVideo' && resetInputAndVideo(e)
//       toastMessage(
//         'Error in  loading file. Please try again.',
//         'error',
//         'addfile'
//       )
//     }
//   }

//   const gifHandler = async (e) => {
//     e.preventDefault()
//     if (e.target.files.length > 0) {
//       const file = e.target.files[0]
//       const fileName = file.name.toLowerCase()
//       if (fileName.endsWith('.gif')) {
//         const validAsectRatio = await checkImageAspectRatio(e.target.files[0])
//         if (validAsectRatio) {
//           setImage(e.target.files[0])
//         } else {
//           e.target.value = ''
//           setImage('')
//         }
//       } else {
//         e.target.value = ''
//         setImage('')
//         toastMessage(IMAGE_GIF_FORMAT, 'error', 'imageFormat')
//       }
//     } else {
//       e.target.value = ''
//       setImage('')
//     }
//   }

//   const imageHandler = async (e) => {
//     e.preventDefault()
//     if (e.target.files.length > 0) {
//       if (imageFileRegex.test(e.target.files[0].name)) {
//         // setImage(e.target.files[0])
//         const validSize = await checkImageDimensions(e.target.files[0])
//         if (validSize) {
//           setMeditationImage(e.target.files[0])
//         } else {
//           e.target.value = ''
//           setMeditationImage('')
//         }
//       } else {
//         e.target.value = ''
//         e('')
//         toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
//       }
//     } else {
//       e.target.value = ''
//       setMeditationImage('')
//     }
//   }

//   //------------------modal-close--------------------------
//   const modalClose = () => {
//     setMantra('')
//     setMusic('')
//     setMeditationTitle('')
//     setMeditationVideo({
//       video: '',
//       thumbnailImage: '',
//     })
//     setError({})
//     close()
//   }
//   //--------------Add-sub-category------------------------
//   const addMeditationHandler = async () => {
//     const status = validation()
//     if (status) {
//       try {
//         buttonLoader(true)
//         const formData = new FormData()
//         formData.append('meditationCategoryId', meditationId)
//         formData.append('title', meditationTitle)
//         formData.append('meditationVideo', image)
//         formData.append('meditationMantra', mantra)
//         formData.append('meditationMusic', music)
//         formData.append('description', meditationDescription)
//         formData.append('meditationImage', meditationImage)
//         const { data } = await addMeditationApiHandler(formData)
//         if (data.code === 200) {
//           toastMessage(data.message, 'success', 'addMeditationHandler')
//           listing()
//           modalClose()
//         } else {
//           toastMessage(data.message, 'error', 'addMeditationHandler')
//         }
//       } catch (error) {
//         console.log('error', error)
//       } finally {
//         buttonLoader(false)
//       }
//     }
//   }

//   return (
//     <ModalBox
//       status={show}
//       onClose={modalClose}
//       title={'Add Video / Mantra'}
//       closeIcon
//       custom
//       customizedFooter={
//         <div className="model_custom_footer">
//           <p>{MESSAGE_SIZE}</p>
//           <button
//             className="btn_size"
//             ref={buttonref}
//             onClick={addMeditationHandler}
//           >
//             Save
//           </button>
//         </div>
//       }
//     >
//       <div>
//         <div className="form-group mb-2">
//           <Input
//             type="text"
//             name="title"
//             value={meditationTitle || ''}
//             required
//             onChange={(e) => {
//               const sanitizedText = optimizeFunction(e.target.value)
//               setMeditationTitle(sanitizedText)
//             }}
//             placeholder="Meditation Title"
//             className={`form-control`}
//             label={'Meditation Title'}
//             error={!meditationTitle && error.title}
//             maxLength={40}
//           />
//         </div>

//         <div className="form-group my-2">
//           <Input
//             type="file"
//             accept="image/gif"
//             name="meditationVideo"
//             // value={image.name || ''}
//             required
//             onChange={(e) => gifHandler(e)}
//             placeholder=""
//             className={`form-control `}
//             label={'Meditation GIF'}
//             error={!image.name && error.video}
//             // maxLength={30}
//           />
//         </div>

//         {image && image.name && (
//           <div className="video-container">
//             <ImagePreview imageUrl={URL.createObjectURL(image)} />
//           </div>
//         )}

//         <div className="form-group my-2">
//           <Input
//             type="file"
//             accept="image/*"
//             name="meditationImage"
//             // value={image.name || ''}
//             required
//             onChange={(e) => imageHandler(e)}
//             placeholder=""
//             className={`form-control `}
//             label={'Meditation Image'}
//             error={!meditationImage.name && error.image}
//             // maxLength={30}
//           />
//         </div>

//         {meditationImage && meditationImage.name && (
//           <div className="video-container">
//             <ImagePreview imageUrl={URL.createObjectURL(meditationImage)} />
//           </div>
//         )}
//         {/* ))} */}

//         <div className="form-group my-2">
//           <Input
//             type="file"
//             accept="audio/mp3"
//             name="meditationMantra"
//             required
//             onChange={(e) => fileHandler(e, 'music', 'meditationMantra')}
//             placeholder="Meditation Mantra"
//             className={`form-control `}
//             label={'Meditation Mantra'}
//             error={!mantra && error.mantra}
//           />
//         </div>

//         {mantra && (
//           <AudioPreview musicUrl={mantra && URL.createObjectURL(mantra)} />
//         )}

//         <div className="form-group my-2">
//           <Input
//             type="file"
//             accept="audio/mp3"
//             name="meditationMusic"
//             required
//             onChange={(e) => fileHandler(e, 'music', 'meditationMusic')}
//             placeholder="Meditation Music"
//             className={`form-control `}
//             label={'Meditation Music'}
//             error={!music && error.music}
//           />
//         </div>

//         {music && (
//           <AudioPreview musicUrl={music && URL.createObjectURL(music)} />
//         )}

//         <div className="form-group my-2">
//           <TextArea
//             value={meditationDescription}
//             onChange={(e) => {
//               e.preventDefault()
//               setMedicationDescription(e.target.value)
//             }}
//             label={'Medication Description'}
//             className={'meditation_description'}
//             error={!meditationDescription && error.description}
//             required
//           />
//         </div>
//       </div>
//     </ModalBox>
//   )
// }

// export default AddMeditation

import React, { useState, useEffect } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../components/Form";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import {
  regex,
  optimizeFunction,
  allowAlphaNumericWithSpace,
} from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import configData from "../../../config/configData";
import { addMeditationApiHandler } from "../../../services/meditationManagement";
import ImagePreview from "../../../components/imagePreview/ImagePreview";
import commonMessages from "../../../utils/commonMessages";
import {
  checkImageAspectRatio,
  checkImageDimensions,
} from "../../../utils/checkImageDimension";

const { IMAGE_GIF_FORMAT, MESSAGE_SIZE, IMAGE_FORMAT } = commonMessages;
const { imageFileRegex, audioFileRegex, videoFileRegex } = regex;

const AddMeditation = ({ show, close, listing, meditationId }) => {
  // State
  const [meditationTitle, setMeditationTitle] = useState("");
  const [meditationDescription, setMedicationDescription] = useState("");
  const [image, setImage] = useState("");
  const [meditationImage, setMeditationImage] = useState("");
  const [music, setMusic] = useState("");
  const [mantra, setMantra] = useState("");
  const [error, setError] = useState({});
  const [audioURL, setAudioURL] = useState("");
  const [musicAudioUrl, setMusicAudioUrl] = useState("");

  // Hooks
  const [buttonRef, buttonLoader] = useButtonLoader("Save");

  // Validation
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!meditationTitle) {
      errorMessage["title"] = "Please add meditation title";
      status = false;
    }
    // if (!image.name) {
    //   errorMessage["video"] = "Please upload meditation GIF";
    //   status = false;
    // }
    if (!music.name) {
      errorMessage["music"] = "Please upload meditation music";
      status = false;
    }
    if (!mantra.name) {
      errorMessage["mantra"] = "Please upload meditation mantra";
      status = false;
    }
    // if (!meditationImage.name) {
    //   errorMessage["image"] = "Please upload meditation image";
    //   status = false;
    // }
    if (!meditationDescription) {
      errorMessage["description"] = "Please add meditation description";
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  // File Handler
  const fileHandler = async (e, name, rest) => {
    e.preventDefault();

    const maxFileSizeInBytes =
      name === "music" ? configData.audio_legth : configData.video_legth;
    const maxDurationInSeconds =
      name === "music" ? configData.audio_duration : configData.video_duration;

    const file = e.target.files[0];

    if (!file) {
      resetInput(e, name, rest);
      return;
    }

    const fileType = name === "music" ? "audio" : "video";
    const validFileTypes =
      fileType === "audio" ? audioFileRegex : videoFileRegex;

    if (!validFileTypes.test(file.name)) {
      resetInput(e, name, rest);
      toastMessage(
        `Please select a valid ${fileType} file (${
          fileType === "audio" ? "MP3, WAV, or OGG" : "MP4, AVI, or MOV"
        }).`,
        "error",
        "addfile"
      );
      return;
    }

    if (file.size > maxFileSizeInBytes) {
      resetInput(e, name, rest);
      toastMessage(
        "File size exceeds the limit. Please select a smaller file.",
        "error",
        "addfile"
      );
      return;
    }

    const element = document.createElement(fileType);
    element.src = URL.createObjectURL(file);
    element.onloadedmetadata = () => {
      if (element.duration > maxDurationInSeconds) {
        resetInput(e, name, rest);
        toastMessage(
          "file duration exceeds the limit. Please select a shorter file.",
          "error",
          "addfile"
        );
      } else {
        if (name === "music") {
          if (rest === "meditationMantra") {
            setMantra(file);
          } else if (rest === "meditationMusic") {
            setMusic(file);
          }
        }
      }
    };
  };

  const resetInput = (e, name, rest) => {
    e.target.value = "";
    if (name === "meditationMantra") {
      setMantra("");
    } else if (name === "meditationMusic") {
      setMusic("");
    }
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      e.target.value = "";
      setMeditationImage("");
      return;
    }

    const fileName = file.name.toLowerCase();
    const isGif = fileName.endsWith(".gif");
    const isValidImage = isGif || imageFileRegex.test(fileName);

    if (!isValidImage) {
      e.target.value = "";
      setMeditationImage("");
      toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      return;
    }

    const isValidAspectRatio = await checkImageAspectRatio(file);

    if (isValidAspectRatio) {
      setMeditationImage(file);
    } else {
      e.target.value = "";
      setMeditationImage("");
    }
  };

  // Audio Preview
  useEffect(() => {
    if (mantra) {
      setAudioURL(URL.createObjectURL(mantra));
    }
  }, [mantra]);

  // Audio Preview
  useEffect(() => {
    if (music) {
      setMusicAudioUrl(URL.createObjectURL(music));
    }
  }, [music]);

  // Modal Close
  const modalClose = () => {
    setMantra("");
    setMusic("");
    setMeditationTitle("");
    setMeditationImage("");
    setError({});
    close();
  };

  // Add Meditation Handler
  const addMeditationHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("meditationCategoryId", meditationId);
        formData.append("title", meditationTitle);
        // if (image && image?.name) {
        //   formData.append("meditationVideo", image);
        // }
        formData.append("meditationMantra", mantra);
        formData.append("meditationMusic", music);
        formData.append("description", meditationDescription);
        if (meditationImage && meditationImage?.name) {
          formData.append("meditationVideo", meditationImage);
        }
        const { data } = await addMeditationApiHandler(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addMeditationHandler");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addMeditationHandler");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Video / Mantra"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonRef}
            onClick={addMeditationHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={meditationTitle}
            required
            onChange={(e) =>
              setMeditationTitle(e.target.value)
            }
            placeholder="Meditation Title"
            className={`form-control`}
            label={"Meditation Title"}
            error={!meditationTitle && error.title}
            maxLength={40}
          />
        </div>

        {/* <div className="form-group my-2">
          <Input
            type="file"
            accept="image/gif"
            // name="meditationVideo"
            // required
            onChange={(e) => gifHandler(e)}
            placeholder=""
            className={`form-control `}
            label={"Meditation GIF"}
            error={!image.name && error.video}
          />
        </div>

        {image && image.name && (
          <div className="video-container">
            <ImagePreview imageUrl={URL.createObjectURL(image)} />
          </div>
        )} */}

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="meditationImage"
            // required
            onChange={(e) => imageHandler(e)}
            placeholder=""
            className={`form-control `}
            label={"Meditation Image/GIF"}
            error={!meditationImage.name && error.image}
          />
        </div>

        {meditationImage && meditationImage.name && (
          <div className="video-container">
            <ImagePreview imageUrl={URL.createObjectURL(meditationImage)} />
          </div>
        )}

        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="meditationMantra"
            required
            onChange={(e) => fileHandler(e, "music", "meditationMantra")}
            placeholder="Meditation Mantra"
            className={`form-control `}
            label={"Meditation Mantra"}
            error={!mantra && error.mantra}
          />
        </div>

        {audioURL && (
          <audio controls>
            <source src={audioURL} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}

        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="meditationMusic"
            required
            onChange={(e) => fileHandler(e, "music", "meditationMusic")}
            placeholder="Meditation Music"
            className={`form-control `}
            label={"Meditation Music"}
            error={!music && error.music}
          />
        </div>

        {musicAudioUrl && (
          <audio controls>
            <source src={musicAudioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}

        <div className="form-group my-2">
          <TextArea
            value={meditationDescription}
            onChange={(e) => setMedicationDescription(e.target.value)}
            label={"Medication Description"}
            className={"meditation_description"}
            error={!meditationDescription && error.description}
            required
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddMeditation;
