import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------form 16-A listing--------------------------
export const fetchFormApi = async (data) => {
  const endPoint =
    `/admin/consultant/form16` +
    "?" +
    new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

//--------------------------Add form ---------------------
export const addFormApi = async (reqData) => {
  const endPoint = `/admin/consultant/upload_form16`;
  return await axios.post(endPoint, reqData, setMultiPartHeader());
};

//--------------------------update form ---------------------
export const updateFormApi = async (reqData) => {
  const endPoint = `/admin/consultant/update_form16`;
  return await axios.put(endPoint, reqData, setMultiPartHeader());
};

//  Change Form16A Status
export const block_unblock_Api = async (data) => {
  // console.log(data);
  const endPoint = `/admin/consultant/block_unblock_form16`;
  return await axios.put(endPoint, data, setJwtToken());
};
