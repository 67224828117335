import React, { Fragment, useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select, TextArea } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { fatchCategoryApi } from "../../services/categoryManagement";
import {
  allowAlphaNumericWithSpace,
  optimizeFunction,
  regex,
} from "../../utils/regex";
import { Avatar } from "@mui/material";
import { charLimit } from "../../utils/charLimit";
import { toastMessage } from "../../utils/toastMessage";
import { addBannerApi, updateBannerApi } from "../../services/bannerManagement";
import commonMessages from "../../utils/commonMessages";
import {
  checkImageAspectRatio,
  checkImageDimensions,
  getImageDimension,
} from "../../utils/checkImageDimension";
import { updateStoryApi } from "../../services/exploreManagement";
import VideoPreview from "../../components/videoPreview/VideoPreview";
import VideoThumbnail from "react-video-thumbnail";
import { BaseToImage } from "../../utils/BaseToImage";
const {
  ADD_BANNER_TITLE,
  CHOOSE_CATEGORY,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages;
const { imageFileRegex, videoFileRegex } = regex;

const EditStories = ({
  show,
  close,
  fetchExploreHandler,
  selectedExploreDetail,
  setSelectedExploreDetail,
}) => {
  const [image, setImage] = useState("");
  const [imageSize,setImageSize]=useState({
    heigth:selectedExploreDetail?.heigth || "",
    width:selectedExploreDetail?.width || ""
  });
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [error, setError] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  const [updateStory, setUpdateStory] = useState({
    id: selectedExploreDetail._id,
    description: selectedExploreDetail.description,
    title: selectedExploreDetail.title,
    hastag: selectedExploreDetail.hastag?._id,
  });

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!updateStory.title) {
      errorMessage["banner"] = ADD_BANNER_TITLE;
      status = false;
    }

    if (!updateStory.hastag) {
      errorMessage["hastag"] = "Add hashtag";
      status = false;
    }
    if (!updateStory.description) {
      errorMessage["description"] = "Add description";
      status = false;
    }
    // if (!image) {
    //   errorMessage["image"] = "Media is mandatory";
    //   status = false;
    // }

    setError(errorMessage);
    return status;
  };

  //  Stories File  handler
  // const imageHandler = async (e) => {
  //   e.preventDefault();
  //   if (e.target.files.length > 0) {
  //     if (imageFileRegex.test(e.target.files[0].name)) {
  //       const mediaFile = e.target.files[0];
  //       const validSize = await checkImageDimensions(mediaFile);
  //       if (validSize) {
  //         const tempData = [];
  //         tempData.push(mediaFile);
  //         setImage(tempData);
  //         // let tempMediaFile=[...image];
  //         // tempMediaFile.push(mediaFile);
  //         // setImage(tempMediaFile)
  //       } else {
  //         e.target.value = "";
  //         // setImage(image);
  //         setImage([]);
  //       }
  //     } else {
  //       setImage(image);
  //       e.target.value = "";
  //       toastMessage(IMAGE_FORMAT, "error", "imageFormat");
  //     }
  //   } else {
  //     e.target.value = "";
  //     // setImage(image);
  //     setImage([]);
  //   }
  // };
  //------------------onChange-input-value---------------------------
  const imageHandler = async (e) => {
    e.preventDefault();
    const { files } = e.target;

    if (files.length === 0) {
      clearInputAndImageState(e);
      return;
    }

    const file = files[0];
    const fileName = file.name.toLowerCase();
    const isGif = fileName.endsWith(".gif");
    const isImage = imageFileRegex.test(fileName);
    const isVideo = videoFileRegex.test(fileName);

    if (isGif) {
      handleGif(file, e);
    } else if (isImage) {
      handleImage(file, e);
    } else if (isVideo) {
      setImage(file);
    } else {
      handleInvalidFileFormat(e);
    }
  };

  const clearInputAndImageState = (e) => {
    e.target.value = "";
    setImage("");
  };

  const handleGif = async (file, e) => {
    const validAspectRatio = await checkImageAspectRatio(file);
    if (validAspectRatio) {
      setImage(file);
    } else {
      clearInputAndImageState(e);
    }
  };

  const handleImage = async (file, e) => {
    if (file) {
      setImage(file);
      const img=await getImageDimension(file);
      setImageSize(img);
    } else {
      clearInputAndImageState(e);
    }
  };

  const handleInvalidFileFormat = (e) => {
    toastMessage(
      "Invalid format: Only GIF and images and videos supported",
      "error",
      "InvalidFormat"
    );
    clearInputAndImageState(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "title") {
      updatedValue = allowAlphaNumericWithSpace(value);
    }
    setUpdateStory({ ...updateStory, [name]: updatedValue });
  };

  // console.log(image, "Image");

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage("");
    setSelectedExploreDetail({});
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const updateStoryHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("exploreId", updateStory.id);
        formData.append("title", updateStory.title);
        formData.append("hastag", updateStory.hastag);
        formData.append("description", updateStory.description);
        if(imageSize?.height && imageSize?.width){
          formData.append("heigth", imageSize?.height);
          formData.append("width", imageSize?.width);
        }
        if (image && image?.name) {
          formData.append("exploreMedia", image);
        }
        if(image.type === "video/mp4"){
            formData.append("videoThumbnail", BaseToImage(videoThumbnail));
        }
        const { data } = await updateStoryApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "updateStory");
          fetchExploreHandler();
          modalClose();
        } else {
          toastMessage(data.message, "error", "updateStory");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  useEffect(() => {
    setUpdateStory({
      id: selectedExploreDetail._id,
      description: selectedExploreDetail.description,
      title: selectedExploreDetail.title,
      hastag: selectedExploreDetail.hastag,
    });
    setImage(selectedExploreDetail.media);
    setVideoThumbnail(selectedExploreDetail.videoThumbnail);
  }, [selectedExploreDetail]);

    console.log(image,"Image");

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Edit Stories"}
      closeIcon
      custom
      size={"sm"}
      customizedFooter={
        <div className="model_custom_footer">
          {/* <p>{MESSAGE_SIZE}</p> */}
          <button
            className="btn_size "
            ref={buttonref}
            onClick={updateStoryHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateStory?.title || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Title"
            className={`form-control `}
            label={"Title"}
            error={!updateStory.title && error.banner}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            name="hastag"
            value={updateStory?.hastag || ""}
            onChange={(e) => handleChange(e)}
            required
            className="form-control"
            label="Hashtag"
            error={!updateStory.hastag && error.hastag}
          />
        </div>
        <div className="form-group mb-2">
          <TextArea
            name="description"
            value={updateStory?.description || ""}
            onChange={(e) => handleChange(e)}
            required
            className="form-control"
            label="Description"
            error={!updateStory.description && error.description}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="video/*,image/*"
            name="exploreMedia"
            // required
            onChange={(e) => imageHandler(e)}
            placeholder="Media Image"
            className={`form-control `}
            label={"Media"}
            error={!image && error.image}
          />
        </div>

        <div>
          <div className="my-2">
            {image.type === "video" || videoFileRegex.test(image?.name) ? (
              <Fragment >
                <div className="video-container"  key={image &&image.lastModified}>
                  <VideoPreview
                    videoUrl={
                      image?.name ? URL.createObjectURL(image) : image.url
                    }
                  />
                </div>
                {image?.name && (
                  <div className="video-thumbnail">
                    <VideoThumbnail   
                    key={image&&image.lastModified}
                      videoUrl={URL.createObjectURL(image)}
                      thumbnailHandler={(thumbnail) =>
                        setVideoThumbnail(thumbnail)
                      }
                      renderThumbnail={false}
                      snapshotAtTime={4}
                      width="100px"
                      height="120"
                    />
                  </div>
                )}
              </Fragment>
            ) : (
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={image.name ? URL.createObjectURL(image) : image.url}
              />
            )}
          </div>
        </div>
      </div>
    </ModalBox>
  );
};

export default EditStories;
