export const dateFormatter = {
    formatOne: (dateStr) => {
        const date = new Date(dateStr);

        // Convert to India Standard Time (UTC+5:30)
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330);

        // Extract the date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const year = date.getFullYear() % 100; // Get last two digits of the year

        // Extract the time components
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // Convert '0' to '12'

        // Format minutes to be two digits
        const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

        // Combine the components into the desired format
        return `${hours}:${minutesFormatted} ${ampm}, ${day}/${month}/${year}`;
    }
}