import { toastMessage } from "./toastMessage";
import commonMessages from "./commonMessages";

export const checkImageDimensions = (image, width = 500, height = 500) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      if (img.width > width || img.height > height) {
        toastMessage(
          commonMessages.MESSAGE_IMAGE_SIZE,
          "error",
          "imageDimension"
        );
        resolve(false);
      } else {
        resolve(true);
      }
    };
  });
};


export const getImageDimension = (image, defaultWidth = 500, defaultHeight = 500) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      });
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
};

export const checkImageAspectRatio = (image, aspectRatio = "9 / 16") => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    img.onload = () => {
      const imageAspectRatio = img.width / img.height;
      const targetAspectRatio = eval(aspectRatio.replace("x", "*"));
      const threshold = 0.01;

      if (Math.abs(imageAspectRatio - targetAspectRatio) > threshold) {
        toastMessage(
          `{Image aspect ratio must be ${aspectRatio == "9/16"?"9:16":"16:9"}}`,
          "error",
          "imageAspectRatio"
        );
        resolve(false);
      } else {
        resolve(true);
      }
    };
  });
};
