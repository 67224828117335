import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

export const CustomTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#FF8989',
  },
})

export const CustomTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightSemiBold,
  fontSize: theme.typography.pxToRem(15),
  backgroundColor: '#ebe8e8',
  // marginRight: theme.spacing(1),
  color: '#242424',
  '&.Mui-selected': {
    color: '#fff',
    fontSize: 17,

    backgroundColor: '#FF8989',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#FF8989',
  },
}))
