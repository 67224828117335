import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../../components/Switch/Switch";
import NotFound from "../../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { music } from "../../../routes/route";
import common from "../../../routes/common";
import { toastMessage } from "../../../utils/toastMessage";
import findSerialNumber from "../../../utils/findSerialNumber";
import { charLimit } from "../../../utils/charLimit";
import { fetchremedyListHandler } from "../../../services/musicManagement";
import EditIcon from "@mui/icons-material/Edit";
import showFullSizeImage from "../../../utils/showFullSizeImage";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import AddMusic from './AddMusic'
// import MusicStatus from './MusicStatus'
// import EditMusic from './EditMusic'
import scrollToUp from "../../../utils/scrollToUp";
// import PlayMusic from './PlayMusic'
import { MdLibraryMusic } from "react-icons/md";
import { getLocalStorage, setLocalStorage } from "../../../config/config";
import ImagePreviewModel from "../../../components/imagePreview/ImagePreviewModel";
import AddIcon from "@mui/icons-material/Add";
import { fetchRemedyListHandler } from "../../../services/concern/Index";
import AddRemedy from "./AddRemedy";
import RemedyStatus from "./RemedyStatus";
import EditRemedy from "./EditRemedy";
import DescriptionModel from "../../../components/imagePreview/DescriptionModel";

const { NOT_FOUND } = common;
const { MUSIC_DETAIL, MUSIC_LIST } = music;

const RemedyList = () => {
  const { concern, id } = useParams();
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  // const [search, setSearch] = useState(searchParam.get('query') || '')

  const [search, setSearch] = useState(
    getLocalStorage("remedyList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  let limit = +searchParam.get("limit") || 10;
  const [addRemedyModal, setAddRemedyModal] = useState(false);
  const [editRemedyModal, setEditRemedyModal] = useState(false);
  const [remedyList, setRemedyList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedRemedyDetail, setSelectedRemedyDetail] = useState(null);
  const [playMusicModel, setPlayMusicModel] = useState(false);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [descriptionModel,setDescriptionModel]=useState(false);
  const [description,setDescription]=useState("");

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage("remedyList", "search", search);
  }, [search]);

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  // update music category handler
  const updateRemedyHandler = (data) => {
    setSelectedRemedyDetail({ ...data, areaOfConcernId: id } || null);
    setEditRemedyModal(true);
  };

  // Play music handler
  const playMusicHandler = (data) => {
    setSelectedRemedyDetail(data);
    setPlayMusicModel(true);
  };

  const showImagePreviewHandler = (music) => {
    setShowImagePreviewModel(true);
    setImagePreview(music.musicImage);
    setImageTitle(music.title);
  };

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // close model

  const closeModel = () => {
    setSelectedRemedyDetail(null);
    setStatusValue("");
    setStatusId("");
    setImagePreview("");
    setImageTitle("");
    setAddRemedyModal(false);
    setStatusModal(false);
    setEditRemedyModal(false);
    setPlayMusicModel(false);
    setShowImagePreviewModel(false);
  };

  // //------------------listing-music-Api----------------------------------
  const fetchRemedyList = async () => {
    try {
      setRemedyList([]);
      showLoader();
      const sendData = {
        areaOfConcernId: id,
        page: activePage,
        limit: 10,
      };
      if (debounceValue) {
        sendData.page = 1;
        sendData.search = debounceValue;
        searchParam.set("page", 1);
        setSearchParam(searchParam);
      }
      const { data } = await fetchRemedyListHandler(sendData);
      if (data.code === 200) {
        setRemedyList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setRemedyList([]);
        setTotal(0);
        toastMessage(data.message, "error", "remedyListHandler");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchRemedyList();
  }, [searchParam]);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              <Link className="back_icon" to={-3}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">{concern || ""}</h4>
            </div>
            <div className="block_user_button">
              <button onClick={() => setAddRemedyModal(true)}>
                <AddIcon />
                Add Remedy
              </button>
            </div>
          </div>

          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
                // disabled={remedyList.length === 0}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {remedyList.length > 0 ? (
                  remedyList.map((remedy, index) => {
                    return (
                      <React.Fragment key={remedy._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 5)}</td>
                          {/* <td>{remedy._id || '---'}</td> */}
                          <td>{charLimit(remedy.title, 30) || "---"}</td>
                          {/* <td>{remedy?.description}</td> */}
                          <td
                            onClick={() => {
                              if (
                                remedy.description &&
                                remedy.description.length > 50
                              ) {
                                setDescriptionModel(true);
                                setDescription(remedy.description);
                              }
                            }}
                          >
                            {remedy?.description &&
                              (remedy.description.length > 50
                                ? charLimit(remedy.description, 50)
                                : remedy.description || "---")}
                          </td>

                          <td>
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() => updateRemedyHandler(remedy)}
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={remedy.status}
                                onChange={() =>
                                  statusUpdateHandler(remedy._id, remedy.status)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Remedy not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 5 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={5}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addRemedyModal && (
          <AddRemedy
            show={addRemedyModal}
            close={closeModel}
            listing={fetchRemedyList}
            remedyId={id}
          />
        )}
        {statusModal && (
          <RemedyStatus
            show={statusModal}
            close={closeModel}
            setListing={setRemedyList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editRemedyModal && (
          <EditRemedy
            show={editRemedyModal}
            close={closeModel}
            remedyDetail={selectedRemedyDetail}
            setRemedyDetail={setSelectedRemedyDetail}
            remedyListing={fetchRemedyList}
          />
        )}
        {/* {playMusicModel && (
          <PlayMusic
            show={playMusicModel}
            close={closeModel}
            musicDetail={selectedRemedyDetail}
            setMusicDetail={setSelectedRemedyDetail}
          />
        )} */}
      {DescriptionModel && (
          <DescriptionModel
            show={descriptionModel}
            close={() => {
              setDescriptionModel(false);
            }}
            description={description}
            title={"Description"}
          />
        )}
      </div>
    </>
  );
};

export default RemedyList;
