import React from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import useButtonLoader from '../../../hooks/UseButtonLoader'
import { toastMessage } from '../../../utils/toastMessage'
import { changeTrainingStatus } from '../../../services/trainingManagement'

const UpdateTrainingStatus = ({
  show,
  close,
  status,
  id,
  setListing,
  categoryId,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //--------------change-status-sub-category------------------------
  const changeTrainingStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = {
        status: !status,
        growthTrainingMediaId: id,
        // growthTrainingCategoryId: categoryId,
      }
      const { data } = await changeTrainingStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((training) => {
            if (training._id === id) {
              return { ...training, status: !status }
            }
            return training
          })
        })
        toastMessage(data.message, 'success', 'TrainingStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'TrainingStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Training Status"
      description="Do you want to change the status of this training?"
      action={changeTrainingStatusHandler}
      ref={buttonref}
    />
  )
}

export default UpdateTrainingStatus
