import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeConsultantStatus, changeConsultantStatusisFake } from '../../services/consultantManagement'

const ConsultantStatus = ({ show, close, setListing, status, id }) => {
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Yes')

  // handle all function

  //--------------change-status-category------------------------
  const changeStatus = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, consultantId: id }
      const { data } = await changeConsultantStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((consultant) => {
            if (consultant._id === id) {
              return { ...consultant, status: !status }
            }
            return consultant
          })
        })

        toastMessage(data.message, 'success', 'consultantStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'consultantStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change User Status"
      description="Do you want to change the status of this consultant?"
      action={changeStatus}
      ref={buttonref}
    />
  )
}




export const FakeStatusModal = ({ show, close, setListing, status, id }) => {
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Yes')

  // handle all function

  //--------------change-status-category------------------------
  const changeStatus = async () => {
    try {
      buttonLoader(true)
      let sendData = { isFake: !status, consultantId: id }
      const { data } = await changeConsultantStatusisFake(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((consultant) => {
            if (consultant._id === id) {
              return { ...consultant, isFake: !status }
            }
            return consultant
          })
        })

        toastMessage(data.message, 'success', 'consultantStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'consultantStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change User Fake Status"
      description="Do you want to change the status of Fake or Real for this consultant?"
      action={changeStatus}
      ref={buttonref}
    />
  )
}

export default ConsultantStatus