import Collapse from '@mui/material/Collapse'
import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const SubMenu = ({ isChild, icon, name, path, index }) => {
  const location = useLocation()
  const [open, setOpen] = useState(true)

  return (
    <li
      className={location.pathname === path ? 'active' : ''}
      // className={isChild.some(child => location.pathname === child.path) ? "active" : ""}
    >
      <a onClick={() => setOpen(!open)}>
        <span>{icon}</span>
        {name}
        {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
      </a>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ol className={`dropdown-menu ${open && 'd-block'}`}>
          {isChild &&
            isChild
              .filter((child) => child.enabled)
              .map((child) => {
                return (
                  <li
                    className={`py-2 ${
                      location.pathname === child.path ? 'active' : ''
                    }`}
                    key={child.path}
                  >
                    <NavLink to={child.path}>
                      <span className="">{child.icon}</span>
                      {child.name}
                    </NavLink>
                  </li>
                )
              })}
        </ol>
      </Collapse>
    </li>
  )
}

export default SubMenu
