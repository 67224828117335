import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import { routes } from "./Sidebar";
import Sidebar from "../comman/Sidebar";
import ForgotPassword from "../pages/auth/BeforeForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import GoToLoginPage from "../pages/auth/GoToLoginPage";
import LoginSuccess from "../pages/auth/passwordResetSuccess";
import Header from "../comman/Header";
import Error from "../components/not-found/Error";
import { auth } from "./route";
import { useDispatch, useSelector } from "react-redux";
import { setRightsInfo, setRole } from "../utils/store/slices/rightsSlice";
const { FORGET_PASSWORD_ROUTE, RESET_PASSWORD_ROUTE } = auth;

const Routing = () => {
  const dispatch = useDispatch();
  const { rightsInfo, role } = useSelector((state) => state?.vedicRights);
  const SidebarLayout = () => (
    <>
      <Header />
      <Sidebar />
      <Outlet />
    </>
  );
  console.log(rightsInfo, role, "Rightss---");

  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem("vedic_token")) {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("vedic_rights"));
    dispatch(setRightsInfo({ rightsInfo: data }));
    dispatch(setRole({ role: localStorage.getItem("vedic_role") }));
  }, []);

  return (
    <Routes>
      {/* ------------------------------------- before Login Routes----------------------------------------------------- */}
      <Route path="/" element={<Login />} />
      <Route path={FORGET_PASSWORD_ROUTE} element={<ForgotPassword />} />
      {/* <Route path="/go-to-login-page" element={<GoToLoginPage />} /> */}
      <Route
        path={`${RESET_PASSWORD_ROUTE}/:token`}
        element={<ResetPassword />}
      />
      <Route path="/password-reset-success" element={<LoginSuccess />} />

      {/* ------------------------------------- After Login Routes----------------------------------------------------- */}
      <Route element={<SidebarLayout />}>
        {routes(rightsInfo,role).map(({ id, path, element }) => (
          <Route
            key={id}
            path={path}
            element={<ProtectedRoute>{element}</ProtectedRoute>}
          />
        ))}
      </Route>
      <Route path="*" element={<ProtectedRoute>{<Error />}</ProtectedRoute>} />
    </Routes>
  );
};

export default Routing;
