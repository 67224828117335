import React from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import { charLimit } from '../../../utils/charLimit'

const PlayMusic = ({ show, close, musicDetail, setMusicDetail }) => {
  // handle all function
  const modalClose = () => {
    setMusicDetail(null)
    close()
  }
  return (
    <ModalBox
      status={show}
      onClose={close}
      closeIcon
      title={charLimit(musicDetail.title, 32)}
      custom
      customizedFooter={
        <button className="btn_size" onClick={modalClose}>
          Close
        </button>
      }
    >
      {musicDetail.musicMedia && (
        <div className="music-player">
          <audio
            src={musicDetail && musicDetail.musicMedia}
            // autoPlay={true}
            controls
            controlsList="nodownload"
          />
        </div>
      )}
    </ModalBox>
  )
}

export default PlayMusic
