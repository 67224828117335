import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import NotFound from '../../components/not-found/NotFound'
import { Link, useSearchParams } from 'react-router-dom'
import IconButton from '../../components/Button/IconButton'
import { Select, TextArea } from '../../components/Form'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import useDebounce from '../../hooks/useDebounce'
import { IoSearch } from 'react-icons/io5'
import { getLocalStorage, setLocalStorage } from '../../config/config'
import findSerialNumber from '../../utils/findSerialNumber'
import { toastMessage } from '../../utils/toastMessage'
import common from '../../routes/common'
import { dateFormat } from '../../utils/dateFormat'
import scrollToUp from '../../utils/scrollToUp'
import { CapitalizeFirstWord } from '../../utils/CapitalizeFirstWord'
import Loader from '../../comman/Loader'
import {
  fetchLiveApi,
  sendMessageApi,
} from '../../services/liveManagement/Index'
import ModalBox from '../../components/modal/ModalBox'
import { emitData, socketConnection } from '../../utils/webSocketServices'
import useButtonLoader from '../../hooks/UseButtonLoader'

const { NOT_FOUND } = common

const LiveManagement = () => {
  const [buttonRef, buttonLoader] = useButtonLoader('Send Message')
  const [messageModal, setMessageModal] = useState(false)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1

  const [search, setSearch] = useState(
    getLocalStorage('liveList', 'search') || ''
  )
  const debounceValue = useDebounce(search || '', 300)
  const [total, setTotal] = useState(0)
  const [liveList, setLiveList] = useState([])
  const [eventId, setEventId] = useState('')
  const [viewModal, setViewModal] = useState(false)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const status = searchParam.get('status') || ''

  useEffect(() => {
    setLocalStorage('liveList', 'search', search)
  }, [search])

  const statusHandler = (value) => {
    if (status === value) {
      searchParam.delete('status')
    } else {
      searchParam.set('status', value)
    }
    searchParam.delete('page')
    setSearchParam(searchParam)
  }

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }
  //------------------request-listing-Api----------------------------------
  const getliveList = async (debounceValue) => {
    try {
      showLoader()
      const sendData = {
        page: `${activePage}`,
        limit: '10',
      }
      if (debounceValue) {
        sendData.page = '1'
        sendData.search = debounceValue
        searchParam.delete('page')
        setSearchParam(searchParam)
      }
      if (status) {
        sendData.status = status
      }
      setLiveList([])
      const { data } = await fetchLiveApi(sendData)
      if (data.code === 200) {
        setLiveList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setLiveList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'liveList')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  const closeModel = () => {
    setViewModal(false)
    setEventId('')
  }

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete('page')
    }
    if (!status) {
      searchParam.delete('status')
    }
    setSearchParam(searchParam)
    getliveList(debounceValue)
  }, [searchParam, debounceValue])

  useEffect(() => {
    if (viewModal) {
      socketConnection()
    }
  }, [viewModal])

  const endBroadcastHandler = (_id) => {
    setEventId(_id)
    setViewModal(true)
  }

  const endBroadcast = async () => {
    try {
      buttonLoader(true)
      await emitData(
        'admin_end_call',
        {
          broadcastId: eventId,
        },
        liveList,
        setLiveList
      )
      buttonLoader(false)
      setViewModal(false)
      setEventId('')
      // getliveList();
    } catch (e) {
      console.log(e.message)
    } finally {
      buttonLoader(false)
    }
  }

  const messageValidation = () => {
    let status = true
    if (!message) {
      setMessageError('Message is mandatory')
      status = false
    }
    return status
  }

  const messageHandler = async () => {
    if (messageValidation()) {
      const connectSocket = socketConnection()
      buttonLoader(true)
      try {
        const req = await sendMessageApi({
          broadcastId: eventId,
          message: message,
        })
        if (req?.data?.code === 200) {
          toastMessage(req?.data?.message, 'success', 'endBroadcast')
          setMessage('')
          setMessageError('')
          setMessageModal(false)
          // await emitData(
          //   'admin_message_count',
          //   {
          //     broadcastId: eventId,
          //   },
          //   liveList,
          //   setLiveList
          // )
          connectSocket.emit('admin_message_count', {
            broadcastId: eventId,
          })
        } else {
          toastMessage(req?.data?.message, 'error', 'broadcast data')
        }
      } catch (e) {
        console.log(e.message)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Live Management</h4>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && 'active'}`}
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>

            <div className="group_filter_value">
              {/* Gender-Filter */}
              <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${status && 'active'}`}
                  value={status || ''}
                  onChange={(e) => statusHandler(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="0">Scheduled</option>
                  <option value="1">Ongoing</option>
                  <option value="2">Completed</option>
                  <option value="3">Cancelled</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  {/* <th>Consultant ID</th> */}
                  <th>Consultant Name</th>
                  <th>Event Name</th>
                  <th>Start Date & Time</th>
                  <th>Joined User</th>
                  <th>Like</th>
                  <th>Status</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {liveList.length > 0 ? (
                  liveList.map((broadcastData, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          {/* <td className="user_profile_image">
                            {broadcastData?.consultantDetails?._id || "---"}
                          </td> */}
                          <td className="user_profile_image">
                            {broadcastData?.consultantDetails?.name || '---'}
                          </td>
                          <td>{broadcastData.eventName || '---'}</td>
                          <td>
                            {dateFormat(broadcastData.eventDate) || '---'},{' '}
                            {broadcastData?.eventTime}
                          </td>
                          <td>{broadcastData?.joinedUserListCount || '0'}</td>
                          <td>{broadcastData?.likeCount || '0'}</td>
                          <td>
                            {broadcastData?.status === 0
                              ? 'Schedule/Pending'
                              : broadcastData?.status === 1
                              ? 'Ongoing'
                              : broadcastData?.status === 2
                              ? 'Completed'
                              : broadcastData?.status === 3
                              ? 'Cancelled'
                              : ''}
                          </td>

                          <td>
                            {/* {broadcastData?.status === 1 && ( */}
                            <div className="action_button">
                              {/* <Link to="#">
     <IconButton
       aria-label="update"
       className="px-2 py-0"
       //   onClick={() => {
       //     setViewModal(true);
       //     setEventId({
       //       eventId: broadcastData?._id,
       //       approveStatus:
       //         broadcastData.approveStatus,
       //     });
       //     // setNewData(broadcastData?.newData);
       //     // setCurrentData(broadcastData.currentData);
       //   }}
       onClick={() => {
         endBroadcastHandler(broadcastData?._id);
       }}
     >
       End
     </IconButton>
   </Link> */}
                              <Link to="#">
                                <div className="block_user_button">
                                  <button
                                    className={`${
                                      broadcastData?.status === 1
                                        ? ''
                                        : 'disabledBtn'
                                    }`}
                                    disabled={broadcastData?.status !== 1}
                                    onClick={() => {
                                      endBroadcastHandler(broadcastData?._id)
                                    }}
                                  >
                                    End
                                  </button>

                                  <button
                                    className={`${
                                      broadcastData?.status === 1
                                        ? ''
                                        : 'disabledBtn'
                                    } mx-2`}
                                    disabled={broadcastData?.status !== 1}
                                    onClick={() => {
                                      setMessageModal(true)
                                      setEventId(broadcastData?._id)
                                    }}
                                  >
                                    Message
                                  </button>
                                </div>
                              </Link>
                            </div>
                            {/* )} */}
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <ModalBox
        status={viewModal}
        onClose={closeModel}
        title="End Live Broadcast"
        description="Are you sure you want to end this live broadcast?"
        action={endBroadcast}
        ref={buttonRef}
      />

      <ModalBox
        status={messageModal}
        onClose={() => {
          setMessage('')
          setMessageError('')
          setMessageModal(false)
        }}
        title={'Send Message'}
        closeIcon
        custom
        customizedFooter={
          <button className="btn_size" ref={buttonRef} onClick={messageHandler}>
            Send Message
          </button>
        }
      >
        <div>
          <div className="form-group mb-2">
            <div className="cstm_span_style">
              {/* <span>
              {' '}
              <strong>Category: </strong> {data.categoryId.enName}
            </span> */}
            </div>
          </div>

          <div className="form-group mb-2">
            <TextArea
              type="text"
              name="message"
              value={message || ''}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter Message"
              className={`form-control `}
              label={'Message'}
              error={messageError}
              maxLength={30}
            />
          </div>
        </div>
      </ModalBox>
    </>
  )
}

export default LiveManagement
