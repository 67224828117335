import React from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import useButtonLoader from '../../../hooks/UseButtonLoader'
import { toastMessage } from '../../../utils/toastMessage'
import { changeRemedyStatus } from '../../../services/concern/Index'

const RemedyStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //--------------change-status-sub-category------------------------
  const changeRemedyStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, areaOfConcernRemedyId: id }
      const { data } = await changeRemedyStatus(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((remedy) => {
            if (remedy._id === id) {
              return { ...remedy, status: !status }
            }
            return remedy
          })
        })
        toastMessage(data.message, 'success', 'remedyStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'musicCategoryStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
//   console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Remedy Status"
      description="Do you want to change the status of this remedy?"
      action={changeRemedyStatusHandler}
      ref={buttonref}
    />
  )
}

export default RemedyStatus
