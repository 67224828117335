import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeVastuStatusCategory } from '../../services/vastuCompassCategory'


const VastuCompassCategoryStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Add')

  //--------------change-status-sub-category------------------------
  const changeVastuCategoryStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, vastuCategoryId: id }
      const { data } = await changeVastuStatusCategory(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((category) => {
            if (category._id === id) {
              return { ...category, status: !status }
            }
            return category
          })
        })
        toastMessage(data.message, 'success', 'vastuCategoryStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'vastuCategoryStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Vastu Category Status"
      description="Do you want to change the status of this vastu compass category?"
      action={changeVastuCategoryStatusHandler}
      ref={buttonref}
    />
  )
}

export default VastuCompassCategoryStatus
