import React, { useEffect, useState } from 'react'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import { generateMonthsWithFilterRange, ShowMonthList } from './components/commons'
import { ConsultantPayoutCurrentMonthCMP, ConsultantPayoutMonthCMP } from './components/Months-Listing';


export default function ConsultantPayout() {

  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const { currentMonth, monthList } = generateMonthsWithFilterRange(15);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth || null);

  console.log('monthList --> ', monthList)
  useEffect(() => {
    if (currentMonth.monthName !== selectedMonth.monthName) {
      console.log('Other Month!!!')
    }
  }, [selectedMonth]);

  return (
    <div>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div style={{ display: 'flex', gap: 12 }}>
            <ShowMonthList currentMonth={currentMonth} monthList={monthList} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
            <div style={{ flex: 1, overflow: 'scroll' }}>
              {(currentMonth.monthName == selectedMonth.monthName) ? <div>
                <ConsultantPayoutCurrentMonthCMP />
              </div> : <div>
                <ConsultantPayoutMonthCMP />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
