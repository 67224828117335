import React, { useEffect, useRef, useState } from "react";
import { FaPaperclip } from "react-icons/fa";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Avatar, IconButton } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchQueryApi,
  fetchQueryStatusApi,
  sendChatApi,
} from "../../services/supportManagement";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toastMessage } from "../../utils/toastMessage";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { Input } from "../../components/Form";
import { regex } from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import adminImage from "../../Assests/image/adminImage.png";
import userImage from "../../Assests/image/userImage.png";
import { dateFormatter } from "../../utils/common-functions";
import { useCreatePortal } from "../../hooks/useCreatePortal";
import { AudioRecorder } from "../../components/audioPreview/AudioPreview";
import { emitData, getSocketInstance, socketConnection } from "../../utils/webSocketServices";

const ViewSupport = () => {
  const { status, id, name } = useParams();
  const chatRef = useRef(null);
  const keyboardState = useRef();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState([]);
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [updatedQueryStatusModal, setUpdateQueryStatusModal] = useState(false);
  const [tempState, openImgView, closeImgView, ImgModalWrapper] = useCreatePortal()
  const [mediaModal, setMediaModal] = useState(false);
  const [media, setMedia] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { imageFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;

  const scrollToBottom = () => {
    console.log('scrolling to bottom ::: ',
      chatRef.current.scrollBottom,
      chatRef.current.scrollHeight)
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  const fetchQuery = async () => {
    try {
      showLoader();
      const { data } = await fetchQueryApi(id);
      if (data?.code === 200) {
        setQuery(data?.result?.list || []);
      } else {
        setQuery([]);
      }
      setTimeout(scrollToBottom, 200);
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const fetchQueryStatus = async () => {
    try {
      buttonLoader(true);
      const { data } = await fetchQueryStatusApi(id);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "queryUpdated");
        navigate("/support-management");
      } else {
        toastMessage(data?.message, "error", "queryUpdated");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setMedia(e.target.files[0]);
      } else {
        e.target.value = "";
        setMedia("");
      }
    } else {
      e.target.value = "";
      setMedia("");
      toastMessage(IMAGE_FORMAT, "error", "imageFormat");
    }
  };

  const validation = () => {
    let status = true;
    if (mediaModal && !media) {
      setError("Image is required");
      status = false;
    }
    if (mediaModal === false && !message) {
      setError("Message is mandatory");
      status = false;
    }
    return status;
  };

  //   Send Message Query
  const chatSendHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("customerSupportQueryId", id);
        if (media && media?.name) {
          formData.append("messageType", 1);
          formData.append("file", media);
        } else {
          formData.append("messageType", 0);
          formData.append("message", message);
        }
        const { data } = await sendChatApi(formData);
        if (data.code === 200) {
          fetchQuery();
          setMediaModal(false);
          setMessage("");
          setMedia("");
        } else {
          toastMessage(data.message, "error", "chatSend");
        }
        setTimeout(scrollToBottom, 200)
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);

      }
    }
  };

  useEffect(() => {

    socketConnection();
    const _socket = getSocketInstance();
    _socket?.on('support_send_message_to_admin_listener', _data => {
      if (_data?.customerSupportQueryId == id) { fetchQuery(); }
    })

    fetchQuery();
  }, []);

  return <>
    <ImgModalWrapper>
      <div style={{ position: 'relative' }}>
        <img
          src={tempState}
          style={{
            objectFit: 'contain', marginBottom: 12, width: '100%', height: '80vh'
          }}
        />
        <button
          style={{
            position: 'absolute', top: 10, right: 10, padding: '2px 12px'
          }}
          onClick={() => { closeImgView() }}>Close</button>
      </div>
    </ImgModalWrapper>
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={-1}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title"> {`Mr. ${name}`}</h4>
          </div>
          <div className="block_user_button">
            <button onClick={() => setUpdateQueryStatusModal(true)}>
              {status == "true" ? "Ticked Closed" : "Close Ticket"}
            </button>
          </div>
        </div>

        <div className="chat-container">
          <div className="chat-box">
            <div
              ref={chatRef}
              className="cstm_chat_scrolling"
            >
              {query &&
                query.length > 0 &&
                query.map((data, index) => {
                  if (
                    data?.senderType === "cons" ||
                    data?.senderType === "user"
                    //   &&
                    // (data?.message || data?.messageType)
                  ) {
                    return (
                      <div
                        key={index}
                        className="chat-message incoming-message"
                      >
                        <img
                          src={data?.senderDetails?.profileImage || userImage}
                          alt="Avatar"
                          className="avatar"
                          height={"200px"}
                          width={"200px"}
                        />
                        {data?.messageType == 1 ? (
                          <div className="message-content">
                            <img
                              src={data?.media?.mediaUrl}
                              width={170}
                              height={200}
                              style={{ objectFit: 'contain', marginBottom: 12 }}
                            />
                            <button
                              style={{ position: 'absolute', top: 12, right: 12, padding: '3px 12px' }}
                              onClick={() => { openImgView(data?.media?.mediaUrl) }}
                            >Open View</button>
                            <p className="date-time"> {dateFormatter.formatOne(data?.createdAt)}</p>
                          </div>
                        ) : data?.messageType == 2 ? (
                          <div className="message-content">
                            <audio
                              src={data?.media?.mediaUrl}
                              autoPlay={false}
                              controls
                              controlsList="nodownload"
                            />
                            <p className="date-time">{dateFormatter.formatOne(data?.createdAt)}</p>
                          </div>
                        ) : <div className="message-content">
                          <p>{data?.message}</p>
                          <p className="date-time">{dateFormatter.formatOne(data?.createdAt)}</p>
                        </div>}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className="chat-message outgoing-message"
                      >
                        {data?.messageType == 1 ? (
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img
                              src={data?.media?.mediaUrl}
                              style={{ width: '60%', height: 300, objectFit: 'contain' }}
                            />
                          </div>
                        ) : data?.messageType == 2 ? (
                          <audio
                            src={data?.media?.mediaUrl}
                            autoPlay={false}
                            controls
                            controlsList="nodownload"
                          />
                        ) : (
                          <div className="message-content">
                            <p>{data?.message}</p>
                            <p className="date-time">{dateFormatter.formatOne(data?.createdAt)}</p>
                          </div>
                        )}
                        <Avatar
                          src={adminImage}
                          alt="Avatar"
                          className="avatar"
                          width={"200px"}
                          height={"200px"}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="input-box">
              <div className="textarea-container">
                <textarea
                  placeholder="Type your message here..."
                  name="message"
                  disabled={status == "true"}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDownCapture={e => {
                    if (e.code === 'Enter') {
                      if (e.shiftKey) {
                        return;
                      } else {
                        e.preventDefault();
                        chatSendHandler();
                      }
                    }
                  }}
                />
                <AudioRecorder />
                <AddPhotoAlternateRoundedIcon
                  className="attach-icon"
                  onClick={() => {
                    setMediaModal(true);
                  }}
                />
              </div>
              <button
                className="send-button"
                disabled={status == "true"}
                onClick={chatSendHandler}
              >
                Send
              </button>
            </div>
          </div>
        </div>

        {/* Updated Query Status Modal */}
        <ModalBox
          status={updatedQueryStatusModal}
          onClose={() => setUpdateQueryStatusModal(false)}
          title={"Update Status"}
          description={"Are you sure want to update status?"}
          action={fetchQueryStatus}
          ref={buttonRef}
        />

        {/* Media Modal  */}

        <ModalBox
          status={mediaModal}
          onClose={() => {
            setMediaModal(false);
            setMedia("");
          }}
          title={"Upload Media"}
          size={"sm"}
          closeIcon
          custom
          customizedFooter={
            <div className="model_custom_footer">
              <button
                className="btn_size"
                // ref={buttonref}
                onClick={chatSendHandler}
              >
                Upload
              </button>
            </div>
          }
        >
          <div>
            <div className="form-group my-2">
              <Input
                type="file"
                accept="image/*"
                name="file"
                // value={image.name || ''}
                required
                onChange={(e) => imageHandler(e)}
                className={`form-control `}
                label={"Image"}
                error={!media && error.media}
              // maxLength={30}
              />
            </div>
            <div>
              {media && (
                <>
                  <Avatar
                    variant="square"
                    sx={{
                      width: "100%",
                      borderRadius: 1,
                      height: 140,
                      border: "1px solid #FF8989",
                    }}
                    src={media && URL.createObjectURL(media)}
                  />
                </>
              )}
            </div>
          </div>
        </ModalBox>
      </div>
    </div>
  </>
};

export default ViewSupport;
