import React, { Fragment, useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { regex, optimizeFunction, allowNumber } from "../../utils/regex";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import {
  AddCategoryApi,
  fatchCategoryApi,
} from "../../services/categoryManagement";
import commonMessages from "../../utils/commonMessages";
import { checkImageDimensions } from "../../utils/checkImageDimension";
import { AddGiftApi } from "../../services/giftManagement";
const { imageFileRegex } = regex;
const { GIFT_TITLE, GIFT_IMAGE, GIFT_COIN, MESSAGE_SIZE, IMAGE_FORMAT } =
  commonMessages;

const AddGift = ({ show, close, listing }) => {
  // handle all state
  const [giftInput, setGiftInput] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState({});

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!giftInput.title) {
      errorMessage["title"] = GIFT_TITLE;
      status = false;
    }
    if (!giftInput.coin) {
      errorMessage["coin"] = GIFT_COIN;
      status = false;
    }
    if (!image.name) {
      errorMessage["image"] = GIFT_IMAGE;
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  // Add  Gift handler function

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        const validSize = await checkImageDimensions(e.target.files[0]);
        if (validSize) {
          setImage(e.target.files[0]);
        } else {
          e.target.value = "";
          setImage("");
        }
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  //------------------modal-close--------------------------
  const modalClose = () => {
    setImage("");
    setGiftInput({});
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const addCategoryHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", giftInput.title);
        formData.append("coin", giftInput.coin);
        formData.append("giftIcon", image);

        const { data } = await AddGiftApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addGift");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addGift");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Gift"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          {/* <p>{MESSAGE_SIZE}</p> */}
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addCategoryHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={giftInput.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setGiftInput({ ...giftInput, ["title"]: sanitizedText });
            }}
            placeholder="Gift Name"
            className={`form-control`}
            label={"Gift Name"}
            error={!giftInput.title && error.title}
            maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
              <Input
            type="text"
            name="coin"
            value={giftInput.coin || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setGiftInput({ ...giftInput, ["coin"]: sanitizedText });
            }}
            placeholder="Coin"
            className={`form-control`}
            label={"Coin"}
            error={!giftInput.coin && error.coin}
            maxLength={5}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="giftImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Gift Image"
            className={`form-control `}
            label={"Gift Icon"}
            error={!image && error.image}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddGift;
