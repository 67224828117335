import React from 'react'
import MuiButton from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Button = ({ children, to, loading, icon, endIcon, ...rest }) => {
  const CustomButton = styled(MuiButton)(({ theme }) => ({
    color: '#fff',
    borderColor: '#28B17B',
    backgroundColor: '#28B17B',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#fff',
      borderColor: '#28B17B',
      backgroundColor: '#28B17B',
    },
    '&:disabled': {
      color: '#F5ED9C',
      opacity: 0.8,
      cursor: 'not-allowed',
    },
  }))
  return (
    <>
      {/* If link */}
      {to ? (
        <Link to={to} {...rest}>
          <CustomButton
            // color="error"
            variant="outlined"
            {...rest}
          >
            {children}
          </CustomButton>
        </Link>
      ) : loading ? (
        <CustomButton
          // color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </CustomButton>
      ) : (
        <CustomButton
          // color="error"
          variant="outlined"
          {...rest}
          endIcon={icon && endIcon}
        >
          {children}
        </CustomButton>
      )}

      {/* if loading is true */}
      {/* {loading && (
        <CustomButton
          color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </CustomButton>
      )} */}
    </>
  )
}

export const SecondaryButton = ({
  children,
  to,
  loading,
  icon,
  endIcon,
  ...rest
}) => {
  const CustomButton = styled(MuiButton)(({ theme }) => ({
    color: '#000',
    border: '2px solid #cbcaca',
    background: 'transparent',
    padding: '6px 20px',
    '&:hover': {
      color: '#fff',
      border: '2px solid #fd4f4fe5',
      backgroundColor: '#D37676',
      padding: '6px 20px',
    },
    '&:disabled': {
      color: '#F5ED9C',
      opacity: 0.8,
      cursor: 'not-allowed',
    },
  }))
  return (
    <>
      {/* If link */}
      {to ? (
        <Link to={to} {...rest}>
          <CustomButton
            // color="error"
            variant="outlined"
            {...rest}
          >
            {children}
          </CustomButton>
        </Link>
      ) : loading ? (
        <CustomButton
          // color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </CustomButton>
      ) : (
        <CustomButton
          // color="error"
          variant="outlined"
          {...rest}
          endIcon={icon && endIcon}
        >
          {children}
        </CustomButton>
      )}

      {/* if loading is true */}
      {/* {loading && (
        <CustomButton
          color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </CustomButton>
      )} */}
    </>
  )
}
