import React, { Fragment, useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, TextArea } from "../../components/Form";
import { allowNumber, optimizeFunction } from "../../utils/regex";
import useButtonLoader from "../../hooks/UseButtonLoader";
// import { addContentApi, editContentApi } from "../../../../services/cms";
import { Avatar } from "@mui/material";
// import { checkImageDimensions } from "../../utils/checkImageDimension";
import { regex } from "../../utils/regex";
import { editGiftApi } from "../../services/giftManagement";
import { Select } from "../../components/Form";
import commonMessages from "../../utils/commonMessages";
import { toastMessage } from "../../utils/toastMessage";
import { fatchCategoryApi } from "../../services/categoryManagement";

const EditGift = ({
  setEditModal,
  editModal,
  fetchGiftHandler,
  selectedGift,
  setSelectedGift,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  const [giftImage, setGiftImage] = useState("");
  const [error, setError] = useState({});
  const { IMAGE_FORMAT } = commonMessages;
  const { imageFileRegex } = regex;
  const [coins, setCoins] = useState([]);

  const modalClose = () => {
    setError({});
    setEditModal(false);
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!selectedGift.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    if (!selectedGift.coin) {
      errors["coin"] = "Coin is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // const validSize = await checkImageDimensions(e.target.files[0]);
        // if (validSize) {
        //   setGiftImage(e.target.files[0]);
        // } else {
        //   e.target.value = "";
        //   setGiftImage("");
        setGiftImage(e.target.files[0]);
        // }
      } else {
        e.target.value = "";
        setGiftImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setGiftImage("");
    }
  };

  const editGiftHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", selectedGift.title);
        formData.append("giftId", selectedGift._id);
        formData.append("coin", selectedGift.coin);
        if (giftImage && giftImage?.name) {
          formData.append("giftIcon", giftImage);
        }
        const { data } = await editGiftApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "editGift");
          fetchGiftHandler();
          modalClose();
        } else {
          toastMessage(data.message, "error", "editGift");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={editModal}
      onClose={modalClose}
      title={"Edit Gift"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={editGiftHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={selectedGift?.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setSelectedGift({ ...selectedGift, ["title"]: sanitizedText });
            }}
            placeholder="Gift"
            className={`form-control `}
            label={"Gift Name"}
            error={!selectedGift.title && error.title}
            maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="coin"
            value={selectedGift.coin || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedGift({ ...selectedGift, ["coin"]: sanitizedText });
            }}
            placeholder="Coin"
            className={`form-control`}
            label={"Coin"}
            error={!selectedGift.coin && error.coin}
            maxLength={5}
          />
        </div>

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="giftImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Gift Image"
            className={`form-control `}
            label={"Gift Image"}
            // error={!giftImage && error.image}
          />
        </div>

        <div>
          {
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={
                  giftImage && giftImage.name
                    ? URL.createObjectURL(giftImage)
                    : selectedGift.giftIcon
                }
              />
            </>
          }
        </div>
      </div>
    </ModalBox>
  );
};

export default EditGift;
