import QrCodeIcon from "@mui/icons-material/QrCode";
import { FaUserAlt, FaUsers } from "react-icons/fa";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { IoSearch } from "react-icons/io5";
import notFound from "../Assests/image/notFound.png";
import { IoMdImages } from "react-icons/io";
import { MdLibraryMusic, MdModelTraining } from "react-icons/md";
import { GiMeditation } from "react-icons/gi";
import RedeemIcon from "@mui/icons-material/Redeem";
import PeopleIcon from "@mui/icons-material/People";
import ExploreIcon from "@mui/icons-material/Explore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import DiscountIcon from "@mui/icons-material/Discount";
import StarIcon from "@mui/icons-material/Star";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PaidIcon from '@mui/icons-material/Paid';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import FeedIcon from '@mui/icons-material/Feed';
import StarRateIcon from '@mui/icons-material/StarRate';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const common = {
  CATEGORY_ICON: <QrCodeIcon sx={{ fontSize: 18 }} />,
  USER_ICON: <FaUserAlt sx={{ fontSize: 18 }} />,
  CONSULTANT_ICON: <FaUsers sx={{ fontSize: 18 }} />,
  DASHBOARD_ICON: <DashboardIcon sx={{ fontSize: 18 }} />,
  SEARCH_ICON: <IoSearch />,
  NOT_FOUND: (
    <figure className="not-found-image">
      <img src={notFound} alt="not-found" />
    </figure>
  ),
  MUSIC_ICON: <MdLibraryMusic sx={{ fontSize: 18 }} />,
  MEDITATION_ICON: <GiMeditation sx={{ fontSize: 18 }} />,
  TRAINING_ICON: <MdModelTraining sx={{ fontSize: 18 }} />,
  IMAGE_ICON: <IoMdImages sx={{ fontSize: 18 }} />,
  COMPASS_ICON: <ExploreIcon sx={{ fontSize: 18 }} />,
  GIFT_ICON: <RedeemIcon sx={{ fontSize: 18 }} />,
  COMMUNITY_ICON: <PeopleIcon sx={{ fontSize: 18 }} />,
  EXPLORE_ICON: <ExploreIcon sx={{ fontSize: 18 }} />,
  REFER_ICON: <MonetizationOnIcon sx={{ fontSize: 18 }} />,
  REQUEST_ICON: <AccessibilityIcon sx={{ fontSize: 18 }} />,
  DISCOUNT_ICON: <DiscountIcon sx={{ fontSize: 18 }} />,
  HOROSCOPE_ICON: <StarIcon sx={{ fontSize: 18 }} />,
  LIVE_ICON: <LiveTvIcon sx={{ fontSize: 18 }} />,
  NOTIFICATION_ICON: <CircleNotificationsIcon sx={{ fontSize: 18 }} />,
  SUPPORT_ICON: <SupportAgentIcon sx={{ fontSize: 18 }} />,
  FEEDBACK_ICON: <FeedbackIcon sx={{ fontSize: 18 }} />,
  FORM_ICON: <SummarizeIcon sx={{ fontSize: 18 }} />,
  CONCERN_ICON:<LocalHospitalIcon sx={{fontSize:18}} />,
  TRANSACTION_ICON:<PaidIcon sx={{fontSize:18}} />,
  OFFERS_ICON:<LocalOfferIcon sx={{fontSize:18}} />,
  PAYOUT_ICON:<CreditScoreIcon sx={{fontSize: 18}} />,
  EXPERTISE:<CastForEducationIcon sx={{fontSize: 18}} />,
  PLATFORMCHARGE:<FeedIcon sx={{fontSize: 18}} />,
  COMMISSION:<StarRateIcon sx={{fontSize: 18}} />,
  SUBADMIN_ICON:<GroupAddIcon sx={{fontSize:18}} />

  
  
};

export default common;
