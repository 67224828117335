// import { Chip } from '@mui/material'
// import React from 'react'

// const CommonListing = ({ problemList , categoryList }) => {
//   return (
//     <div className="listing">
//       {problemList &&
//         problemList.length > 0 &&
//         problemList.map((list, index) => {
//           return (
//             <React.Fragment key={index}>
//               <Chip label={list} className="listing_chip" />
//             </React.Fragment>
//           )
//         })}
//     </div>
//   )
// }
// export default CommonListing


import React from 'react';
import { Chip } from '@mui/material';

const CommonListing = ({ problemList, categoryList=[] }) => {
  // console.log(problemList, "ProblemList");
  return (
    <div className="listing">
      {problemList &&
        problemList.length > 0 ?
        problemList.map((problem, index) => {
          // const matchingCategory = categoryList.find(category => category._id === problem);
          // if (matchingCategory) {
            return (
              <React.Fragment key={index}>
                <Chip label={problem} className="listing_chip" />
              </React.Fragment>
            );
          }
          // return "None"; // If no matching category, return null
       ) :  null}
       {/* ) */}
       {/* } */}
    </div>
  );
};


export const CommonListingName = ({ problemList, categoryList=[] }) => {
  // console.log(problemList, "ProblemList");
  return (
    <div className="listing">
      {problemList &&
        problemList.length > 0 &&
        problemList.map((problem, index) => {
          const matchingCategory = categoryList.find(category => category._id === problem);
          if (matchingCategory) {
            return (
              <React.Fragment key={index}>
                <Chip label={matchingCategory?.title} className="listing_chip" />
              </React.Fragment>
            );
          }
           return "None"; // If no matching category, return null
        }
        ) 
        }
    </div>
  );
};


export default CommonListing;

