import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from '../../config/axiosInstance'
  
  // ---------------------fatch-horoscope-listing--------------------------
  export const fetchHoroscopeApi = async (data) => {
    const endPoint =
      `/admin/horoscope` + '?' + new URLSearchParams({ ...data }).toString()
  
    return await axios.get(endPoint, setJwtToken())
  }

  //   Get Horoscope Details
  export const getHoroscopeDetail = async (data) => {
    const endPoint =
      `/admin/horoscope/detail` +
      '?' +
      new URLSearchParams({ ...data }).toString()
    return await axios.get(endPoint, setJwtToken())
  }

  //   //------------------Horoscope Update Detail-------------------------------
export const updateHoroscopeDetail = async (data) => {
    const endPoint = `admin/horoscope/month_update`;
    return await axios.put(endPoint, data, setJwtToken());
  };