import React, { useEffect, useState } from "react";
import {
  fetchExploreDetailApi,
  fetchExploreLikeCommentApi,
  updateCommentVisiblity,
} from "../../services/exploreManagement";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toastMessage } from "../../utils/toastMessage";
import { Avatar, IconButton, Switch } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VideoPreview from "../../components/videoPreview/VideoPreview";
import NotFound from "../../components/not-found/NotFound";
import { dateFormat } from "../../utils/dateFormat";
import profileImage from "../../Assests/image/profile_not_found.png";
import { CustomTab, CustomTabs } from "../../components/tabs/Tabs";

const ExploreDetail = () => {
  const { id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [searchParam, setSearchParam] = useSearchParams();
  let type = searchParam.get("type") || "LIKE";
  const [exploreDetail, setExploreDetail] = useState({});
  const [likeCommentList, setLikeCommentList] = useState([]);

  const fetchExploreDetail = async () => {
    try {
      showLoader();
      let sendData = { exploreId: id, type: type };
      const { data } = await fetchExploreDetailApi(sendData);
      if (data.code === 200) {
        toastMessage(data.message, "success", "exploreDetail");
        setExploreDetail(data?.result);
      } else {
        toastMessage(data.message, "error", "exploreDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // console.log(exploreDetail, "Explore Detail");

  const fetchExploreLikeComment = async () => {
    try {
      showLoader();
      let sendData = { exploreId: id, type: type };
      const { data } = await fetchExploreLikeCommentApi(sendData);
      if (data.code === 200) {
        toastMessage(data.message, "success", "likeCOmmentList");
        setLikeCommentList(data?.result?.list);
      } else {
        toastMessage(data.message, "error", "likeCOmmentList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const typeHandler = (type) => {
    searchParam.set("type", type);
    setSearchParam(searchParam);
  };

  const changeCommentStatus = async (_comment) => {
    try {
      showLoader();
      let sendData = { exploreId: _comment._id, status: !Boolean(_comment.status) };
      await updateCommentVisiblity(sendData);
      fetchExploreLikeComment();
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  }

  useEffect(() => {
    fetchExploreDetail();
  }, []);

  useEffect(() => {
    fetchExploreLikeComment();
  }, [searchParam]);

  return (
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <div className="user_detail_heading">
            <Link className="back_icon" to={-2}>
              <IconButton>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
            </Link>
            <h4 className="Title">Explore Details</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            {exploreDetail?.media?.type === "video" ? (
              <div className="video-container">
                <VideoPreview videoUrl={exploreDetail?.media?.url} />
              </div>
            ) : (
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={exploreDetail?.media?.url}
              />
            )}
          </div>
          <div className="col-6">{exploreDetail?.description || ""}</div>
        </div>
        <div className="row my-4">
          <div className="col-12">
            <h4>HashTag</h4>
            <p>{exploreDetail?.hastag || "---"}</p>
          </div>
        </div>

        <div
          className="col-form-label mx-auto mt-3"
        >
          <CustomTabs
            value={type}
            onChange={(e, section) => {
              typeHandler(section);
            }}
            aria-label="Content"
            variant="fullWidth"
          >
            <CustomTab value="LIKE" label="Like" />
            <CustomTab value="COMMENT" label="Comment" />
          </CustomTabs>
        </div>

        <div className="row">
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Email Id</th>
                  <th>Date</th>
                  {type == 'COMMENT' && <>
                    <th>Comment</th>
                    <th>Action(s)</th>
                  </>}
                </tr>
              </thead>
              <tbody>
                {likeCommentList.length > 0 ? (
                  likeCommentList.map((offers, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{++index}</td>
                          <td className="user_profile_image">
                            <img
                              src={offers?.userDetails?.image || profileImage}
                              alt={`${index + 1}-${offers?.userDetails?.name} profile image`}
                            />
                          </td>
                          <td>{offers?.userDetails?.name || "---"}</td>
                          <td>{offers?.userDetails?.email || "---"}</td>
                          <td>{dateFormat(offers.createdAt) || "---"} </td>


                          {type == 'COMMENT' && <>
                            <td>{offers?.comment || "-- No comment--"}</td>
                            <td>
                              <Switch
                                checked={offers?.status}
                                onChange={() => {
                                  changeCommentStatus(offers);
                                }}
                              />
                            </td>
                          </>}

                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound cols={8} msg="Request not found!" loader={loader} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreDetail;
