import React, { Fragment, useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { toastMessage } from "../../utils/toastMessage";
import {
  addConsultant,
  changeConsultantStatus,
} from "../../services/consultantManagement";
import AddConsultantForm from "./AddConsultantForm";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { allowFloatingNumber, allowNumber, optimizeFunction, regex } from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import { CommonSelect, Input, TextArea } from "../../components/Form";
import PhoneInput from "react-phone-input-2";
import ReactDatePicker from "react-datepicker";
import {
  astrologyPlatforms,
  consultantType,
  dailyHoursOptions,
  experience,
  expertist,
  gender,
  hearAboutOptions,
  languages,
  mainSourceOfIncome,
  qualification,
} from "../../utils/genderList";
import { dateFormat, dateFormatYear } from "../../utils/dateFormat";
import createMediaData from "../../utils/mediaDataSender";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const {
  CONSULTANT_NAME,
  VALID_CONSULTANT_EMAIL,
  CONSULTANT_EMAIL,
  CONSULTANT_NUMBER,
  AVERAGE_LIVE_BRIER,
  CONSULTANT_GENDER,
  CONSULTANT_LANGUAGE,
  CONSULTANT_ENTERPRISE,
  CONSULTANT_TYPE,
  CONSULTANT_SKILL,
  IMAGE_FORMAT,
} = commonMessages;

const { email, imageFileRegex } = regex;
const AddConsultant = () => {
  const navigate = useNavigate();
  // handle all state
  const [languageValue, setLanguageValue] = useState([]);
  const [genderValue, setGenderValue] = useState([]);
  const [qualificationValue, setQualificationValue] = useState("");
  const [expertiseValue, setExpertiseValue] = useState([]);
  const [workOtherPlatformValue, setWorkOtherPlatformValue] = useState("");
  const [platformValue, setPlatformValue] = useState("");
  const [dailyHourValue, setDailyHourValue] = useState("");
  const [sourceOfIncomeValue, setSourceOfIncomeValue] = useState("");
  const [hearAboutUsValue, sethearAboutUsValue] = useState("");
  const [experienceValue, setExperienceValue] = useState("");
  // const [consultantTypeValue, setConsultantTypeValue] = useState("");
  const [consultantSkill, setConsultantSkill] = useState([]);
  const [otherSkill, setOtherSkill] = useState([]);
  const [profileImage, setProfileImage] = useState("");

  const [addConsultantValue, setAddConsultantValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [error, setError] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Add");

  const genderOptions = gender.map(({ value, name }) => ({
    value,
    label: name,
  }));

  const experienceOptions = experience.map(({ value, name }) => ({
    value,
    label: name,
  }));

  const workAnyOtherPlatformOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];

  const platformOptions = astrologyPlatforms.map(({ value, name }) => ({
    value,
    label: name,
  }));
  const hearOptions = hearAboutOptions.map(({ name, value }) => ({
    value,
    label: name,
  }));
  const sourceOfIncomeOptions = mainSourceOfIncome.map(({ value, name }) => ({
    value,
    label: name,
  }));

  const qualificationOptions = qualification.map(({ value, name }) => ({
    value,
    label: name,
  }));
  const dailyHourOptions = dailyHoursOptions.map(({ value, name }) => ({
    value,
    label: name,
  }));
  // const consultantTypeOption = consultantType.map(({ value, name }) => ({
  //   value,
  //   label: name,
  // }));

  const [consultantSkillOption, setConsultantSkillOption] = useState([]);
  const [expertiseOption, setExpertiseOption] = useState([]);
  const [languageOptions, setLanguageOption] = useState([]);
  const [otherSkillOption, setOtherSkillOption] = useState([]);
  const [consultantSkillLoader, setConsultantSkillLoader] = useState(false);
  const [otherSkillLoader, setOtherSkillOptionLoader] = useState(false);

  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!addConsultantValue.name) {
      errorMessage["name"] = CONSULTANT_NAME;
      status = false;
    }
    if (!addConsultantValue.userName) {
      errorMessage["userName"] = "User Name is Mandatory";
      status = false;
    }

    // if (!addConsultantValue.score) {
    //   errorMessage["score"] = "Score is Mandatory";
    //   status = false;
    // }

    if (!addConsultantValue.email) {
      errorMessage["email"] = CONSULTANT_EMAIL;
      status = false;
    }
    if (!addConsultantValue.email?.match(email)) {
      errorMessage["matchEmail"] = VALID_CONSULTANT_EMAIL;
      status = false;
    }

    if (!addConsultantValue.mobile) {
      errorMessage["mobile"] = CONSULTANT_NUMBER;
      status = false;
    }
    if (!profileImage) {
      errorMessage["profileImage"] = "Please add consultant profile";
      status = false;
    }

    if (!addConsultantValue.avgLiveBrier) {
      errorMessage["avgLiveBrier"] = AVERAGE_LIVE_BRIER;
      status = false;
    }
    if (!addConsultantValue.bio) {
      errorMessage["bio"] = "Please add consultant bio";
      status = false;
    }
    if (!addConsultantValue.greaterChallengeAndConquer) {
      errorMessage["greaterChallengeAndConquer"] = "Required";
      status = false;
    }
    if (!addConsultantValue.workingFullTimeJob) {
      errorMessage["workingFullTimeJob"] = "Required";
      status = false;
    }
    if (!addConsultantValue.foreignCountryNo) {
      errorMessage["foreignCountryNo"] = "Required";
      status = false;
    }
    if (!addConsultantValue.learnAstrologyFrom) {
      errorMessage["learnAstrologyFrom"] = "Required";
      status = false;
    }
    if (!addConsultantValue.reasonOfOnboard) {
      errorMessage["reasonOfOnboard"] = "Required";
      status = false;
    }
    if (!addConsultantValue.minimumEarningExpectation) {
      errorMessage["minimumEarningExpectation"] = "Required";
      status = false;
    }
    if (!sourceOfIncomeValue) {
      errorMessage["mainSourceIncome"] =
        "Please add consultant source of income";
      status = false;
    }
    if (!addConsultantValue.pincode) {
      errorMessage["pincode"] = "Please add consultant pincode";
      status = false;
    }
    if (!addConsultantValue.state) {
      errorMessage["state"] = "Please add consultant state";
      status = false;
    }
    if (!addConsultantValue.city) {
      errorMessage["city"] = "Please add consultant city";
      status = false;
    }
    if (!addConsultantValue.address) {
      errorMessage["address"] = "Please add consultant address";
      status = false;
    }
    if (!dateOfBirth) {
      errorMessage["dateOfBirth"] = "Please add DOB";
      status = false;
    }
    if (!dailyHourValue) {
      errorMessage["dailyWorkHour"] = "Please add your daily work hour ";
      status = false;
    }
    if (!addConsultantValue.isRefer) {
      errorMessage["isRefer"] = "Select yes or no";
      status = false;
    }
    if (!addConsultantValue.price) {
      errorMessage["price"] = "Please add price (per min)";
      status = false;
    }
    if (!addConsultantValue.whenHearAboutUs) {
      errorMessage["whenHearAboutUs"] = "Required";
      status = false;
    }
    if (!workOtherPlatformValue) {
      errorMessage["otherOnlinePlatformWork"] = "Required";
      status = false;
    }
    if (workOtherPlatformValue === "Yes" && !platformValue) {
      errorMessage["platformValue"] = "Select consultant Platform";
      status = false;
    }
    if (
      workOtherPlatformValue === "Yes" &&
      platformValue === "Other" &&
      !addConsultantValue.platformName
    ) {
      errorMessage["platformName"] = "Enter consultant platform name";
      status = false;
    }
    if (!addConsultantValue.whenHearAboutUs) {
      errorMessage["whenHearAboutUs"] = "Required";
      status = false;
    }
    if (!hearAboutUsValue) {
      errorMessage["hearAboutUs"] = "Required";
      status = false;
    }

    if (genderValue.length <= 0) {
      errorMessage["gender"] = CONSULTANT_GENDER;
      status = false;
    }

    if (languageValue.length === 0) {
      errorMessage["language"] = CONSULTANT_LANGUAGE;
      status = false;
    }
    if (qualificationValue.length === 0) {
      errorMessage["qualification"] = "Qualification is required";
      status = false;
    }

    if (expertiseValue.length === 0) {
      errorMessage["expertise"] = CONSULTANT_ENTERPRISE;
      status = false;
    }
    // if (!consultantTypeValue) {
    //   errorMessage["type"] = CONSULTANT_TYPE;
    //   status = false;
    // }
    if (!experienceValue) {
      errorMessage["experience"] = "Please select consultant experience";
      status = false;
    }
    if (!workOtherPlatformValue) {
      errorMessage["otherOnlinePlatformWork"] = "Required";
      status = false;
    }
    if (!qualificationValue) {
      errorMessage["qualification"] = "Required";
      status = false;
    }

    if (consultantSkill.length === 0) {
      errorMessage["skill"] = CONSULTANT_SKILL;
      status = false;
    }
    // if (otherSkill.length === 0) {
    //   errorMessage["otherSkill"] = "Please select consultant other skill";
    //   status = false;
    // }

    setError(errorMessage);
    return status;
  };

  // console.log(error,"Error");

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "name") {
      updatedValue = optimizeFunction(value);
    }
    if(name==="score"){
      updatedValue=allowFloatingNumber(value);
    }

    if (
      name === "avgLiveBrier" ||
      name === "price" ||
      name === "foreignCountryNo" ||
      name === "pincode" 
    ) {
      updatedValue = allowNumber(value);
    }
    setAddConsultantValue({ ...addConsultantValue, [name]: updatedValue });
  };

  const handlePhoneChange = (phone, country) => {
    // Extract the phone number without country code
    const phoneWithoutCountryCode = phone.replace(country.dialCode, "");
    // Update states
    setAddConsultantValue({
      ...addConsultantValue,
      mobile: phoneWithoutCountryCode,
      completeMobile: `+${country?.dialCode}` || "+91",
    });

    // setCountryCode("+"+country.dialCode);
    setCountryCode("+" + country?.dialCode);
    setCountryName(country.name);
  };

  //  Profile Image Handler
  const profileImageHandler = (e) => {
    let { name, value, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (file && imageFileRegex.test(file.name)) {
        setProfileImage(file);
      } else {
        e.target.value = "";
        setProfileImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setProfileImage("");
    }
  };

  // fetch consultant skill
  const fetchConsultantSkillHandler = async () => {
    try {
      setConsultantSkillLoader(true);
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        const updatedConsultantSkill =
          data?.result?.skillList &&
          data?.result?.skillList.length > 0 &&
          data?.result?.skillList.map(({ _id, title }) => ({
            value: title,
            label: title,
          }));
        setConsultantSkillOption(updatedConsultantSkill);
        setOtherSkillOption(updatedConsultantSkill);

        const expertise =
          data?.result?.categoryList &&
          data?.result?.categoryList.length > 0 &&
          data.result.categoryList.map(({ _id, title }) => ({
            value: title,
            label: title,
          }));
        setExpertiseOption(expertise);

        const language =
          data?.result?.languageList &&
          data?.result?.languageList.length > 0 &&
          data.result.languageList.map(({ _id, title }) => ({
            value: title,
            label: title,
          }));
        setLanguageOption(language);
      } else {
        setConsultantSkillOption([]);
        setOtherSkillOption([]);
        setExpertiseOption([]);
        setLanguageOption([]);

        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setConsultantSkillLoader(false);
    }
  };

  useEffect(() => {
    fetchConsultantSkillHandler();
  }, []);

  //--------------change-Consultant------------------------
  const addConsultantHandler = async () => {
    let isValid = validation();
    if (isValid) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        if (profileImage && profileImage.name) {
          formData.append("profileImage", profileImage);
        }
        if (languageValue && languageValue.length != 0) {
          for (let idx = 0; idx < languageValue.length; idx++) {
            formData.append("language", languageValue[idx]);
          }
        }

        if (consultantSkill && consultantSkill.length != 0) {
          for (let idx = 0; idx < consultantSkill.length; idx++) {
            formData.append("primarySkills", consultantSkill[idx]);
          }
        }
        if (otherSkill && otherSkill.length != 0) {
          for (let idx = 0; idx < otherSkill.length; idx++) {
            formData.append("otherSkills", otherSkill[idx]);
          }
        }
        if (expertiseValue && expertiseValue.length != 0) {
          for (let idx = 0; idx < expertiseValue.length; idx++) {
            formData.append("expertise", expertiseValue[idx]);
          }
        }

        formData.append(
          "highestQualification",
          addConsultantValue?.qualification || ""
        );

        if (qualificationValue === "Others") {
          formData.append("qualification", qualificationValue || "");
        }
        formData.append("dailyWorkHour", dailyHourValue || "");
        formData.append("hearAboutUs", hearAboutUsValue || "");
        formData.append("otherOnlinePlatformWork", workOtherPlatformValue);
        if (workOtherPlatformValue === "Yes") {
          formData.append("onlinePlatformWork", platformValue);
        }
        if (workOtherPlatformValue === "Yes" && platformValue === "Other") {
          formData.append(
            "onlinePlatformName",
            addConsultantValue.platformName
          );
        }
        formData.append("mainSourceIncome", sourceOfIncomeValue);
        formData.append("experienceYear", experienceValue);
        formData.append("userName",addConsultantValue?.userName || "")

        const sendData = {
          name: addConsultantValue.name || "",
          email: addConsultantValue.email || "",
          price: addConsultantValue.price || "",
          countryCode: countryCode || "+91",
          mobile: addConsultantValue.mobile || "",
          gender: genderValue || "",
          score:addConsultantValue?.score || "",
          // consType: consultantTypeValue || "",
          address: addConsultantValue.address || "",
          avgLiveBrier: addConsultantValue.avgLiveBrier || "",
          dateOfBirth: dateFormatYear(dateOfBirth) || "",
          learnAstrologyFrom: addConsultantValue?.learnAstrologyFrom,
          instaLink: addConsultantValue?.instaLink || "",
          faceBookLink: addConsultantValue?.faceBookLink || "",
          linkedinLink: addConsultantValue?.linkedinLink || "",
          youTubeLink: addConsultantValue.youTubeLink || "",
          foreignCountryNo: addConsultantValue.foreignCountryNo || "",
          workingFullTimeJob: addConsultantValue?.workingFullTimeJob,
          greaterChallengeAndConquer:
            addConsultantValue.greaterChallengeAndConquer,
          bio: addConsultantValue.bio,
          isRefer: addConsultantValue.isRefer === "yes" ? true : false,
          whenHearAboutUs: addConsultantValue.whenHearAboutUs || "",
          minimumEarningExpectation:
            addConsultantValue.minimumEarningExpectation || "",
          pincode: addConsultantValue.pincode || "",
          city: addConsultantValue.city,
          state: addConsultantValue.state,
          reasonOfOnboard: addConsultantValue.reasonOfOnboard,
        };
        createMediaData(formData, sendData);
        const { data } = await addConsultant(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addConsultant");
          setLanguageValue([]);
          setGenderValue("");
          setExpertiseValue([]);
          // setConsultantTypeValue("");
          setConsultantSkill([]);
          setAddConsultantValue("");
          setCountryCode("");
          setCountryName("");
          setAddConsultantValue("");
          navigate(-1);
        } else {
          toastMessage(data.message, "error", "addConsultant");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  // console.log(genderValue,"genderValue");

  useEffect(() => {
    setAddConsultantValue({ ...addConsultantValue, platformName: "" });
  }, [workOtherPlatformValue, platformValue]);

  return (
    <Fragment>
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              <Link className="back_icon" to={-1}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">Add Consultant Management</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div>
                <label className="my-1 text-dark">
                  Upload Profile
                  <span className="text-danger"> *</span>
                </label>
              </div>
              <input
                type="file"
                onChange={profileImageHandler}
                accept="image/*"
                className={`form-control ${error.profileImage && "is-invalid"}`}
              />
              {error.profileImage && (
                <div className="invalid-feedback d-block">
                  {error.profileImage}
                </div>
              )}
            </div>
            <div className="col-4">
              {/* name */}
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="name"
                  value={addConsultantValue?.name || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Consultant Name"
                  className={`form-control `}
                  label={"Name"}
                  error={!addConsultantValue?.name && error.name}
                  maxLength={30}
                />
              </div>
            </div>
            {/* User Name */}
            <div className="col-4">
           
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="userName"
                  value={addConsultantValue?.userName || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Consultant User Name"
                  className={`form-control `}
                  label={"User Name"}
                  error={!addConsultantValue?.userName && error.userName}
                  maxLength={30}
                />
              </div>
            </div>
            {/* Email */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="email"
                  value={addConsultantValue?.email || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter email"
                  className={`form-control `}
                  label={"Email"}
                  // error={!addConsultantValue.email && error.email}
                  error={
                    !addConsultantValue.email
                      ? error.email
                      : !addConsultantValue.email.match(email) &&
                        error.matchEmail
                  }
                />
              </div>
            </div>
            <div className="col-4 cstm_consultant_profile_image">
              <Stack direction="row" className="w" spacing={2}>
                <Avatar
                  alt=""
                  sx={{ width: 150, height: 150 }}
                  src={
                    profileImage && profileImage.name
                      ? URL.createObjectURL(profileImage)
                      : ""
                  }
                />
              </Stack>
            </div>

            {/* phone Input */}
            <div className="col-4">
              <div className="form-group cstM-tele_input mb-2 text-wrapper">
                <label className="my-1 text-dark">Mobile Number</label>
                <PhoneInput
                  className={`${!addConsultantValue.mobile && "is-invalid"}`}
                  country={"in"}
                  value={addConsultant.completeMobile}
                  enableSearch={true}
                  placeholder={"Enter Mobile Number"}
                  countryCodeEditable={false}
                  onChange={handlePhoneChange}
                />
                {!addConsultantValue.mobile && (
                  <span className="invalid-feedback">{error.mobile}</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Gender"}
                  options={genderOptions}
                  onChange={setGenderValue}
                  required
                  error={error.gender}
                />
              </div>
            </div>
            {/* date picker */}
            <div className="col-4">
              <div className="form-group mb-2">
                <label className="my-1 text-dark w-100">DOB</label>
                <ReactDatePicker
                  selected={dateOfBirth}
                  onChange={(date) => setDateOfBirth(date)}
                  className={`form-control multi_search_date`}
                  isClearable
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="dd-MMM-yyyy"
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  maxDate={new Date()}
                />
                {error.dateOfBirth && (
                  <span className="invalid-feedback d-block">
                    {error.dateOfBirth}
                  </span>
                )}
              </div>
            </div>

            {/* Language */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Language"}
                  options={languageOptions}
                  onChange={setLanguageValue}
                  isMulti={true}
                  isSearchable={true}
                  required
                  error={languageValue.length === 0 && error.language}
                />
              </div>
            </div>
            {/* Primary skills */}
            <div className="col-4">
              <div
                className={`form-group select_sm_width ${
                  consultantSkill.length === 1 && "hide_multiSelect_arrow"
                }`}
              >
                <CommonSelect
                  className="add-consultant"
                  disabled
                  label={"Primary skill"}
                  options={consultantSkillOption}
                  onChange={setConsultantSkill}
                  loading={consultantSkillLoader}
                  isMulti={true}
                  isSearchable={true}
                  maxOptions={3}
                  menuDisable={consultantSkill.length === 1}
                  required
                  error={consultantSkill.length === 0 && error.skill}
                />
              </div>
            </div>
            {/* Other skills */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Others skill"}
                  options={otherSkillOption}
                  onChange={setOtherSkill}
                  isMulti={true}
                  isSearchable={true}
                  loading={otherSkillLoader}
                  required
                  // error={otherSkill.length === 0 && error.otherSkill}
                />
              </div>
            </div>
            {/* Consultant Type */}
            {/* <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Consultant Type"}
                  options={consultantTypeOption}
                  onChange={setConsultantTypeValue}
                  required
                  error={!consultantTypeValue && error.type}
                />
              </div>
            </div> */}
            {/* Expertise */}
            <div className="col-4">
              <div
                className={`form-group select_sm_width ${
                  expertiseValue.length === 3 && "hide_multiSelect_arrow"
                }`}
              >
                <CommonSelect
                  className="add-consultant"
                  id="expertise"
                  label={"Expertise"}
                  options={expertiseOption}
                  onChange={setExpertiseValue}
                  isMulti={true}
                  isSearchable={true}
                  required
                  maxOptions={3}
                  menuDisable={expertiseValue.length === 3}
                  error={expertiseValue.length === 0 && error.expertise}
                />
              </div>
            </div>

            {/* Experiences in Years */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Experiences in Years"}
                  options={experienceOptions}
                  onChange={setExperienceValue}
                  required
                  error={!experienceValue && error.experience}
                />
              </div>
            </div>

            {/* Consultant Score */}
            <div className="col-4">
            <div className="form-group mb-2">
                <Input
                  type="text"
                  name="score"
                  value={addConsultantValue?.score || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Consultant Score"
                  className={`form-control `}
                  label={"Consultant Score"}
                  // error={!addConsultantValue?.score && error.score}
                  maxLength="10"
                />
              </div>
              </div>

            {/* How many hours you work daily? */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"How many hours you work daily?"}
                  options={dailyHourOptions}
                  onChange={setDailyHourValue}
                  required
                  error={dailyHourValue.length === 0 && error.dailyWorkHour}
                />
              </div>
            </div>

            {/*  Price Per Min  */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <Input
                  type="text"
                  name="price"
                  value={addConsultantValue?.price || ""}
                  required
                  onChange={handleChange}
                  placeholder="Price"
                  className={`form-control `}
                  label={"Price (Per Min)"}
                  error={!addConsultantValue.price && error.price}
                  maxLength={3}
                />
              </div>
            </div>
            {/* Where did you hear about Vedic Meet? (Give Option) */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Where did you hear about Vedic Meet?"}
                  options={hearOptions}
                  onChange={sethearAboutUsValue}
                  required
                  error={!hearAboutUsValue && error.hearAboutUs}
                />
              </div>
            </div>

            {/* When did you hear about vedic meet? */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <Input
                  type="text"
                  name="whenHearAboutUs"
                  value={addConsultantValue?.whenHearAboutUs || ""}
                  required
                  onChange={handleChange}
                  placeholder="When you hear about us.."
                  className={`form-control `}
                  label={"When did you hear about vedic meet?"}
                  error={
                    !addConsultantValue.whenHearAboutUs && error.whenHearAboutUs
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Are you work on any other online astrologer platform? */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={
                    "Are you work on any other online astrologer platform?"
                  }
                  options={workAnyOtherPlatformOptions}
                  onChange={setWorkOtherPlatformValue}
                  required
                  error={error.otherOnlinePlatformWork}
                />
              </div>
            </div>

            {workOtherPlatformValue == "Yes" && (
              <div className="col-4">
                <div className="form-group select_sm_width">
                  <CommonSelect
                    className="add-consultant"
                    label={"Please select platform"}
                    options={platformOptions}
                    onChange={setPlatformValue}
                    required
                    error={error.platformValue}
                  />
                </div>
              </div>
            )}

            {workOtherPlatformValue == "Yes" && platformValue == "Other" && (
              <div className="col-4">
                <div className="form-group select_sm_width">
                  <Input
                    type="text"
                    name="platformName"
                    value={addConsultantValue?.platformName || ""}
                    required
                    onChange={(e) => {
                      setAddConsultantValue({
                        ...addConsultantValue,
                        platformName: e.target.value,
                      });
                    }}
                    placeholder="Enter platform name"
                    className={`form-control `}
                    label={"Platform name"}
                    error={
                      !addConsultantValue.platformName && error.platformName
                    }
                  />
                </div>
              </div>
            )}

            {/* Avg Live Brier */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="avgLiveBrier"
                  value={addConsultantValue?.avgLiveBrier || ""}
                  required
                  onChange={handleChange}
                  placeholder="Average Live Barrier (in Hours)"
                  className={`form-control `}
                  label={"Avg. Live Barrier (in Mins)"}
                  error={!addConsultantValue.avgLiveBrier && error.avgLiveBrier}
                  // maxLength={30}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="address"
                  value={addConsultantValue?.address || ""}
                  required
                  onChange={handleChange}
                  placeholder="Address"
                  className={`form-control `}
                  label={"Address"}
                  error={!addConsultantValue.address && error.address}
                  // maxLength={30}
                />
              </div>
            </div>
            {/* City */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="city"
                  value={addConsultantValue?.city || ""}
                  required
                  onChange={handleChange}
                  placeholder="City"
                  className={`form-control `}
                  label={"City"}
                  error={!addConsultantValue.city && error.city}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* State */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="state"
                  value={addConsultantValue?.state || ""}
                  required
                  onChange={handleChange}
                  placeholder="State"
                  className={`form-control `}
                  label={"State"}
                  error={!addConsultantValue.state && error.state}
                  // maxLength={30}
                />
              </div>
            </div>
            {/* Pin Code */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="pincode"
                  value={addConsultantValue?.pincode || ""}
                  required
                  onChange={handleChange}
                  placeholder="Pincode"
                  className={`form-control `}
                  label={"Pincode"}
                  error={!addConsultantValue.pincode && error.pincode}
                  maxLength={6}
                />
              </div>
            </div>

            {/* Main source of income */}
            <div className="col-4">
              <div className="form-group mb-2">
                <CommonSelect
                  className="add-consultant"
                  label={
                    "What is your main source of income(Other than astrology)?"
                  }
                  options={sourceOfIncomeOptions}
                  onChange={setSourceOfIncomeValue}
                  required
                  error={error.mainSourceIncome}
                />
              </div>
            </div>

            {/* Highest Qualification */}
            <div className="col-4">
              <div className="form-group select_sm_width">
                <CommonSelect
                  className="add-consultant"
                  label={"Please select your highest qualification"}
                  options={qualificationOptions}
                  onChange={setQualificationValue}
                  isMulti={false}
                  isSearchable={false}
                  required
                  error={!qualificationValue && error.qualification}
                />
              </div>
            </div>
            {qualificationValue == "Others" && (
              <div className="col-4">
                <div className="form-group mb-2">
                  <Input
                    type="text"
                    name="qualification"
                    value={addConsultantValue?.qualification || ""}
                    required
                    onChange={handleChange}
                    placeholder="Enter Qualification"
                    className={`form-control `}
                    label={"Qualification"}
                    error={
                      !addConsultantValue.qualification &&
                      error.minimumEarningExpectation
                    }
                  />
                </div>
              </div>
            )}

            {/* Minimum earning expecatations form vedic */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="minimumEarningExpectation"
                  value={addConsultantValue?.minimumEarningExpectation || ""}
                  required
                  onChange={handleChange}
                  placeholder="Earning Expectations"
                  className={`form-control `}
                  label={"Minimum earning expecatations from vedic?"}
                  error={
                    !addConsultantValue.minimumEarningExpectation &&
                    error.minimumEarningExpectation
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Instagram Profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="instaLink"
                  value={addConsultantValue?.instaLink || ""}
                  onChange={handleChange}
                  placeholder="Insta profile link"
                  className={`form-control `}
                  label={"Instagram Profile Link"}
                  error={!addConsultantValue.instaLink && error.instaLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* FB profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="faceBookLink"
                  value={addConsultantValue?.faceBookLink || ""}
                  onChange={handleChange}
                  placeholder="Facebook profile link"
                  className={`form-control `}
                  label={"Facebook Profile Link"}
                  error={!addConsultantValue.faceBookLink && error.faceBookLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Linked In Profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="linkedinLink"
                  value={addConsultantValue?.linkedinLink || ""}
                  onChange={handleChange}
                  placeholder="LinkedIn profile link"
                  className={`form-control `}
                  label={"LinkedIn Profile Link"}
                  error={!addConsultantValue.linkedinLink && error.linkedinLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Youtube Profile Link */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="youTubeLink"
                  value={addConsultantValue?.youTubeLink || ""}
                  onChange={handleChange}
                  placeholder="youtube profile link"
                  className={`form-control `}
                  label={"Youtube Profile Link"}
                  error={!addConsultantValue.youTubeLink && error.youTubeLink}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Reason For OnBoard */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="reasonOfOnboard"
                  value={addConsultantValue?.reasonOfOnboard || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter Reason..."
                  className={`form-control `}
                  label={"Why do you belive we should onborad you?"}
                  error={
                    !addConsultantValue.reasonOfOnboard && error.reasonOfOnboard
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* WHere do u learn from  */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="learnAstrologyFrom"
                  value={addConsultantValue?.learnAstrologyFrom || ""}
                  required
                  onChange={handleChange}
                  placeholder="Learn Astrology From..."
                  className={`form-control `}
                  label={"Where did you learn astrology from?"}
                  error={
                    !addConsultantValue.learnAstrologyFrom &&
                    error.learnAstrologyFrom
                  }
                  // maxLength={30}
                />
              </div>
            </div>
            {/* Has anyone refered you to vedic meet */}
            <div className="col-4">
              <div className="form-group mb-2">
                <div>
                  <label className="my-1">
                    Has anyone refered you to vedic meet
                  </label>
                  <span className="text-danger"> *</span>
                </div>
                <div className="checkbox_Wrap">
                  <label>
                    <span>Yes </span>{" "}
                    <input
                      type="radio"
                      name="isRefer"
                      value="yes"
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    <span>No</span>
                    <input
                      type="radio"
                      name="isRefer"
                      value="no"
                      onChange={handleChange}
                    />
                  </label>
                </div>
                {error.isRefer && (
                  <div className="invalid-feedback d-block">
                    {error.isRefer}
                  </div>
                )}
              </div>
            </div>

            {/* Number of countries you lived or travelled to? */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="foreignCountryNo"
                  value={addConsultantValue?.foreignCountryNo || ""}
                  required
                  onChange={handleChange}
                  placeholder="Enter No. of countries you travelled or lived.."
                  className={`form-control `}
                  label={"Number of countries you lived or travelled to?"}
                  error={
                    !addConsultantValue.foreignCountryNo &&
                    error.foreignCountryNo
                  }
                  maxLength={3}
                />
              </div>
            </div>

            {/* Are you currently working full time job? */}
            <div className="col-4">
              <div className="form-group mb-2">
                <Input
                  type="text"
                  name="workingFullTimeJob"
                  value={addConsultantValue?.workingFullTimeJob || ""}
                  required
                  onChange={handleChange}
                  placeholder="Are you currently working full time job?"
                  className={`form-control `}
                  label={"Are you currently working full time job?"}
                  error={
                    !addConsultantValue.workingFullTimeJob &&
                    error.workingFullTimeJob
                  }
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Bio */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="bio"
                  value={addConsultantValue?.bio || ""}
                  required
                  onChange={handleChange}
                  placeholder="Bio.."
                  className={`form-control `}
                  label={"Bio"}
                  error={!addConsultantValue.bio && error.bio}
                  // maxLength={30}
                />
              </div>
            </div>

            {/* Grater Challenge & Conqueor */}
            <div className="col-4">
              <div className="form-group mb-2">
                <TextArea
                  type="text"
                  name="greaterChallengeAndConquer"
                  value={addConsultantValue?.greaterChallengeAndConquer || ""}
                  required
                  onChange={handleChange}
                  placeholder="What has been your greatest challenge and how did you conquer it..."
                  className={`form-control `}
                  label={
                    "What has been your greatest challenge and how did you conquer it?"
                  }
                  error={
                    !addConsultantValue.greaterChallengeAndConquer &&
                    error.greaterChallengeAndConquer
                  }
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn_size text-center"
              onClick={addConsultantHandler}
              ref={buttonref}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddConsultant;
