import React, { useState } from "react";
import ModalBox from "../../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../../components/Form";
import { optimizeFunction } from "../../../../utils/regex";
import useButtonLoader from "../../../../hooks/UseButtonLoader";
import { toastMessage } from "../../../../utils/toastMessage";
import { addContentApi } from "../../../../services/cms";
import { Avatar } from "@mui/material";
import { checkImageDimensions } from "../../../../utils/checkImageDimension";
import commonMessages from "../../../../utils/commonMessages";
import { regex } from "../../../../utils/regex";

const AddSkills = ({
  setAddModal,
  addModal,
  getCmsDetails,
  userType,
  type,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [skillInput, setSkillInput] = useState("");
  const [skillImage, setSkillImage] = useState("");
  const [error, setError] = useState({});
  const { IMAGE_FORMAT } = commonMessages;
  const { imageFileRegex } = regex;
  const {MESSAGE_SIZE}=commonMessages

  //   console.log(userType,type);

  const modalClose = () => {
    setError({});
    setAddModal(false);
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!skillInput) {
      errors["title"] = "Skill is mandatory";
      status = false;
    }
    if (!skillImage) {
      errors["skillImage"] = "Image is Mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // const validSize = await checkImageDimensions(e.target.files[0]);
        // if (validSize) {
          setSkillImage(e.target.files[0]);
        // } else {
        //   e.target.value = "";
        //   setSkillImage("");
        // }
      } else {
        e.target.value = "";
        setSkillImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setSkillImage("");
    }
  };

  const addSkillHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", skillInput);
        formData.append("contentType", type);
        formData.append("userType", userType);
        if (skillImage && skillImage?.name) {
          formData.append("skillImage", skillImage);
        }
        const { data } = await addContentApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addSkill");
          setSkillImage("");
          setSkillInput("");
          setError({});
          getCmsDetails();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addSkill");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };
  return (
    <ModalBox
      status={addModal}
      onClose={modalClose}
      title={"Add Skill"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
             {/* <p>{MESSAGE_SIZE}</p> */}
        <button className="btn_size" ref={buttonref} onClick={addSkillHandler}>
          Save
        </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={skillInput || ""}
            required
            onChange={(e) => {
              const sanitizedText = optimizeFunction(e.target.value);
              setSkillInput(sanitizedText);
            }}
            placeholder="Skill"
            className={`form-control`}
            label={"Skill"}
            error={!skillInput && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="skillImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Skill Image"
            className={`form-control `}
            label={"Skill Image"}
            error={!skillImage && error.skillImage}
          />
        </div>

        <div>
          {skillImage && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={skillImage && URL.createObjectURL(skillImage)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddSkills;
