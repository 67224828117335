
import { useState, useCallback, useRef, useEffect } from 'react';

export function useCreatePortal() {
    const [tempState, setTempState] = useState(null)
    const [isOpen, setIsOpen] = useState(false);

    const openPortal = useCallback((_data) => {
        if (_data) { setTempState(_data) }
        setIsOpen(true);
    }, []);
    const closePortal = useCallback((cb) => {
        setIsOpen(false);
        if (cb) {
            cb(tempState);
            setTempState(null);
        }
    }, []);

    const ModalWrapper = ({ children, style = {}, isClosable }) => {
        if (!isOpen) return null;

        return <div
            onClick={() => { isClosable && setIsOpen(false) }}
            style={{
                ...style,
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999,
                backgroundColor: '#00000078',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div style={{ zIndex: 1000 }}>
                {children}
            </div>
        </div>
    };

    return [tempState, openPortal, closePortal, ModalWrapper];
}
