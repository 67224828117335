import React, { useEffect, useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { regex, optimizeFunction, allowAlphaNumericWithSpace } from '../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../utils/toastMessage'
import { AddCategoryApi, UpdateCategoryApi } from '../../services/categoryManagement'
import commonMessages from '../../utils/commonMessages'
import { checkImageDimensions } from '../../utils/checkImageDimension'
const { imageFileRegex } = regex
const { CATEGORY_TITLE, CATEGORY_IMAGE, IMAGE_FORMAT, MESSAGE_SIZE } =
  commonMessages

const AddCategory = ({ show, close, listing, updatingExpertise }) => {
  // handle all state
  const [categoryTitle, setCategoryTitle] = useState('')
  const [image, setImage] = useState('')
  const [error, setError] = useState({})

  const [alreadyImg, setAlreadyImg] = useState(null);

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Save')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let status = true;
    let errorMessage = {};

    if (updatingExpertise) {
      if (updatingExpertise?.title == categoryTitle && !image) {
        errorMessage['needAtLeastOneChange'] = 'Change at least title or image!'
        status = false
      }
      setError(errorMessage)
      return status;
    } else {
      if (!categoryTitle) {
        errorMessage['category'] = CATEGORY_TITLE
        status = false
      }
      if (!image.name) {
        errorMessage['image'] = CATEGORY_IMAGE
        status = false
      }
      setError(errorMessage)
      return status
    }
  }

  // Add Category image handler function

  const imageHandler = async (e) => {
    e.preventDefault()
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        // setImage(e.target.files[0])
        const validSize = await checkImageDimensions(e.target.files[0])
        if (validSize) {
          setImage(e.target.files[0])
        } else {
          e.target.value = ''
          setImage('')
        }
      } else {
        e.target.value = ''
        setImage('')
        toastMessage(IMAGE_FORMAT, 'error', 'imageFormat')
      }
    } else {
      e.target.value = ''
      setImage('')
    }
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage('')
    setCategoryTitle('')
    // setInput({ name: '', categoryId: '' })
    setError({})
    close()
  }


  //--------------Add-sub-category------------------------
  const addCategoryHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData = new FormData();
        formData.append('title', categoryTitle)

        if (image) {
          formData.append('categoryImage', image)
        }
        let data;
        if (updatingExpertise) {
          formData.append('toUpdateId', updatingExpertise._id);
          const response = await UpdateCategoryApi(formData)
          data = response.data;
        } else {
          const response = await AddCategoryApi(formData)
          data = response.data;
        }
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'addCategory')
          listing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'addCategory')
        }
      } catch (error) {
      } finally {
        buttonLoader(false)
      }
    }
  }

  useEffect(() => {
    if (updatingExpertise) {
      console.log('updatingExpertise ---> ', updatingExpertise)
      setCategoryTitle(updatingExpertise?.title);
      setAlreadyImg(updatingExpertise?.categoryImage)
    }
  }, [updatingExpertise])
  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={<>
        {updatingExpertise ? 'Update Expertise' : 'Add Expertise'}
        {error?.needAtLeastOneChange && <span style={{ fontSize: 12, color: 'red', marginLeft: 20 }}>Need At Least One Change!</span>}
      </>}
      size={'sm'}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addCategoryHandler}
          >
            {updatingExpertise ? 'Update' : 'Save'}
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={categoryTitle || ''}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value)
              setCategoryTitle(e.target.value);
            }}
            placeholder="Expertise Title"
            className={`form-control`}
            label={updatingExpertise ? 'Update Expertise' : 'Add Expertise'}
            error={!categoryTitle && error.category}
            maxLength={30}
          />
        </div>
        {updatingExpertise && alreadyImg &&
          <div className="form-group my-2 mt-2">
            <h6>Already Previous Image</h6>
            <img src={alreadyImg} style={{ width: '100%', height: 300, objectFit: 'contain' }} />
          </div>}
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Expertise Image"
            className={`form-control `}
            label={updatingExpertise && alreadyImg ? 'Update New Expertise Image' : 'Expertise Image'}
            error={!image && error.image}
          // maxLength={30}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  height: 140,
                  border: '1px solid #FF8989',
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  )
}

export default AddCategory
