import React, { useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { allowAlphaNumeric, allowAlphaNumericWithSpace, optimizeFunction } from '../../utils/regex'
import { toastMessage } from '../../utils/toastMessage'
import commonMessages from '../../utils/commonMessages'
import { updateTrainingCategoryApi } from '../../services/trainingManagement'
const { TRAINING_CATEGORY_TITLE } = commonMessages
const EditTrainingCategory = ({
  show,
  close,
  trainingCategoryDetail,
  setTrainingCategoryDetail,
  trainingListing,
}) => {
  // handle all state

  const [error, setError] = useState({})
  const [updateTrainingCategory, setUpdateTrainingCategory] = useState({
    id: trainingCategoryDetail._id,
    title: trainingCategoryDetail.title,
  })

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!updateTrainingCategory.title) {
      errorMessage['trainingTitle'] = TRAINING_CATEGORY_TITLE
      status = false
    }

    setError(errorMessage)
    return status
  }

  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target
    let updatedValue = value
    if (name === 'title') {
      updatedValue = allowAlphaNumericWithSpace(value)
    }
    setUpdateTrainingCategory({
      ...updateTrainingCategory,
      [name]: updatedValue,
    })
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setTrainingCategoryDetail({})
    setUpdateTrainingCategory({})
    setError({})
    close()
  }
  //--------------Update-music-category------------------------
  const updateTrainingCategoryHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const sendData = {
          growthTrainingCategoryId: updateTrainingCategory.id,
          title: updateTrainingCategory.title,
        }
        const { data } = await updateTrainingCategoryApi(sendData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'updateTrainingCategory')
          trainingListing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'updateTrainingCategory')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Edit Training Category'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonref}
          onClick={updateTrainingCategoryHandler}
        >
          Update
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateTrainingCategory?.title || ''}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Category Title"
            className={`form-control `}
            label={'Add Category'}
            error={!updateTrainingCategory.title && error.trainingTitle}
            // maxLength={30}
          />
        </div>
      </div>
    </ModalBox>
  )
}

export default EditTrainingCategory
