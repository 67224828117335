import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../utils/toastMessage";

// this code is created an instance with base url for admin
export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const setJwtToken = () => {
  const token = localStorage.getItem("vedic_token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

export const setMultiPartHeader = () => {
  const token = localStorage.getItem("vedic_token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };
};

// this interceptors checks user's token is valid or not
export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();

  const removeToken = () => {
    localStorage.removeItem("vedic_token");
  };

  useEffect(() => {
    const resInterceptor = (response) => {
      if (response?.data?.code === 401) {
        removeToken();
        navigate("/");
      }
      return response;
    };

    const errInterceptor = (error) => {
      // console.log(error,"Error");
      if (error?.message === "Network Error") {
        toastMessage(error?.message, "error", "networkError");
        return error
      }
      if (error?.response?.data?.code === 404) {
        removeToken();
        navigate("/");
        return error;
      }
      if (error.response && error.response?.data?.code === 400) {
        toastMessage(error.response?.data?.message, "error", "errorMeesage");
      }
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);

    return () => instance.interceptors.response.eject(interceptor);
    // eslint-disable-next-line
  }, []);

  return isSet && children;
};
