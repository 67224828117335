import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../components/Form";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import { allowAlphaNumericWithSpace } from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import { updateRemedyApi } from "../../../services/concern/Index";

const EditRemedy = ({
  show,
  close,
  remedyDetail,
  setRemedyDetail,
  remedyListing,
}) => {
  // handle all state

  // console.log(remedyDetail,"RemedyDetail");

  const [error, setError] = useState({});
  const [updateRemedy, setUpdateRemedy] = useState({
    areaOfConcernRemedyId: remedyDetail.id,
    title: remedyDetail.title,
    description: remedyDetail.description,
    areaOfConcernId: remedyDetail.areaOfConcernId,
  });

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Update");

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!updateRemedy.title) {
      errorMessage["title"] = "Title is mandatory";
      status = false;
    }
    if (!updateRemedy.description) {
      errorMessage["description"] = "Description is mandatory";
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  // change handler
  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    // if (name === "title") {
    //   updatedValue = allowAlphaNumericWithSpace(value);
    // }
    setUpdateRemedy({ ...updateRemedy, [name]: updatedValue });
  };

  // Add Category image handler function

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setRemedyDetail(null);
    setUpdateRemedy(null);
    setError({});
    close();
  };

  //--------------Add-sub-category------------------------
  const updateRemedyHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const { data } = await updateRemedyApi(updateRemedy);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addMusic");
          remedyListing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addMusic");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Edit Remedy"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateRemedyHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateRemedy.title || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Remedy Title"
            className={`form-control`}
            label={"Remedy Title"}
            error={!updateRemedy.title && error.title}
          />
        </div>
        <div className="form-group my-2">
          <TextArea
            type="text"
            name="description"
            value={updateRemedy.description || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Remedy Description"
            className={`form-control`}
            label={"Remedy description"}
            error={!updateRemedy.description && error.description}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default EditRemedy;
