import { IconButton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CommonSelect, Input, TextArea } from "../../components/Form";
import ModalBox from "../../components/modal/ModalBox";
import { toastMessage } from "../../utils/toastMessage";
import { sendChatApi } from "../../services/supportManagement";
import { sendNotificationApi } from "../../services/notificationManagement";
import useButtonLoader from "../../hooks/UseButtonLoader";

const AddNotification = ({ show, close, listing }) => {
  const [inputField, setInputField] = useState({});
  const [error, setError] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Send");
  const userTypeOptions = [
    { label: "All", value: "all" },
    { label: "User", value: "user" },
    { label: "Consultant", value: "cons" },
  ];
  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
  ];
  const [userTypeValue, setUserTypeValue] = useState("");
  const [gender, setGender] = useState("");

  // Onchange Handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
  };

  // Input field Validation
  const validation = () => {
    let status = true;
    const errors = {};
    // if (!inputField?.title) {
    //   errors["title"] = "Title is mandatory";
    //   status = false;
    // }
    if (!inputField?.message) {
      errors["message"] = "Message is mandatory";
      status = false;
    }
    if (!inputField?.activeUserType) {
      errors["activeUserType"] = "Choose  user type";
      status = false;
    }
    if (!userTypeValue) {
      errors["userType"] = "Select user type";
      status = false;
    }
    setError(errors);
    return status;
  };

  // Add Notification Handler
  const addNotificationHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const reqData = { ...inputField, userType: userTypeValue }
        if (gender) {
          reqData.gender = gender
        }
        // if(!reqData.age){
        //   delete reqData.age
        // }
        const { data } = await sendNotificationApi(reqData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addNotification");
          listing();
          close();
        } else {
          toastMessage(data.message, "error", "addNotification");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  // console.log(error, "Error message");

  return (
    <ModalBox
      status={show}
      onClose={close}
      title={"Add Notification"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-2">
              <div>
                <label className="my-1">Select user type</label>
                <span className="text-danger"> *</span>
              </div>
              <div className="checkbox_Wrap">
                <label>
                  <span>All</span>{" "}
                  <input
                    type="radio"
                    name="activeUserType"
                    value="all"
                    checked={inputField?.activeUserType === "all"}
                    onChange={onChangeHandler}
                  />
                </label>
                <label>
                  <span>Active</span>
                  <input
                    type="radio"
                    name="activeUserType"
                    value="active"
                    checked={inputField?.activeUserType === "active"}
                    onChange={onChangeHandler}
                  />
                </label>
                <label>
                  <span>In-active</span>
                  <input
                    type="radio"
                    name="activeUserType"
                    value="inactive"
                    checked={inputField?.activeUserType === "inactive"}
                    onChange={onChangeHandler}
                  />
                </label>
              </div>
              {error.activeUserType && (
                <div className="invalid-feedback d-block f-2">
                  {error.activeUserType}
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="title"
                value={inputField?.title || ""}
                // required
                onChange={onChangeHandler}
                placeholder="Title"
                className={`form-control `}
                label={"Title"}
                // error={!inputField?.title && error.title}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group select_sm_width">
              <CommonSelect
                className="add-consultant"
                label={"Gender"}
                options={genderOptions}
                onChange={setGender}
              />
            </div>
          </div>
          {/* <div className="col-12">
            <div className="form-group mb-2">
              <Input
                type="text"
                name="age"
                value={inputField?.age || ""}
                // required
                onChange={onChangeHandler}
                placeholder="Age"
                className={`form-control `}
                label={"Age"}
              // error={!addConsultantValue?.name && error.name}
              maxLength={3}
              />
            </div>
          </div> */}
          <div className="col-12">
            <div className="form-group select_sm_width">
              <CommonSelect
                className="add-consultant"
                label={"User Type"}
                options={userTypeOptions}
                onChange={setUserTypeValue}
                required
                error={!userTypeValue && error.userType}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group mb-2">
              <TextArea
                type="text"
                name="message"
                value={inputField?.message || ""}
                required
                onChange={onChangeHandler}
                placeholder="message"
                className={`form-control `}
                label={"Message"}
                error={!inputField?.message && error.message}
              // maxLength={30}
              />
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn_size text-center"
              onClick={addNotificationHandler}
              ref={buttonref}
            >
              Send
            </button>
          </div>
        </div>
      }
    />
  );
};

export default AddNotification;
