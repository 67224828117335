import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fetch-community-listing--------------------------
export const fetchCommunityApi = async (data) => {
  const endPoint =
    `/admin/community` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

//   //------------------ Community Detail API-------------------------------
export const fetchCommunityDetailApi = async (data) => {
  const endPoint =
    `/admin/community/details` +
    "?" +
    new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// Community Members Listing
export const fetchCommunityMembersListing = async (data) => {
  const endPoint =
    `/admin/community/member/list` +
    "?" +
    new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// Community Chat List
export const fetchCommunityChatList = async (data) => {
  const endPoint =
    `/admin/community/member/chat/list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Change Memebers Status
export const changeMemberStatusApi = async (data) => {
  const endPoint = `/admin/community/change/user/status`;
  return await axios.patch(endPoint, data, setJwtToken());
};
