import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fatch-Live-listing--------------------------
export const fetchLiveApi = async (data) => {
  const endPoint =
    `/admin/event` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// ---------------------send Message ---------------------------
export const sendMessageApi = async (data) => {
  const endPoint = `/admin/event/send_message`;
  return await axios.post(endPoint, data, setJwtToken());
};
