import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import Switch from "../../components/Switch/Switch";
import { SecondaryButton } from "../../components/Button/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker, Select } from "../../components/Form";
import { toast } from "sonner";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../Assests/image/profile_not_found.png";
import { user } from "../../routes/route";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";

import { toastMessage } from "../../utils/toastMessage";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { charLimit } from "../../utils/charLimit";
import common from "../../routes/common";
// import UserStatus from './UserStatus'
import { dateFormat, dateFormatUtc, timeFormat } from "../../utils/dateFormat";
import { gender } from "../../utils/genderList";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import useFileDownloader from "../../hooks/useFileDownloader";
import csv from "../../Assests/image/csv.svg";
import {
  block_unblock_Api,
  fetchFeedbackListApi,
  fetchfeedbackListApi,
  //   getuserTypeDetail,
  requestAcceptRejectApi,
} from "../../services/feedbackManagement";
import { Avatar } from "@mui/material";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import DescriptionModel from "../../components/imagePreview/DescriptionModel";
// import RequestDetail from "./RequestDetail";

const { USER_DETAIL } = user;
const { NOT_FOUND } = common;

// console.log('Gender', gender)

const FeedbackManagement = () => {
  let URL = process.env.REACT_APP_API_URL;
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [search, setSearch] = useState(
    getLocalStorage("feedbackList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [feedbackList, setFeedbackList] = useState([]);
  const [selectedRequestDetail, setSelectedRequestDetail] = useState({});
  const [feedbackId, setFeedbackId] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [reviewData, setReviewData] = useState("");
  const [reviewModal, setReviewModal] = useState(false);
  const [newData, setNewData] = useState({});
  const [currentData, setCurrentData] = useState({});

  const userType = searchParam.get("userType") || "";

  useEffect(() => {
    setLocalStorage("feedbackList", "search", search);
  }, [search]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const userTypeHandler = (value) => {
    if (userType === value) {
      searchParam.delete("userType");
    } else {
      searchParam.set("userType", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  //------------------request-listing-Api----------------------------------
  const getFeedbackList = async () => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        userType:userType
      };
      const { data } = await fetchFeedbackListApi(sendData);
      if (data.code === 200) {
        setFeedbackList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setFeedbackList([]);
        setTotal(0);
        toastMessage(data.message, "error", "feedbackList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Change Feedback Status Handler
  const changeFeedbackStatusHandler = async () => {
    try {
      buttonLoader(true);
      const res = await block_unblock_Api({
        feedbackId: feedbackId?.feedbackId,
        status: feedbackId.status,
      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "faqStatus");
        setStatusModal(false);
        setFeedbackId({});
        getFeedbackList();
      } else {
        toastMessage(res?.data?.message, "error", "faqStatus");
        setFeedbackId({});
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!userType) {
      searchParam.delete("userType");
    }
    setSearchParam(searchParam);
    getFeedbackList(debounceValue);
  }, [searchParam, debounceValue]);

    // console.log(reviewData, "reviewData");

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Feedback Management</h4>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              {/* <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span> */}
            </div>

            <div className="group_filter_value">
              {/* Gender-Filter */}
              <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${userType && "active"}`}
                  value={userType || ""}
                  onChange={(e) => userTypeHandler(e.target.value)}
                >
                  <option value="">Filter By</option>
                  <option value="user">User</option>
                  <option value="cons">Consultant</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>User Image</th>
                  <th>Date & Time</th>
                  <th>Address</th>
                  <th>Reviews</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {feedbackList.length > 0 ? (
                  feedbackList.map((request, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{request.userDetails?.name || "---"}</td>
                          <td className="user_profile_image">
                            <img
                              src={
                                request?.userDetails?.profileImage ||
                                profileImage
                              }
                              alt={`${request.consName} profile image`}
                            />
                          </td>
                          <td>
                            {dateFormat(request.createdAt) || "---"},{" "}
                            {timeFormat(request?.createdAt)}
                          </td>
                          <td className="text-capitalize">
                            {request?.userDetails?.address || "---"}
                          </td>

                          <td
                            onClick={() => {
                              if (
                                request.review &&
                                request.review.length > 50
                              ) {
                                setReviewModal(true);
                                setReviewData(request.review);
                              }
                            }}
                          >
                            {request?.review &&
                              request?.review?.length > 50
                                ? charLimit(request.review, 50)
                                : request.review }
                          </td>

                          {/* <td
                            className="text-capitalize"
                            onClick={() => {
                              if (
                                request.review &&
                                request?.review?.length > 50
                              ) {
                                setReviewModal(true);
                                setReviewData(request.review);
                              }
                            }}
                          >
                            {request?.review &&
                              (request?.review?.length > 50
                                ? charLimit(request.review, 50)
                                : request.review || "---")}
                          </td> */}
                          <td>
                            <div className="action_button">
                              <Switch
                                checked={request.status}
                                onChange={() => {
                                  setStatusModal(true);
                                  setFeedbackId({
                                    feedbackId: request._id,
                                    status:
                                      request.status == true ? "false" : "true",
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={6}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* Status Modal */}
          <ModalBox
            status={statusModal}
            onClose={() => {
              setStatusModal(false);
              setFeedbackId({});
            }}
            title={"Change Status"}
            description={"Are you sure want to change status of this feedback?"}
            action={changeFeedbackStatusHandler}
            ref={buttonRef}
          />



          {reviewModal && (
            <DescriptionModel
              show={reviewModal}
              close={() => {
                setReviewModal(false);
                setReviewData("");
              }}
              description={reviewData}
              title={"Review"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FeedbackManagement;
