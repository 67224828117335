import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import { useSearchParams } from 'react-router-dom'
import { IoSearch } from 'react-icons/io5'

import NotFound from '../../../components/not-found/NotFound'
import { dateMonthFormat } from '../../../components/Form'
import useFullPageLoader from '../../../hooks/useFullPageLoader'
import useDebounce from '../../../hooks/useDebounce'
import { getLocalStorage, setLocalStorage } from '../../../config/config'
import findSerialNumber from '../../../utils/findSerialNumber'
import { toastMessage } from '../../../utils/toastMessage'
import common from '../../../routes/common'
import { dateFormatWithTime, monthFormat, } from '../../../utils/dateFormat'
import scrollToUp from '../../../utils/scrollToUp'
import { getbeforeConsultantPayoutApi, getConsultantPayoutForExactMonthApi, importExcelApi } from '../../../services/consultantPayout'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ModalBox from '../../../components/modal/ModalBox'
import { regex } from '../../../utils/regex'
import commonMessages from '../../../utils/commonMessages'
import { FaFileExcel } from 'react-icons/fa'
const { NOT_FOUND } = common;

export const ConsultantPayoutCurrentMonthCMP = () => {
    const [searchParam, setSearchParam] = useSearchParams()
    let activePage = +searchParam.get('page') || 1

    const [search, setSearch] = useState(getLocalStorage('payoutList', 'search') || '')

    const debounceValue = useDebounce(search || '', 300)
    const [total, setTotal] = useState(0)
    const [payoutList, setPayoutList] = useState([])
    const fromDate = searchParam.get('fromDate') || '';
    const toDate = searchParam.get('toDate') || '';
    useEffect(() => {
        setLocalStorage('payoutList', 'search', search)
    }, [search])

    const [loader, showLoader, hideLoader] = useFullPageLoader()

    //*********Handel Pagination********* */
    const handlePageChange = (event) => {
        searchParam.set('page', event)
        setSearchParam(searchParam)
    }

    //------------------consultant payout -listing-Api----------------------------------
    const getpayoutList = async (debounceValue) => {
        try {
            showLoader()
            const sendData = {
                page: `${activePage}`,
                limit: '10',
            }
            if (debounceValue) {
                sendData.page = '1'
                sendData.search = debounceValue
                searchParam.delete('page')
                setSearchParam(searchParam)
            }
            if (fromDate) {
                sendData.fromDate = fromDate
            }
            if (toDate) {
                sendData.toDate = toDate
            }

            const response = await getbeforeConsultantPayoutApi(sendData)
            console.log(response)
            const data = response.data

            // const { data } =
            if (data.code === 200) {
                setPayoutList(data?.result?.finalList || [])
                setTotal(data?.result?.total || 0)
                scrollToUp()
            } else {
                setPayoutList([])
                setTotal(0)
                toastMessage(data.message, 'error', 'payoutList')
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            hideLoader()
        }
    }

    useEffect(() => {
        if (Number(activePage) === 1) {
            searchParam.delete('page')
        }
        setSearchParam(searchParam)
        getpayoutList(debounceValue)
    }, [searchParam, debounceValue, fromDate && toDate])

    return (
        <>
            {loader}
            <div className="">
                <div className="FilterBox FilterArea">
                    <div className="form-group search_lg_size userSearch">
                        <input
                            type="search"
                            className={`form-control ${search && 'active'}`}
                            placeholder="Search"
                            value={search || ''}
                            style={{ '--color': '#FF8989' }}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="user_search_icon">
                            <IoSearch />
                        </span>
                    </div>
                </div>
                <div className="content-table">
                    <table>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Date Of Joining</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Withdraw Amount (INR)</th>
                                <th>PAN</th>
                                <th>Phone Number</th>
                                <th>Bank Account Name</th>
                                <th>Bank IFSC code</th>
                                <th>Bank Holder Name</th>
                                <th>Transaction ID</th>
                                <th>Payment Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payoutList.length > 0 ? (
                                payoutList.map((user, index) => {
                                    return <tr key={user?.createdAt}>
                                        <td>{findSerialNumber(index, activePage, 10)}</td>
                                        <td>{dateMonthFormat(user?.createdAt)}</td>
                                        <td>{user.name || '---'}</td>
                                        <td>{user.email || '---'}</td>
                                        <td>{`${Number(user?.coins).toFixed(2)} INR` || '---'}</td>
                                        <td>{user?.panNumber || '---'}</td>
                                        <td>{user?.mobile || '---'}</td>
                                        <td>{user?.bankName || '---'}</td>
                                        <td>{user?.bankHolderName || '---'}</td>
                                        <td>{user?.ifsc || '---'}</td>
                                        <td>{user?.transactionId || '---'}</td>
                                        <td> {dateFormatWithTime(user?.payOutDate) || '---'} </td>
                                    </tr>
                                })
                            ) : (
                                <NotFound
                                    cols={12}
                                    msg="Payout not found!"
                                    icon={NOT_FOUND}
                                    loader={loader}
                                />
                            )}
                        </tbody>
                    </table>
                </div>
                {total > 10 ? (
                    <div className="pagination-wrapper">
                        <div className="Pagination">
                            <Pagination
                                activePage={activePage}
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                itemsCountPerPage={10}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                hideDisabled={true}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export const ConsultantPayoutMonthCMP = ({ }) => {

    const [searchParam, setSearchParam] = useSearchParams()
    let activePage = +searchParam.get('page') || 1;

    const [search, setSearch] = useState(getLocalStorage('payoutList', 'search') || '')

    const debounceValue = useDebounce(search || '', 300)
    const [total, setTotal] = useState(0)
    const [payoutList, setPayoutList] = useState([])
    const fromDate = searchParam.get('fromDate') || '';
    const toDate = searchParam.get('toDate') || '';
    const [settelmentInfo, setSettelmentInfo] = useState(null);

    const [importModal, setImportModal] = useState(false)
    const [file, setFile] = useState('')
    const [error, setError] = useState({})
    const { excelFileRegex } = regex;
    const { EXCEL_FORMAT } = commonMessages

    const [tabState, setTabState] = useState('final-amount'); // ['final-amount','paid-amount']

    useEffect(() => {
        setLocalStorage('payoutList', 'search', search)
    }, [search])

    const [loader, showLoader, hideLoader] = useFullPageLoader()

    //*********Handel Pagination********* */
    const handlePageChange = (event) => {
        searchParam.set('page', event)
        setSearchParam(searchParam)
    }

    //------------------consultant payout -listing-Api----------------------------------
    const getpayoutList = async (debounceValue) => {
        try {
            showLoader()
            const sendData = {
                page: `${activePage}`,
                limit: '10',
                month: 'December',
                year: '2024'
            }
            if (debounceValue) {
                sendData.page = '1'
                sendData.search = debounceValue
                searchParam.delete('page')
                setSearchParam(searchParam)
            }
            if (fromDate) {
                sendData.fromDate = fromDate
            }
            if (toDate) {
                sendData.toDate = toDate
            }

            const response = await getConsultantPayoutForExactMonthApi(sendData)
            const data = response.data
            if (data.code === 200) {
                console.log('data ---> ', data)
                setTabState('final-amount')
                setPayoutList(data?.result?.finalList || [])
                setTotal(data?.result?.total || 0)
                setSettelmentInfo(data?.result?.prevPayoutTrack || null)
                scrollToUp()
            } else {
                setPayoutList([])
                setTotal(0)
                setSettelmentInfo(null)
                toastMessage(data.message, 'error', 'payoutList')
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            hideLoader()
        }
    }

    const validation = () => {
        let status = true
        let errors = {}
        if (!file) {
            errors['file'] = 'File is mandatory'
            status = false
        }
        setError(errors)
        return status
    }

    const importFileHandler = async () => {
        if (validation()) {
            try {
                const formData = new FormData()
                formData.append('payOut', file)
                formData.append('payoutMonth', settelmentInfo?.month);
                formData.append('payoutYear', settelmentInfo?.year);
                const res = await importExcelApi(formData)
                if (res?.data?.code === 200) {
                    toastMessage(res?.data?.message, 'success', 'importExcel')
                    setImportModal(false)
                    setError('')
                    setFile('')
                } else {
                    toastMessage(res.data.message, 'error', 'importExcel')
                }
            } catch (e) {
                console.log('Error:', e.message)
            } finally {

            }
        }
    }

    const fileHandler = (e) => {
        e.preventDefault()
        if (e.target.files.length > 0) {
            if (excelFileRegex.test(e.target.files[0].name)) {
                setFile(e.target.files[0])
            } else {
                e.target.value = ''
                setFile('')
                toastMessage(EXCEL_FORMAT, 'error', 'imageFormat')
            }
        } else {
            e.target.value = ''
            setFile('')
        }
    }

    useEffect(() => {
        if (Number(activePage) === 1) {
            searchParam.delete('page')
        }
        setSearchParam(searchParam)
        getpayoutList(debounceValue)
    }, [searchParam, debounceValue, fromDate && toDate]);

    return (
        <>
            {loader}
            <div style={{ display: 'flex', gap: 12 }}>
                <Button variant={tabState == 'final-amount' ? 'contained' : "outlined"}>Final Amount Decided On This Month</Button>
                <Button variant={tabState == 'paid-amount' ? 'contained' : "outlined"}>Paid Amount On This Month</Button>
                <Button variant="contained" color="success" onClick={() => {
                    window.open(settelmentInfo?.payoutSheet, '_blank');
                }}>Download Un-paid Excel</Button>
                <Button variant="contained" color="success" onClick={() => {
                    setImportModal(true);
                }}>Upload Payout Sheet</Button>
            </div>
            <div style={{ display: 'flex', gap: 12, padding: '12px 2px', justifyContent: 'space-between' }}>
                <p><span style={{ fontWeight: '600' }}>Month : </span>{settelmentInfo?.month}</p>
                <p><span style={{ fontWeight: '600' }}>Year : </span> {settelmentInfo?.year}</p>
                <p><span style={{ fontWeight: '600' }}>Settlement Completed? : </span>{settelmentInfo?.isSettlementComplete ? 'Yes' : 'No'}</p>
            </div>
            <div style={{ marginTop: 24 }}>
                <div className="">
                    <div className="search_lg_size userSearch" style={{}}>
                        <input
                            type="search"
                            className={`form-control ${search && 'active'}`}
                            placeholder="Search"
                            value={search || ''}
                            style={{ '--color': '#FF8989' }}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="user_search_icon">
                            <IoSearch />
                        </span>
                    </div>
                </div>
                <div className="content-table">
                    <table>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Date Of Joining</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Withdraw Amount (INR)</th>
                                <th>PAN</th>
                                <th>Phone Number</th>
                                <th>Bank Account Name</th>
                                <th>Bank IFSC code</th>
                                <th>Bank Holder Name</th>
                                <th>Transaction ID</th>
                                <th>Payment Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payoutList.length > 0 ? (
                                payoutList.map((user, index) => {
                                    return <tr key={user?.createdAt}>
                                        <td>{findSerialNumber(index, activePage, 10)}</td>
                                        <td>{dateMonthFormat(user?.createdAt)}</td>
                                        <td>{user.name || '---'}</td>
                                        <td>{user.email || '---'}</td>
                                        <td>{`${Number(user?.coins).toFixed(2)} INR` || '---'}</td>
                                        <td>{user?.panNumber || '---'}</td>
                                        <td>{user?.mobile || '---'}</td>
                                        <td>{user?.bankName || '---'}</td>
                                        <td>{user?.bankHolderName || '---'}</td>
                                        <td>{user?.ifsc || '---'}</td>
                                        <td>{user?.transactionId || '---'}</td>
                                        <td> {dateFormatWithTime(user?.payOutDate) || '---'} </td>
                                    </tr>
                                })
                            ) : (
                                <NotFound
                                    cols={12}
                                    msg="Payout not found!"
                                    icon={NOT_FOUND}
                                    loader={loader}
                                />
                            )}
                        </tbody>
                    </table>
                </div>
                {total > 10 ? (
                    <div className="pagination-wrapper">
                        <div className="Pagination">
                            <Pagination
                                activePage={activePage}
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                itemsCountPerPage={10}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                hideDisabled={true}
                            />
                        </div>
                    </div>
                ) : null}
            </div>

            <ModalBox
                status={importModal}
                onClose={() => {
                    setImportModal(false)
                    setError('')
                    setFile('')
                }}
                title={'Import File'}
                closeIcon
                size={'sm'}
                custom
                customizedFooter={
                    <div className="model_custom_footer">
                        <button
                            className="btn_size"
                            onClick={importFileHandler}
                        >
                            Import
                        </button>
                    </div>
                }
            >
                <div>
                    <div className="form-group my-2">
                        <label className="my-1 text-dark w-100">Select Month</label>
                        <input
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            name="file"
                            required
                            onChange={(e) => fileHandler(e)}
                            placeholder="Upload Excel File"
                            className={`form-control `}
                            label={'Export Excel File'}
                        //   error={!file && error}
                        // maxLength={30}
                        />
                        {error.file && !file && (
                            <span className="invalid-feedback d-block">{error.file}</span>
                        )}
                    </div>

                    {file && !error.file && (
                        <div className="cstm_icon_preview">
                            <FaFileExcel />{' '}
                        </div>
                    )}
                </div>
            </ModalBox>
        </>
    )
} 