import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fetch-refer-listing--------------------------
export const fetchReferApi = async (data) => {
  const endPoint =
    `/admin/refer` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

//------------------ Refer Approve Handler-------------------------------
export const referApproveApi = async (data) => {
  const endPoint = `admin/refer/approve`;
  return await axios.put(endPoint, data, setJwtToken());
};
