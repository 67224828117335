import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category, music } from "../../routes/route";
import common from "../../routes/common";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchMusicApi } from "../../services/musicManagement";
import EditIcon from "@mui/icons-material/Edit";
import AddMusic from "./AddMusicCategory";
import showFullSizeImage from "../../utils/showFullSizeImage";
import MusicCategoryStatus from "./MusicCategoryStatus";
import EditMusicCategory from "./EditMusicCategory";
import scrollToUp from "../../utils/scrollToUp";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import ImagePreviewModel from "../../components/imagePreview/ImagePreviewModel";
import AddIcon from '@mui/icons-material/Add';
const { ADD_MUSIC } = category;
const { NOT_FOUND } = common;
const { MUSIC_DETAIL } = music;

const MusicCategoryList = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  const [search, setSearch] = useState(
    getLocalStorage("musicCategory", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  const [addMusicModal, setAddMusicModal] = useState(false);
  const [editMusicModel, setEditMusicModel] = useState(false);
  const [musicCategoryList, setMusicCategoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedMusicCategoryDetail, setSelectedMusicCategoryDetail] =
    useState(null);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageTitle, setImageTitle] = useState("");

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage("musicCategory", "search", search);
  }, [search]);
  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  // update music category handler
  const updateMusicCategoryHandler = (data) => {
    setSelectedMusicCategoryDetail(data || null);
    setEditMusicModel(true);
  };

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const showImagePreviewHandler = (music) => {
    setShowImagePreviewModel(true);
    setImagePreview(music.musicImage);
    setImageTitle(music.title);
  };

  // close model

  const closeModel = () => {
    setSelectedMusicCategoryDetail(null);
    setStatusValue("");
    setStatusId("");
    setAddMusicModal(false);
    setStatusModal(false);
    setEditMusicModel(false);
    setImagePreview("");
    setImageTitle("");
    setShowImagePreviewModel(false);
  };

  // //------------------listing-music-Api----------------------------------
  const fetchMusicHandler = async () => {
    try {
      setMusicCategoryList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
      };
      if (debounceValue) {
        sendData.page = 1;
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchMusicApi(sendData);
      if (data.code === 200) {
        setMusicCategoryList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setMusicCategoryList([]);
        setTotal(0);
        toastMessage(data.message, "error", "musicList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchMusicHandler();
  }, [searchParam, debounceValue]);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Music Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddMusicModal(true)}>
                <AddIcon/>Add Category
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  {/* <th>Music Id</th> */}
                  <th>Music Title</th>
                  <th>Music Image</th>
                  <th>Number of Music</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {musicCategoryList.length > 0 ? (
                  musicCategoryList.map((music, index) => {
                    return (
                      <React.Fragment key={music._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          {/* <td>{music._id || '---'}</td> */}
                          <td>{charLimit(music.title, 30) || "---"}</td>
                          <td>
                            <Avatar
                              id={music._id}
                              alt={music.title}
                              src={music.musicImage}
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: "pointer",
                                borderRadius: 2,
                              }}
                              onClick={() => showImagePreviewHandler(music)}
                            />
                          </td>

                          <td>{Number(music.musicMedia) || 0}</td>
                          <td>
                            <div className="action_button">
                              <Link
                                to={`${MUSIC_DETAIL}/${music.title}/${music._id}`}
                              >
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateMusicCategoryHandler(music)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={music.status}
                                onChange={() =>
                                  statusUpdateHandler(music._id, music.status)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Music Categories not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addMusicModal && (
          <AddMusic
            show={addMusicModal}
            close={closeModel}
            listing={fetchMusicHandler}
          />
        )}
        {statusModal && (
          <MusicCategoryStatus
            show={statusModal}
            close={closeModel}
            setListing={setMusicCategoryList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editMusicModel && (
          <EditMusicCategory
            show={editMusicModel}
            close={closeModel}
            musicCategoryDetail={selectedMusicCategoryDetail}
            setMusicCategoryDetail={setSelectedMusicCategoryDetail}
            musicListing={fetchMusicHandler}
          />
        )}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
      </div>
    </>
  );
};

export default MusicCategoryList;
