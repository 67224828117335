import { instance as axios } from '../../config/axiosInstance'

// ---------------------login----------------------------------
export const loginApi = async (data) => {
  return await axios.post('/admin/login', data)
}
// ---------------------forgot-email------------------------------

export const forgotApi = async (data) => {
  const endPoint = `/admin/forgot_password?email=` + encodeURIComponent(data)
  return await axios.get(endPoint)
}
// ---------------------reset-password---------------------------------

export const resetPasswordApi = async (data) => {
  const endPoint = '/admin/reset_password'
  return await axios.put(endPoint, data)
}
