import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { Input, Select } from "../../components/Form";
import ReactDatePicker from "react-datepicker";
import { dateFormatUtc } from "../../utils/dateFormat";
import {
  addDiscountApi,
  editDiscountApi,
} from "../../services/discountManagement";
import useButtonLoader from "../../hooks/UseButtonLoader";

const EditCoupon = ({
  editCouponModal,
  selectedData,
  setSelectedData,
  setDiscountList,
  setEditCouponModal,
  fetchDiscountHandler,
  buttonType,
}) => {
  const [error, setError] = useState({});
  const [image, setImage] = useState("");
  const { imageFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  const modalClose = () => {
    setError({});
    setSelectedData({});
    setEditCouponModal(false);
  };

  // console.log(selectedData,"SelectedData");
  // return

  const validation = () => {
    let status = true;
    let error = {};

    if (!selectedData?.title) {
      error["title"] = "Title is required *";
      status = false;
    }
    if (!selectedData?.couponDiscount) {
      error["couponDiscount"] = "Coupon discount is required *";
      status = false;
    }
    if (!selectedData?.couponStartDate) {
      error["couponStartDate"] = "Start date is required *";
      status = false;
    }
    if (!selectedData?.couponEndDate) {
      error["couponEndDate"] = "End date is required *";
      status = false;
    }
    if (!selectedData?.couponType) {
      error["couponType"] = "Coupon type is required *";
      status = false;
    }
    if (!image && !image?.name && !selectedData.image) {
      error["membershipDiscountImage"] = "Image is required *";
      status = false;
    }
    setError(error);
    return status;
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  const editCouponHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", selectedData.title);
        formData.append("membershipDiscountId", selectedData._id);
        formData.append("type", buttonType);
        formData.append("couponType", selectedData.couponType);
        formData.append("couponDiscount", selectedData.couponDiscount);
        formData.append(
          "couponStartDate",
          dateFormatUtc(selectedData?.couponStartDate)
        );
        formData.append(
          "couponEndDate",
          dateFormatUtc(selectedData?.couponEndDate)
        );
        if (image && image?.name) {
          formData.append("membershipDiscountImage", image);
        }
        const { data } = await editDiscountApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "editCoupon");
          fetchDiscountHandler();
          modalClose();
        } else {
          toastMessage(data.message, "error", "editCoupon");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={editCouponModal}
      onClose={modalClose}
      title={"Edit Coupon"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={editCouponHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={selectedData.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setSelectedData({ ...selectedData, ["title"]: sanitizedText });
            }}
            placeholder="Coupon Name"
            className={`form-control`}
            label={"Coupon Name"}
            error={!selectedData.title && error.title}
            maxLength={30}
          />

          <div className="form-group mb-2">
            <Select
              name="couponType"
              value={selectedData.couponType}
              required
              onChange={(e) => {
                setSelectedData({
                  ...selectedData,
                  couponType: e.target.value,
                });
              }}
              className={`form-control`}
              label={"Plan Type"}
              error={!selectedData.couponType && error.couponType}
              maxLength={40}
            >
              <option value="">Select Type</option>
              <option value="COUPON">Coupon</option>
              <option value="PROMOCODE">Promocode</option>
            </Select>
          </div>
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="couponDiscount"
            value={selectedData.couponDiscount || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedData({
                ...selectedData,
                ["couponDiscount"]: sanitizedText,
              });
            }}
            placeholder="Coupon Discount"
            className={`form-control`}
            label={"Coupon Discount"}
            error={!selectedData.couponDiscount && error.couponDiscount}
            maxLength={5}
          />
        </div>
        <div className="form-group mb-2">
          <label className="my-1 text-dark w-100">Start Date</label>
          <ReactDatePicker
            selected={
              selectedData?.couponStartDate
            }
            onChange={(date) => {
              setSelectedData({ ...selectedData, ["couponStartDate"]: date });
            }}
            className={`form-control multi_search_date`}
            isClearable
            dateFormat="dd-MMM-yyyy"
            placeholderText="dd-MMM-yyyy"
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            minDate={new Date()}
          />
          {error.couponStartDate && (
            <span className="invalid-feedback d-block">
              {error.couponStartDate}
            </span>
          )}
        </div>
        <div className="form-group mb-2">
          <label className="my-1 text-dark w-100">End Date</label>
          <ReactDatePicker
            selected={
              selectedData?.couponEndDate
            }
            onChange={(date) => {
              setSelectedData({ ...selectedData, ["couponEndDate"]: date });
            }}
            className={`form-control multi_search_date`}
            isClearable
            dateFormat="dd-MMM-yyyy"
            placeholderText="dd-MMM-yyyy"
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            minDate={selectedData?.couponStartDate}
          />
          {error.couponEndDate && (
            <span className="invalid-feedback d-block">
              {error.couponEndDate}
            </span>
          )}
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="membershipDiscountImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Image"
            className={`form-control `}
            label={"Image"}
            error={!image && error.membershipDiscountImage}
          />
        </div>

        <div>
          <>
            <Avatar
              variant="square"
              sx={{
                width: "100%",
                borderRadius: 1,
                height: 140,
                border: "1px solid #FF8989",
              }}
              src={
                image && image.name
                  ? URL.createObjectURL(image)
                  : selectedData?.image
              }
            />
          </>
        </div>
      </div>
    </ModalBox>
  );
};

export default EditCoupon;
