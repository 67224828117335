import { Email } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import useButtonLoader from '../../hooks/UseButtonLoader'

const AfterForgetPassword = ({ email = '', forgetPassword }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Resent link', '#fd4f4fe5')
  return (
    <div className="LoginArea">
      <h1 className="login_head">Vedic Meet</h1>
      <div className="LoginBox">
        <h5 className="mt-2">Email Sent!</h5>
        <p className="text-center">
          An email has been send to your registered email address.
          <br />
          Please click on the link in the shared
          <br /> email to reset your password.
        </p>
        <div className="resend_email_btn">
          <button
            onClick={(e) => forgetPassword(e, email, buttonLoader)}
            ref={buttonref}
          >
            Resent link
          </button>
          <span>{'to '}</span>
          <strong>{email}</strong>
        </div>
        <div className="form_btn">
          {/* <button className="login_btn"> */}
          <Link to={'/'}>
            Go to Login <i className="fa fa-sign-in"></i>
          </Link>
          {/* </button> */}
        </div>
      </div>
    </div>
  )
}
export default AfterForgetPassword
