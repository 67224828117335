import { configureStore } from "@reduxjs/toolkit";
import configRights from "./slices/rightsSlice";

const appStore = configureStore({
  reducer: {
    vedicRights: configRights,
  },
});

export default appStore;
