import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { Link, useParams, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import { Select } from "../../components/Form";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { IoSearch } from "react-icons/io5";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";
import { toastMessage } from "../../utils/toastMessage";
import common from "../../routes/common";
// import { dateFormat } from "../../utils/dateFormat";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { block_unblock_Api, fetchFormApi } from "../../services/form16A";
import Switch from "../../components/Switch/Switch";
import consultantPerformance from "../../Assests/image/consultantPerformance.svg";
import AddIcon from "@mui/icons-material/Add";

import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  fetchShopifyCommissionListApi,
  fetchShopifyOrderListApi,
} from "../../services/shopify";
import { dateFormat } from "../../utils/dateFormat";


const { NOT_FOUND } = common;

const ConsultantCommission = () => {
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [search, setSearch] = useState(
    getLocalStorage("commissionList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [getCommissionModal, setGetCommissionModal] = useState(false);
  const [commissionList, setCommissionList] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const status = searchParam.get("status") || "";

  useEffect(() => {
    setLocalStorage("commissionList", "search", search);
  }, [search]);

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };


  //------------------request-listing-Api----------------------------------
  const getConsultantCommissionHandler = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        // consultantId: consultantId,
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setCommissionList([]);
      const { data } = await fetchShopifyOrderListApi(sendData);
      if (data.code === 200) {
        setCommissionList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setCommissionList([]);
        setTotal(0);
        toastMessage(data.message, "error", "commissionList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };


  // Get Commission  Coupon
  const getCommissionHandler = async () => {
    try {
      buttonLoader(true);
      const res = await fetchShopifyCommissionListApi();
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "discountCouponId");
        setGetCommissionModal(false);
        getConsultantCommissionHandler();
      } else {
        toastMessage(res?.data?.message, "error", "discountCouponId");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!status) {
      searchParam.delete("status");
    }
    setSearchParam(searchParam);
    getConsultantCommissionHandler(debounceValue);
  }, [searchParam, debounceValue]);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              <h4 className="Title">Shopify Commission</h4>
            </div>
            {/* <h4 className="Title"></h4> */}
            <div className="block_user_button">
              <button onClick={() => setGetCommissionModal(true)}>
                <AddIcon />
                Get Commission
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Consultant Name</th>
                  <th>Product Name</th>

                  <th>Coupon</th>
                  <th>Amount Paid</th>
                  <th>Product Actual Price</th>
                  <th>Last Order Date</th>

                </tr>
              </thead>
              <tbody>
                {commissionList.length > 0 ? (
                  commissionList.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            {(data?.consultantDetail &&
                              data?.consultantDetail?.name) ||
                              "---"}
                          </td>
                          <td>{data?.productName || "---"}</td>
                          <td>{data?.coupon || "---"}</td>
                          <td>{data?.amountPaid || "---"}</td>
                          <td>{data?.productActualPrice || "---"}</td>
                          <td>
                            {dateFormat(data?.lastOrderGetDate) || "---"}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <ModalBox
        status={getCommissionModal}
        onClose={() => setGetCommissionModal(false)}
        title={"Commissiom"}
        description={"Are you sure want to give commission?"}
        action={getCommissionHandler}
        ref={buttonRef}
      />
    </>
  );
};

export default ConsultantCommission;
