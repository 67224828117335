import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-music-listing--------------------------
export const fetchMeditationApi = async (data) => {
  const endPoint =
    `/admin/meditation/category_list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// // ---------------------add-category---------------------------
export const AddMeditationCategoryApi = async (data) => {
  const endPoint = `/admin/meditation/category_add`
  return await axios.post(endPoint, data, setMultiPartHeader())
}
// // ---------------------change-status-category---------------------------
export const changeMeditationStatusCategory = async (data) => {
  const endPoint = `/admin/meditation/category_block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}
// update meditation cateogry

export const updateMeditationCategoryApi = async (data) => {
  const endPoint = `/admin/meditation/category_update`
  return await axios.put(endPoint, data, setMultiPartHeader())
}

// Meditation handler
// add meditation api handler
export const addMeditationApiHandler = async (data) => {
  const endPoint = `/admin/meditation/media_add`
  return await axios.post(endPoint, data, setMultiPartHeader())
}

// meditation list handler

export const fetchMeditationListHandler = async (data) => {
  const endPoint =
    `/admin/meditation/media_list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Change music status
export const changeMeditationStatus = async (data) => {
  const endPoint = `/admin/meditation/media_block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}

// update music media
export const updateMeditationApiHandler = async (data) => {
  const endPoint = `/admin/meditation/media_update`
  return await axios.put(endPoint, data, setMultiPartHeader())
}
