import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from '../../config/axiosInstance'

// ---------------------fatch-music-listing--------------------------
export const fetchTrainigCategoryApi = async (data) => {
  const endPoint =
    `/admin/growth/category_list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// // ---------------------add-category---------------------------
export const AddTrainingCategoryApi = async (data) => {
  const endPoint = `/admin/growth/category_add`
  return await axios.post(endPoint, data, setJwtToken())
}
// // ---------------------change-status-category---------------------------
export const changeTrainingStatusCategory = async (data) => {
  const endPoint = `/admin/growth/category_block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}
// update meditation cateogry

export const updateTrainingCategoryApi = async (data) => {
  const endPoint = `/admin/growth/category_update`
  return await axios.put(endPoint, data, setJwtToken())
}

// Meditation handler
// add meditation api handler
export const addTrainingMediaApiHandler = async (data) => {
  const endPoint = `/admin/growth/media_add`
  return await axios.post(endPoint, data, setJwtToken())
}

// meditation list handler

export const fetchTrainingMediaApi = async (data) => {
  const endPoint =
    `/admin/growth/media_list` +
    '?' +
    new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}

// Change music status
export const changeTrainingStatus = async (data) => {
  const endPoint = `/admin/growth/media_block_unblock`
  return await axios.put(endPoint, data, setJwtToken())
}

// update music media
export const editTrainingMediaApiHandler = async (data) => {
  const endPoint = `/admin/growth/media_update`
  return await axios.put(endPoint, data, setJwtToken())
}
