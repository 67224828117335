import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from '../../config/axiosInstance'
  
  // ---------------------fatch-gift-listing--------------------------
  export const fetchGiftApi = async (data) => {
    const endPoint =
      `/admin/gift` + '?' + new URLSearchParams({ ...data }).toString()
  
    return await axios.get(endPoint, setJwtToken())
  }

  // ---------------------change-status-gift---------------------------
export const changeStatusGift = async (data) => {
    const endPoint = `/admin/gift/block_unblock`
    return await axios.put(endPoint, data, setJwtToken())
  }

  // ---------------------add-gift---------------------------
export const AddGiftApi = async (data) => {
    const endPoint = `/admin/gift/add`
    return await axios.post(endPoint, data, setMultiPartHeader())
  }

  export const editGiftApi=async(data)=>{
    const endPoint= `/admin/gift/update`
    return await axios.put(endPoint,data,setMultiPartHeader())
  }