import { BrowserRouter, HashRouter } from "react-router-dom";
import Routing from "./routes/Routing";
import "./css/style.css";
import { AxiosInterceptor } from "./config/axiosInstance";
import "react-phone-input-2/lib/bootstrap.css";
import { Provider } from "react-redux";
import appStore from "./utils/store/appStore";

function App() {
  return (
    <>
      {/* <HashRouter> */}
        <BrowserRouter>
        <Provider store={appStore}>
          <AxiosInterceptor>
            <Routing />
          </AxiosInterceptor>
        </Provider>
        </BrowserRouter>
      {/* </HashRouter> */}
    </>
  );
}

export default App;
