import React, { useState } from 'react'

export const BaseToImage = (base64Data = '') => {
  const base64String = base64Data.split(',')[1]
  // Step 1: Decode Base64 data
  const decodedData = atob(base64String)

  // Step 2: Create a Blob
  const arrayBuffer = new ArrayBuffer(decodedData.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: 'image/png' }) // Adjust the type based on your image format

  // Step 3: Create a File (optional)
  const file = new File([blob], 'image.png', { type: 'image/png' }) // Adjust the name and type accordingly

  return file
}
