import React, { useState } from "react";
import { Avatar } from "@mui/material";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import ModalBox from "../../../components/modal/ModalBox";
import { allowAlphaNumericWithSpace, regex } from "../../../utils/regex";
import { Input, TextArea } from "../../../components/Form";
import { toastMessage } from "../../../utils/toastMessage";
import { AddMediaCategoryApi } from "../../../services/categoryManagement";
import {
  checkImageAspectRatio,
  checkImageDimensions,
} from "../../../utils/checkImageDimension";
import commonMessages from "../../../utils/commonMessages";
import configData from "../../../config/configData";
import VideoPreview from "../../../components/videoPreview/VideoPreview";

const { imageFileRegex, audioFileRegex, videoFileRegex } = regex;
const {
  CATEGORY_TITLE,
  CATEGORY_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
  EXCEED_SIZE,
  AUDIO_EXCEED_SIZE,
  ERROR_AUDIO_LOADING,
} = commonMessages;

const AddMedia = ({ show, close, listing, categoryId,type,setType }) => {
  // handle all state
  const [categoryDescription, setCategoryDescription] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  // handle all function

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!categoryDescription) {
      errorMessage["description"] = "Please add description";
      status = false;
    }
    if (!image.name) {
      errorMessage["image"] = "Please choose media";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  // Add Category media handler function
  const imageHandler = async (e) => {
    e.preventDefault();
    const { files } = e.target;

    if (files.length === 0) {
      clearInputAndImageState(e);
      return;
    }

    const file = files[0];
    const fileName = file.name.toLowerCase();
    const isGif = fileName.endsWith(".gif");
    const isImage = imageFileRegex.test(fileName);
    const isVideo = videoFileRegex.test(fileName);
    const isAudio = audioFileRegex.test(fileName);

    if (isGif) {
      handleGif(file, e);
    } else if (isImage) {
      setType("image");
      handleImage(file, e);
    } else if (isVideo) {
      setType("video");
      setImage(file);
    } else if (isAudio) {
      setType("music");
      handleAudio(file, e);
    } else {
      handleInvalidFileFormat(e);
    }
  };

  const clearInputAndImageState = (e) => {
    e.target.value = "";
    setImage("");
  };

  const handleGif = async (file, e) => {
    const validAspectRatio = await checkImageAspectRatio(file);
    if (validAspectRatio) {
      setImage(file);
    } else {
      clearInputAndImageState(e);
    }
  };

  //   Handle Audio
  const handleAudio = async (file, e) => {
    const maxSizeInBytes = configData.audio_legth;
    const maxDurationInSeconds = configData.audio_duration;
    if (file.size > maxSizeInBytes) {
      clearInputAndImageState(e);
      toastMessage(EXCEED_SIZE, "error", "addAudio");
      return;
    }

    const audioElement = document.createElement("audio");
    audioElement.src = URL.createObjectURL(file);
    audioElement.onloadedmetadata = () => {
      if (audioElement.duration > maxDurationInSeconds) {
        clearInputAndImageState(e);
        toastMessage(AUDIO_EXCEED_SIZE, "error", "addAudio");
      } else {
        // setMusicDuration(convertSecToMin(audioElement.duration));
        setImage(file);
      }
    };
    audioElement.onerror = () => {
      clearInputAndImageState(e);
      toastMessage(ERROR_AUDIO_LOADING, "error", "addAudio");
    };
  };

  const handleImage = async (file, e) => {
    const validSize = await checkImageDimensions(file);
    if (validSize) {
      setImage(file);
    } else {
      clearInputAndImageState(e);
    }
  };

  const handleInvalidFileFormat = (e) => {
    toastMessage(
      "Invalid format: Only Audio and images and videos supported",
      "error",
      "InvalidFormat"
    );
    clearInputAndImageState(e);
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage("");
    setCategoryDescription("");
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const addMediaCategoryHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("description", categoryDescription);
        formData.append("categoryMedia", image);
        formData.append("categoryId", categoryId);
        formData.append("type", type);

        const { data } = await AddMediaCategoryApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addCategory");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addCategory");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      description={"Add Media"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addMediaCategoryHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <TextArea
            type="text"
            name="description"
            value={categoryDescription || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowAlphaNumericWithSpace(e.target.value);
              setCategoryDescription(sanitizedText);
            }}
            placeholder="Description"
            className={`form-control`}
            label={"Add Description"}
            error={!categoryDescription && error.description}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            // value={image.name || ''}
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Category Image"
            className={`form-control `}
            label={"Category Media"}
            error={!image && error.image}
            // maxLength={30}
          />
        </div>

        <div>
          {type === "image" && image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
          {type === "music" && image && (
            <>
              <div className="music-player">
                <audio
                  src={image && URL.createObjectURL(image)}
                  // autoPlay={true}
                  controls
                  controlsList="nodownload"
                />
              </div>
            </>
          )}
          {type === "video" && image && (
            <>
              <div className="video-container">
                <VideoPreview videoUrl={URL.createObjectURL(image)} />
              </div>
            </>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddMedia;
