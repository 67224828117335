import React, { Fragment, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { regex, optimizeFunction } from "../../utils/regex";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { AddMusicCategoryApi } from "../../services/musicManagement";
import commonMessages from "../../utils/commonMessages";
import { checkImageDimensions } from "../../utils/checkImageDimension";
import { addVastuCategoryApi } from "../../services/vastuCompassCategory";
import { category } from "../../routes/route";
const {
  VASTU_COMPASS_CATEGORY_TITLE,
  VASTU_COMPASS_CATEGORY,
  VASTU_COMPASS_CATEGORY_IMAGE,
  IMAGE_FORMAT,
  MESSAGE_SIZE,
} = commonMessages;
const { imageFileRegex } = regex;

const AddVastuCompass = ({
  show,
  close,
  listing,
  vastuCategory,
  setVastuCategory,
  vastuTitle,
  setVastuTitle,
}) => {
  const [vastuCategoryTitle, setVastuCategoryTitle] = useState({});
  const [image, setImage] = useState("");
  const [error, setError] = useState({});

  const [buttonref, buttonLoader] = useButtonLoader("Save");
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!vastuCategoryTitle.title) {
      errorMessage["title"] = VASTU_COMPASS_CATEGORY_TITLE;
      status = false;
    }
    if (!vastuCategoryTitle.category) {
      errorMessage["category"] = VASTU_COMPASS_CATEGORY;
      status = false;
    }
    if (!image.name) {
      errorMessage["image"] = VASTU_COMPASS_CATEGORY_IMAGE;
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  // Add Category image handler function

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        const validSize = await checkImageDimensions(e.target.files[0]);
        if (validSize) {
          setImage(e.target.files[0]);
        } else {
          e.target.value = "";
          setImage("");
        }
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setImage("");
    setVastuCategoryTitle({});
    setError({});
    close();
  };
  //--------------Add-Music-category------------------------
  const addVastuCategoryHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", vastuCategoryTitle.title);
        formData.append("category", vastuCategoryTitle.category);
        formData.append("vastuCompassCategoryImage", image);

        const { data } = await addVastuCategoryApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addVastu");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addVastu");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Vastu Compass Category"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <p>{MESSAGE_SIZE}</p>
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addVastuCategoryHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Select
            name="title"
            value={vastuCategoryTitle.title}
            required
            onChange={(e) => {
              // const sanitizedText = optimizeFunction(e.target.value);
              // setVastuCategoryTitle(sanitizedText);
              setVastuCategoryTitle({
                ...vastuCategoryTitle,
                title: e.target.value,
              });
            }}
            placeholder="Vastu Category Title"
            className={`form-control`}
            label={"Vastu Title"}
            error={!vastuCategoryTitle.title && error.title}
            maxLength={40}
          >
            <option>Select Title</option>
            {vastuTitle.map((data, index) => {
              return (
                <Fragment key={++index}>
                  <option value={data}>{data}</option>
                </Fragment>
              );
            })}
          </Select>
        </div>
        <div className="form-group my-2">
        <Select
            name="category"
            value={vastuCategoryTitle.category}
            required
            onChange={(e) => {
              setVastuCategoryTitle({
                ...vastuCategoryTitle,
                category: e.target.value,
              });
            }}
            className={`form-control`}
            label={"Vastu Category"}
            error={!vastuCategoryTitle.category && error.category}
            maxLength={40}
          >
            <option>Select Category</option>
            {vastuCategory.map((data, index) => {
              return (
                <Fragment key={++index}>
                  <option value={data}>{data}</option>
                </Fragment>
              );
            })}
          </Select>
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="categoryImage"
            required
            onChange={(e) => imageHandler(e)}
            placeholder="Vastu Image"
            className={`form-control `}
            label={"Vastu Image"}
            error={!image && error.image}
          />
        </div>

        <div>
          {image && (
            <>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  height: 140,
                  border: "1px solid #FF8989",
                }}
                src={image && URL.createObjectURL(image)}
              />
            </>
          )}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddVastuCompass;
