import React, { useState } from "react";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";

import { toastMessage } from "../../utils/toastMessage";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { Input } from "../../components/Form";
import ModalBox from "../../components/modal/ModalBox";
import { addShopifyDiscountCouponApi } from "../../services/shopify";

const AddDiscountCoupon = ({
  setAddModal,
  addModal,
  listing,
  consultantId,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [inputField, setInputField] = useState({
    consultantId: consultantId,
    coupon: "",
    commission: null,
  });
  const [error, setError] = useState({});

  const modalClose = () => {
    setError({});
    setAddModal(false);
    setInputField({});
  };

  const validation = () => {
    let status = true;
    const errors = {};
    if (!inputField?.coupon) {
      errors["coupon"] = "Coupon is mandatory";
      status = false;
    }
    if (!inputField?.commission) {
      errors["commission"] = "Commission is Mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const addShopifyDiscountCoupon = async () => {
    if (validation()) {
      try {
        buttonLoader(true);

        const { data } = await addShopifyDiscountCouponApi(inputField);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addDIscountCoupon");
          setInputField({});
          setError({});
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addDIscountCoupon");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={addModal}
      onClose={() => {
        setError({});
        setInputField("");
        setAddModal(false);
      }}
      inputField={"Add Discount Coupon"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addShopifyDiscountCoupon}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="coupon"
            value={inputField?.coupon || ""}
            required
            onChange={(e) => {
              const sanitizedText =e.target.value;
              setInputField({ ...inputField, coupon: sanitizedText });
            }}
            placeholder="Coupon"
            className={`form-control`}
            label={"Coupon"}
            error={!inputField?.coupon && error.coupon}
            maxLength={40}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="text"
            name="commission"
            value={inputField?.commission || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setInputField({ ...inputField, commission: Number(sanitizedText) });
            }}
            placeholder="Commission"
            className={`form-control`}
            label={"Commission"}
            error={!inputField?.commission && error.commission}
            maxLength={3}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddDiscountCoupon;
