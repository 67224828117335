import React, { useState } from 'react'
import { extractVideoId } from '../../utils/extractVideoId'

const YoutubeVideoPlayer = ({ src, height = '315', width = '560' }) => {
  const [error, setError] = useState(false)

  const extractedSrc = extractVideoId(src)

  // console.log('extracted src', extractedSrc)

  const handleOnError = () => {
    setError(true)
  }

  return (
    <>
      {error ? (
        <p>Sorry, there was an error playing the video.</p>
      ) : (
        <iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${extractedSrc}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          onError={handleOnError}
        ></iframe>
      )}
    </>
  )
}

export default YoutubeVideoPlayer
