import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import common from "../../routes/common";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import EditIcon from "@mui/icons-material/Edit";
import { dateFormat } from "../../utils/dateFormat";
import { fetchDiscountApi } from "../../services/discountManagement";
import DiscountStatus from "./DiscountStatus";
import AddIcon from "@mui/icons-material/Add";
import AddCoupon from "./AddCoupon";
import AddRecharge from "./AddRecharge";
import EditCoupon from "./EditCoupon";
import EditRecharge from "./EditRecharge";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import AddMembership from "./AddMembership";
import EditMembership from "./EditMembership";
import { Select } from "../../components/Form";
const { NOT_FOUND } = common;

const DiscountManagement = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let buttonKey = searchParam.get("type") || "membership";
  const [search, setSearch] = useState(
    getLocalStorage("discountList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [editCouponModal, setEditCouponModal] = useState(false);
  const [editRechargeModal, setEditRechargeModal] = useState(false);
  const [addMembershipModal, setAddMembershipModal] = useState(false);
  const [editMembershipModal, setEditMembershipModal] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [selectedData, setSelectedData] = useState({});
  const [membershipDiscountId, setMembershipDiscountId] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [couponModal, setCouponModal] = useState(false);
  const [rechargeModal, setRechargeModal] = useState(false);
  const [couponCodeType, setCouponCodeType] = useState("");
  const couponType = searchParam.get("couponType") || "";

  useEffect(() => {
    setLocalStorage("discountList", "search", search);
  }, [search]);

  //*********Handel status********* */
  const statusUpdateHandler = (id, status, couponCodeType = "") => {
    if (buttonKey === "coupon") {
      setCouponCodeType(couponCodeType);
    }

    setStatusModal(true);
    setMembershipDiscountId(id);
    setStatus(!status);
  };

  const couponTypeHandler = (value) => {
    if (couponType === value) {
      searchParam.delete("couponType");
    } else {
      searchParam.set("couponType", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  //   ********Edit data*************//
  const editHandler = (data) => {
    // console.log(data, "Data");
    if (buttonKey === "coupon") {
      const { couponEndDate, couponStartDate, ...rest } = data;
      setSelectedData({
        ...rest,
        couponEndDate: couponEndDate ? new Date(couponEndDate) : "",
        couponStartDate: couponStartDate ? new Date(couponStartDate) : "",
      });
      setEditCouponModal(true);
    } else if (buttonKey === "recharge") {
      setSelectedData(data);
      setEditRechargeModal(true);
    } else {
      setSelectedData(data);
      setEditMembershipModal(true);
    }
  };

  const discountTypeHandler = (type) => {
    searchParam.set("type", type);
    searchParam.set("page", 1);
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  //------------------Refer-Listing-Api----------------------------------
  const fetchDiscountHandler = async () => {
    try {
      const sendData = {};
      setDiscountList([]);
      showLoader();
      //   sendData.limit = "1";
      sendData.page = activePage;
      sendData.type = buttonKey;
      setSearchParam(searchParam);

      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      if (couponType) {
        sendData.couponType = couponType;
      }
      const res = await fetchDiscountApi(sendData);
      if (res.data.code === 200) {
        // console.log(res?.data?.data, "Data");
        setDiscountList(res?.data?.result?.list || []);
        setTotal(res?.data?.result?.total || 0);
      } else {
        setDiscountList([]);
        setTotal(0);
        toastMessage(res.data.message, "error", "discountList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (Number(activePage === 1)) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    if (!couponType) {
      searchParam.delete("couponType");
    }

    fetchDiscountHandler(activePage);
  }, [searchParam, debounceValue]);

  useEffect(() => {
    fetchDiscountHandler();
    setSearch("");
  }, [buttonKey]);



  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Discount Management</h4>
            <div className="block_user_button">
              {buttonKey === "coupon" && (
                <button onClick={() => setCouponModal(true)}>
                  <AddIcon />
                  Add Coupons
                </button>
              )}
              {buttonKey === "membership" && discountList.length < 2 && (
                <button onClick={() => setAddMembershipModal(true)}>
                  <AddIcon />
                  Add Membership
                </button>
              )}
              {buttonKey === "recharge" && (
                <button onClick={() => setRechargeModal(true)}>
                  <AddIcon />
                  Recharge Option
                </button>
              )}
            </div>
          </div>
          {/* <div className="group_filter_value"></div> */}
          <div className="banner_wrapper">
            <div className="FilterBox FilterArea ">
              {buttonKey !== "membership" && (
                <div className="form-group search_lg_size userSearch">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={search || ""}
                    style={{ "--color": "#FF8989" }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="user_search_icon">{common.SEARCH_ICON}</span>
                </div>
              )}
              <div className="group_filter_value">
                {buttonKey === "coupon" && (
                  <div className="form-group select_sm_width">
                    <Select
                      className={`form-control ${couponType && "active"}`}
                      value={couponType || ""}
                      onChange={(e) => couponTypeHandler(e.target.value)}
                    >
                      <option value="">Select Type</option>
                      <option value="COUPON">Coupon</option>
                      <option value="PROMOCODE">Promocode</option>
                    </Select>
                  </div>
                )}
              </div>
            </div>

            <div className="banner_button_wrapper">
              <button
                className={`_button ${
                  buttonKey === "membership" && "banner_active"
                }`}
                onClick={() => discountTypeHandler("membership")}
              >
                Membership
              </button>
              <button
                className={`_button ${
                  buttonKey === "coupon" && "banner_active"
                }`}
                onClick={() => discountTypeHandler("coupon")}
              >
                Coupons
              </button>
              <button
                className={`_button ${
                  buttonKey === "recharge" && "banner_active"
                }`}
                onClick={() => discountTypeHandler("recharge")}
              >
                Recharge Option
              </button>
            </div>
          </div>

          {/* Recharge */}
          {buttonKey === "recharge" && (
            <>
              <div className="content-table">
                <table>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Name</th>
                      <th>Recharge Amount</th>
                      <th>Recharge Coins</th>
                      <th>Recharge Discount</th>
                      <th className="text-center"> Action </th>
                    </tr>
                  </thead>

                  <tbody>
                    {discountList.length > 0 ? (
                      discountList.map((recharge, index) => {
                        return (
                          <React.Fragment key={recharge._id}>
                            <tr>
                              <td>{findSerialNumber(index, activePage, 10)}</td>
                              <td>{recharge?.title || "---"}</td>
                              <td>{recharge?.rechargeAmount || "---"}</td>
                              <td>{recharge?.rechargeCoins || "---"}</td>
                              <td>{recharge?.rechargeDiscount || "---"}</td>
                              <td>
                                <div className="action_button">
                                  <IconButton
                                    aria-label="update"
                                    className="px-2 py-0"
                                    onClick={() => editHandler(recharge)}
                                  >
                                    <EditIcon className="edit_icon" />
                                  </IconButton>
                                  <Switch
                                    checked={recharge.status}
                                    onChange={() =>
                                      statusUpdateHandler(
                                        recharge?._id,
                                        recharge?.status
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <NotFound
                        cols={7}
                        msg="Discount not found!"
                        icon={NOT_FOUND}
                        loader={loader}
                      />
                    )}
                  </tbody>
                </table>
              </div>

              {total >= 10 ? (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              ) : null}
            </>
          )}

          {/* Coupon */}
          {buttonKey === "coupon" && (
            <>
              <div className="content-table">
                <table>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Coupon Start Date</th>
                      <th>Coupon End from</th>
                      <th>Coupon Title</th>
                      <th>Coupon Image</th>
                      <th>Coupon Discount</th>
                      <th>Type</th>
                      <th className="text-center"> Action </th>
                    </tr>
                  </thead>

                  <tbody>
                    {discountList.length > 0 ? (
                      discountList.map((coupon, index) => {
                        return (
                          <React.Fragment key={coupon._id}>
                            <tr>
                              <td>{findSerialNumber(index, activePage, 10)}</td>
                              <td>
                                {dateFormat(coupon?.couponStartDate) || "---"}
                              </td>
                              <td>
                                {dateFormat(coupon?.couponEndDate) || "---"}
                              </td>
                              <td>{coupon?.title || "---"}</td>
                              <td>
                                <Avatar src={coupon?.image || ""} />
                              </td>
                              <td>{coupon?.couponDiscount || "---"}</td>
                              <td>{coupon?.couponType || "---"}</td>
                              <td>
                                <div className="action_button">
                                  <IconButton
                                    aria-label="update"
                                    className="px-2 py-0"
                                    onClick={() => editHandler(coupon)}
                                  >
                                    <EditIcon className="edit_icon" />
                                  </IconButton>
                                  <Switch
                                    checked={coupon.status}
                                    onChange={() =>
                                      statusUpdateHandler(
                                        coupon?._id,
                                        coupon?.status,
                                        coupon?.couponType
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <NotFound
                        cols={7}
                        msg="Discount not found!"
                        icon={NOT_FOUND}
                        loader={loader}
                      />
                    )}
                  </tbody>
                </table>
              </div>
              {total >= 10 ? (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              ) : null}
            </>
          )}

          {/* Membership */}
          {buttonKey === "membership" && (
            <div className="row my-4">
              {discountList && discountList.length > 0
                ? discountList.map((membershipData, index) => {
                    return (
                      <div className="card col-4 mb-3 mx-1 card-with-shadow position-relative">
                        <img
                          className="card-img-top fixed-image" // Added class fixed-image
                          src={membershipData.image}
                          alt="Membership"
                        />
                        <div className="card-body">
                          <h5 className="card-title text-capitalize">
                            {membershipData.title}
                          </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: membershipData?.details,
                            }}
                          />
                          <p className="card-text">
                            <strong>Membership Price:</strong> INR.
                            {membershipData.membershipPrice}
                          </p>
                          <p className="card-text">
                            <strong>Discounted Price:</strong> INR.
                            {membershipData.membershipDiscountPrice}
                          </p>
                          <p className="card-text">
                            <strong>Status:</strong>{" "}
                            {membershipData.status ? "Active" : "Inactive"}
                          </p>
                          <p className="card-text">
                            <small className="text-muted">
                              Created At:{" "}
                              {new Date(
                                membershipData.createdAt
                              ).toLocaleDateString()}
                            </small>
                          </p>
                        </div>
                        <div className="card-actions position-absolute top-0 end-0 p-2 d-flex align-items-center">
                          <EditIcon
                            className="me-2"
                            onClick={() => {
                              editHandler(membershipData);
                            }}
                          />
                          {/* <Switch
                            checked={membershipData.status}
                            onChange={() =>
                              statusUpdateHandler(
                                membershipData._id,
                                membershipData.status
                              )
                            }
                          /> */}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
        </div>

        {/* Status Component */}
        {statusModal && (
          <DiscountStatus
            statusModal={statusModal}
            setStatusModal={setStatusModal}
            status={status}
            setStatus={setStatus}
            discountList={discountList}
            setDiscountList={setDiscountList}
            setMembershipDiscountId={setMembershipDiscountId}
            membershipDiscountId={membershipDiscountId}
            buttonKey={buttonKey}
            couponType={couponCodeType}
          />
        )}

        {/* Add Membership */}
        <AddMembership
          addMembershipModal={addMembershipModal}
          setAddMembershipModal={setAddMembershipModal}
          fetchDiscountHandler={fetchDiscountHandler}
          buttonType={buttonKey}
        />

        {/* Coupon Modal */}
        <AddCoupon
          couponModal={couponModal}
          setCouponModal={setCouponModal}
          fetchDiscountHandler={fetchDiscountHandler}
          buttonType={buttonKey}
        />
        {/* Recharge Modal */}
        <AddRecharge
          rechargeModal={rechargeModal}
          setRechargeModal={setRechargeModal}
          fetchDiscountHandler={fetchDiscountHandler}
          buttonType={buttonKey}
        />

        {/* Edit Membership */}
        <EditMembership
          editMembershipModal={editMembershipModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setEditMembershipModal={setEditMembershipModal}
          fetchDiscountHandler={fetchDiscountHandler}
          buttonType={buttonKey}
        />

        {/* Edit Coupon */}
        <EditCoupon
          editCouponModal={editCouponModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setDiscountList={setDiscountList}
          setEditCouponModal={setEditCouponModal}
          fetchDiscountHandler={fetchDiscountHandler}
          buttonType={buttonKey}
        />

        {/* Edit Recharge */}
        <EditRecharge
          editRechargeModal={editRechargeModal}
          setEditRechargeModal={setEditRechargeModal}
          setDiscountList={setDiscountList}
          fetchDiscountHandler={fetchDiscountHandler}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          buttonType={buttonKey}
        />
      </div>
    </>
  );
};

export default DiscountManagement;
