import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
// import useFullPageLoader from "../../hooks/useFullPageLoader";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
// import Switch from "../../components/Switch/Switch";
// import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { category } from "../../routes/route";
// import common from "../../../routes/common";
// import AddCategory from "./AddCategory";
// import { fatchCategoryApi } from "../../services/categoryManagement";
import {
  changeMediaCategoryStatus,
  fatchCategoryApi,
  fetchCategoryMediaApi,
} from "../../../services/categoryManagement";
// import { toastMessage } from "../../utils/toastMessage";
// import findSerialNumber from "../../utils/findSerialNumber";
// import { charLimit } from "../../utils/charLimit";
// import CategoryStatus from "./CategoryStatus";
import AddIcon from "@mui/icons-material/Add";
import { getLocalStorage, setLocalStorage } from "../../../config/config";
import findSerialNumber from "../../../utils/findSerialNumber";
import { toastMessage } from "../../../utils/toastMessage";
import { charLimit } from "../../../utils/charLimit";
import AddMedia from "./AddMedia";
import common from "../../../routes/common";
import { category } from "../../../routes/route";
import NotFound from "../../../components/not-found/NotFound";
import videoLogo from "../../../Assests/image/videoLogo.png";
import { MdLibraryMusic } from "react-icons/md";
import EditIcon from "@mui/icons-material/Edit";
import EditMedia from "./EditMedia";
import Switch from "../../../components/Switch/Switch";
import ModalBox from "../../../components/modal/ModalBox";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import DescriptionModel from "../../../components/imagePreview/DescriptionModel";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

// import { getLocalStorage, setLocalStorage } from "../../config/config";
const { ADD_MEDIA } = category;
const { NOT_FOUND } = common;

const MediaList = () => {
  const { id } = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [search, setSearch] = useState(
    getLocalStorage("categoryMedia", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [addCategoryMediaModal, setAddCategoryMediaModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedMediaDetail, setSelectedMediaDetail] = useState({});
  const [editMediaModal, setEditMediaModal] = useState(false);
  const [type, setType] = useState("");
  const [mediaId, setMediaId] = useState(null);
  const [buttonref, buttonLoader] = useButtonLoader("Yes");
  const [mediaStatus, setMediaStatus] = useState(null);
  const [descriptionModel, setDescriptionModel] = useState(false);
  const [description, setDescription] = useState("");

  // update media category handler
  const updateMediaCategoryHandler = (data) => {
    setSelectedMediaDetail(data || null);
    setEditMediaModal(true);
    setType(data?.type);
  };

  useEffect(() => {
    setLocalStorage("categoryMedia", "search", search);
  }, [search]);

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
  // //------------------listing-Category-Api----------------------------------
  const fetchCategoryMediaHandler = async () => {
    try {
      const reqData = {};
      reqData.page = activePage;
      reqData.limit = 10;
      reqData.categoryId = id;
      if (debounceValue) {
        reqData.page = 1;
        reqData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setCategoryList([]);
      showLoader();
      const { data } = await fetchCategoryMediaApi(reqData);
      if (data.code === 200) {
        setCategoryList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
      } else {
        setCategoryList([]);
        setTotal(0);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //   Status Handler
  const changeStatus = async () => {
    try {
      buttonLoader(true);
      let data = {
        status: !mediaStatus,
        categoryId: id,
        type: "media",
        categoryMediaId: mediaId,
      };
      const res = await changeMediaCategoryStatus(data);
      if (res.data.code === 200) {
        fetchCategoryMediaHandler();
        setStatusModal(false);
        setMediaId(null);
        setMediaStatus(null);
        toastMessage(res.data.message, "success", "mediaCategoryStatus");
      } else {
        toastMessage(res.data.message, "error", "mediaCategoryStatus");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchCategoryMediaHandler();
  }, [searchParam, debounceValue]);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
          <Link className="back_icon" to={-3}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
            <h4 className="Title">Expertise Media</h4>
            </div>
            <div className="block_user_button">
              <button onClick={() => setAddCategoryMediaModal(true)}>
                <AddIcon />
                Add Media
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Media Description</th>
                  <th>Media Type</th>
                  <th>Media</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {categoryList.length > 0 ? (
                  categoryList.map((category, index) => {
                    return (
                      <React.Fragment key={category._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          {/* <td>
                            {charLimit(category.description, 40) || "---"}
                          </td> */}
                          <td
                            onClick={() => {
                              if (
                                category.description &&
                                category.description.length > 50
                              ) {
                                setDescriptionModel(true);
                                setDescription(category.description);
                              }
                            }}
                          >
                            {category?.description &&
                              (category.description.length > 50
                                ? charLimit(category.description, 50)
                                : category.description || "---")}
                          </td>
                          <td>{category?.type || "---"}</td>
                          <td className="music_icon">
                            {category && (
                              <>
                                {category?.type === "music" && (
                                  <MdLibraryMusic />
                                )}
                                {category?.type === "video" && (
                                  <Avatar
                                    alt={category.description}
                                    src={videoLogo}
                                    sx={{
                                      width: 50,
                                      height: 50,
                                    }}
                                  />
                                )}
                                {category?.type === "image" && (
                                  <Avatar
                                    alt={category.description}
                                    src={category.categoryMedia}
                                    sx={{
                                      width: 50,
                                      height: 50,
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            <div className="action_button">
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateMediaCategoryHandler(category)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={category.status}
                                onChange={() => {
                                  setMediaId(category._id);
                                  setStatusModal(true);
                                  setMediaStatus(category?.status);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Media not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
        {addCategoryMediaModal && (
          <AddMedia
            show={addCategoryMediaModal}
            close={() => setAddCategoryMediaModal(false)}
            listing={fetchCategoryMediaHandler}
            categoryId={id}
            type={type}
            setType={setType}
          />
        )}
        {editMediaModal && (
          <EditMedia
            show={editMediaModal}
            close={() => setEditMediaModal(false)}
            data={selectedMediaDetail}
            listing={fetchCategoryMediaHandler}
            setData={setSelectedMediaDetail}
            type={type}
            setType={setType}
          />
        )}

        <ModalBox
          status={statusModal}
          onClose={() => setStatusModal(false)}
          title="Change media category Status"
          description="Do you want to change the status of this media?"
          action={changeStatus}
          ref={buttonref}
        />

        {descriptionModel && (
          <DescriptionModel
            show={descriptionModel}
            close={() => {
              setDescriptionModel(false);
            }}
            description={description}
            title={"Description"}
          />
        )}
      </div>
    </>
  );
};

export default MediaList;
