import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category, concern, music } from "../../routes/route";
import common from "../../routes/common";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchMusicApi } from "../../services/musicManagement";
import EditIcon from "@mui/icons-material/Edit";
// import AddMusic from "./AddMusicCategory";
import showFullSizeImage from "../../utils/showFullSizeImage";
// import MusicCategoryStatus from "./MusicCategoryStatus";
// import EditMusicCategory from "./EditMusicCategory";
import scrollToUp from "../../utils/scrollToUp";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import ImagePreviewModel from "../../components/imagePreview/ImagePreviewModel";
import AddIcon from '@mui/icons-material/Add';
import { fetchConcernApi } from "../../services/concern/Index";
import AddConcern from "./AddConcern";
import ConcernStatus from "./ConcernStatus";
import EditConcern from "./EditConcern";
const { ADD_MUSIC } = category;
const { NOT_FOUND } = common;
const { CONCERN_DETAIL } = concern;

const ConcernManagement = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  const [search, setSearch] = useState(
    getLocalStorage("concern", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  const [addConcernModal, setAddConcernModal] = useState(false);
  const [editConcernModal, setEditConcernModal] = useState(false);
  const [concernList, setConcernList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedConcernDetail, setSelectedConcernDetail] =
    useState(null);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [concernTitle, setConcernTitle] = useState("");

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage("concern", "search", search);
  }, [search]);
  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  // update music category handler
  const updateConcernHandler = (data) => {
    setSelectedConcernDetail(data || null);
    setEditConcernModal(true);
  };

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };


  // close model

  const closeModel = () => {
    setSelectedConcernDetail(null);
    setStatusValue("");
    setStatusId("");
    setAddConcernModal(false);
    setStatusModal(false);
    setEditConcernModal(false);
    setImagePreview("");
    setShowImagePreviewModel(false);
  };

  // //------------------listing-Concern-Api----------------------------------
  const fetchConcernList = async () => {
    try {
      setConcernList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
      };
      if (debounceValue) {
        sendData.page = 1;
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchConcernApi(sendData);
      if (data.code === 200) {
        setConcernList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setConcernList([]);
        setTotal(0);
        toastMessage(data.message, "error", "concernList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

//   useEffect(() => {
//     if (debounceValue) {
//       searchParam.set('query', debounceValue)
//     } else {
//       searchParam.delete('query')
//     }
//     if (activePage === 1) {
//       searchParam.delete('page')
//     }
//     setSearchParam(searchParam)
//     fetchConcernList(activePage, debounceValue)
//   }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchConcernList();
  }, [searchParam, debounceValue]);
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Concern & Remedy Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddConcernModal(true)}>
                <AddIcon/>Add Concern
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Concern Title</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {concernList.length > 0 ? (
                  concernList.map((concern, index) => {
                    return (
                      <React.Fragment key={concern._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{charLimit(concern.title, 30) || "---"}</td>
                          <td>
                            <div className="action_button">
                              <Link
                                to={`${CONCERN_DETAIL}/${concern.title}/${concern._id}`}
                              >
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateConcernHandler(concern)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={concern.status}
                                onChange={() =>
                                  statusUpdateHandler(concern._id, concern.status)
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={3}
                    msg="Concern not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

        </div>
        {addConcernModal && (
          <AddConcern
            show={addConcernModal}
            close={closeModel}
            listing={fetchConcernList}
          />
        )}
        {statusModal && (
          <ConcernStatus
            show={statusModal}
            close={closeModel}
            setListing={setConcernList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editConcernModal && (
          <EditConcern
            show={editConcernModal}
            close={closeModel}
            concernDetail={selectedConcernDetail}
            setConcernDetail={setSelectedConcernDetail}
            listing={fetchConcernList}
          />
        )}
      </div>
    </>
  );
};

export default ConcernManagement;
