/** @format */

import React from "react";
import ErrorImg from "../../css/404.png";
import { Button } from "../Button/Button";

const Error = () => {
  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox justify-content-center ">
          <div className="error_container ">
            <div className="errorImg">
              <img src={ErrorImg} alt="not found" loading="lazy" />
            </div>
            <h5>Page Not Found</h5>
            <Button to="/dashboard">Go to home</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
