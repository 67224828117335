import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { allowAlphaNumericWithSpace, allowNumber } from "../../utils/regex";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { updateOfferApi } from "../../services/offersManagement";

const EditOffer = ({ show, close, offerDetail, setOfferDetail, listing }) => {
  // handle all state

  const [error, setError] = useState({});
  const [updateOffer, setUpdateOffer] = useState({
    offerId: offerDetail?._id,
    title: offerDetail?.title,
    userType:offerDetail?.userType,
    couponDiscount:offerDetail?.couponDiscount,

  });

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Update");

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!updateOffer?.title) {
      errorMessage["title"] = "Offer Data is mandatory";
      status = false;
    }
    if (!updateOffer?.couponDiscount) {
      errorMessage["couponDiscount"] = "Percentage is mandatory";
      status = false;
    }
    if (!updateOffer?.userType) {
      errorMessage["userType"] = "User Type is mandatory";
      status = false;
    }
    setError(errorMessage);
    return status;
  };

  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "couponDiscount") {
      updatedValue = allowNumber(value);
    }
    setUpdateOffer({ ...updateOffer, [name]: updatedValue });
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setOfferDetail({});
    setError("");
    close();
  };
  //--------------Update-music-category------------------------
  const updateOfferHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const { data } = await updateOfferApi(updateOffer);
        if (data.code === 200) {
          toastMessage(data.message, "success", "updateOffer");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "updateOffer");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Edit Offer"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateOfferHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateOffer?.title || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Concern Title"
            className={`form-control `}
            label={"Title"}
            error={!updateOffer.title && error.title}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="couponDiscount"
            value={updateOffer?.couponDiscount || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Percentage"
            className={`form-control `}
            label={"Percentage"}
            error={!updateOffer.couponDiscount && error.couponDiscount}
          />
        </div>
        <div className="form-group mb-2">
          <Select
            name="userType"
            value={updateOffer.userType}
            required
            onChange={(e) => {
              setUpdateOffer({
                ...updateOffer,
                userType: e.target.value,
              });
            }}
            // placeholder
            className={`form-control`}
            label={"User Type"}
            error={!updateOffer.userType && error.userType}
            maxLength={40}
          >
            <option value="">Select User Type</option>
            <option value="NEWUSERS">New User</option>
            <option value="LOYALUSERS">Loyal User</option>
          </Select>
        </div>
      </div>
    </ModalBox>
  );
};

export default EditOffer;
