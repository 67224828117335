import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeMusicStatusCategory } from '../../services/musicManagement'

const MusicCategoryStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Add')

  //--------------change-status-sub-category------------------------
  const changeMusicCategoryStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, musicId: id }
      const { data } = await changeMusicStatusCategory(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((category) => {
            if (category._id === id) {
              return { ...category, status: !status }
            }
            return category
          })
        })
        toastMessage(data.message, 'success', 'musicCategoryStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'musicCategoryStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Music Category Status"
      description="Do you want to change the status of this music category?"
      action={changeMusicCategoryStatusHandler}
      ref={buttonref}
    />
  )
}

export default MusicCategoryStatus
