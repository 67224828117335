import React, { useEffect, useState } from 'react'
import { Link, useSearchParams, useParams } from 'react-router-dom'
import useDebounce from '../../../hooks/useDebounce'
import useFullPageLoader from '../../../hooks/useFullPageLoader'
import { Avatar, IconButton } from '@mui/material'
import Switch from '../../../components/Switch/Switch'
import NotFound from '../../../components/not-found/NotFound'
import Pagination from 'react-js-pagination'
import { meditation } from '../../../routes/route'
import common from '../../../routes/common'
import { toastMessage } from '../../../utils/toastMessage'
import findSerialNumber from '../../../utils/findSerialNumber'
import { charLimit } from '../../../utils/charLimit'
import EditIcon from '@mui/icons-material/Edit'
import showFullSizeImage from '../../../utils/showFullSizeImage'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import AddMeditation from './AddMeditation'
import scrollToUp from '../../../utils/scrollToUp'
import { fetchMeditationListHandler } from '../../../services/meditationManagement'
import MeditationStatus from './MeditationStatus'
import EditMeditation from './EditMeditation'
import PlayMusicMeditationModel from './PlayMusicMeditation'
import PlayVideoMeditation from './PlayVideoMeditation'
import { getLocalStorage, setLocalStorage } from '../../../config/config'
import { MdLibraryMusic, MdVideoLibrary } from 'react-icons/md'
import ImagePreviewModel from '../../../components/imagePreview/ImagePreviewModel'
import { FaImages } from 'react-icons/fa'
import AddIcon from '@mui/icons-material/Add';

const { NOT_FOUND } = common
const { MEDITATION_LIST, MEDITATION_DETAIL } = meditation

const MeditationList = () => {
  const { meditationCategory, id } = useParams()

  // handle all state
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  const [search, setSearch] = useState(
    getLocalStorage('meditationList', 'search') || ''
  )
  const debounceValue = useDebounce(search || '', 300)

  const [addMeditationModal, setAddMeditationModal] = useState(false)
  const [editMeditationModel, setEditMeditationModel] = useState(false)
  const [meditationList, setMeditationList] = useState([])
  const [total, setTotal] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [statusModal, setStatusModal] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const [statusId, setStatusId] = useState('')
  const [selectedMeditationDetail, setSelectedMeditationDetail] = useState(null)
  const [playMusicMeditationModel, setMusicPlayMeditationModel] =
    useState(false)
  const [playVideoMeditationModel, setVideoPlayMeditationModel] =
    useState(false)

  const [playMantraMeditationModel, setMantraPlayMeditationModel] =
    useState(false)
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [imageTitle, setImageTitle] = useState('')

  useEffect(() => {
    setLocalStorage('meditationlist', 'search', search)
  }, [search])

  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true)
    setStatusId(id)
    setStatusValue(status)
  }

  // update music category handler
  const updateMeditationHandler = (data) => {
    setSelectedMeditationDetail(data || null)
    setEditMeditationModel(true)
  }

  // Play music handler
  const playMantraMeditationHandler = (data) => {
    setSelectedMeditationDetail(data)
    setMantraPlayMeditationModel(true)
  }
  const playMusicMeditationHandler = (data) => {
    setSelectedMeditationDetail(data)
    setMusicPlayMeditationModel(true)
  }
  const playVideoMeditationHandler = (data) => {
    setSelectedMeditationDetail(data)
    setVideoPlayMeditationModel(true)
  }

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set('page', event)
    setSearchParam(searchParam)
  }

  const showImagePreviewHandler = (url, title) => {
    setShowImagePreviewModel(true)
    setImagePreview(url)
    setImageTitle(title)
  }

  // close model

  const closeModel = () => {
    setSelectedMeditationDetail(null)
    setStatusValue('')
    setStatusId('')
    setAddMeditationModal(false)
    setStatusModal(false)
    setEditMeditationModel(false)
    setMusicPlayMeditationModel(false)
    setMantraPlayMeditationModel(false)
    setShowImagePreviewModel(false)
  }

  // //------------------listing-music-Api----------------------------------
  const fetchMeditationList = async (debounceValue) => {
    try {
      setMeditationList([])
      showLoader()
      const sendData = {
        meditationCategoryId: id,
        page: activePage,
        limit: 5,
      }

      if (debounceValue) {
        sendData.page = 1
        sendData.search = debounceValue
        searchParam.delete('page')
        setSearchParam(searchParam)
      }
      const { data } = await fetchMeditationListHandler(sendData)
      if (data.code === 200) {
        setMeditationList(data?.result?.list || [])
        setTotal(data?.result?.total || 0)
        scrollToUp()
      } else {
        setMeditationList([])
        setTotal(0)
        toastMessage(data.message, 'error', 'meditationListHandler')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      hideLoader()
    }
  }

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   fetchCategoryHandler(activePage, debounceValue)
  // }, [searchParam, debounceValue])
  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete('page')
    }
    setSearchParam(searchParam)
    fetchMeditationList(debounceValue)
  }, [searchParam, debounceValue])

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              <Link className="back_icon" to={-3}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">{meditationCategory || ''}</h4>
            </div>
            <div className="block_user_button">
              <button onClick={() => setAddMeditationModal(true)}>
                <AddIcon/>Add Meditation
              </button>
            </div>
          </div>

          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ''}
                style={{ '--color': '#FF8989' }}
                onChange={(e) => setSearch(e.target.value)}
                // disabled={meditationList.length === 0}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  {/* <th>Music Id</th> */}
                  <th>Meditation Name</th>
                  <th>Meditation Image / GIF</th>
                  {/* <th>Meditation Image</th> */}
                  <th>Meditation Mantra</th>
                  <th>Meditation Music</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {meditationList.length > 0 ? (
                  meditationList.map((meditation, index) => {
                    return (
                      <React.Fragment key={meditation._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 5)}</td>
                          <td>{charLimit(meditation.title, 30) || '---'}</td>
                          <td>
                            <Avatar
                              id={meditation._id}
                              alt={meditation.title}
                              src={meditation.meditationVideo}
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: 'pointer',
                                borderRadius: 2,
                              }}
                              onClick={() =>
                                showImagePreviewHandler(
                                  meditation?.meditationVideo,
                                  meditation?.title
                                )
                              }
                            />
                          </td>
                          {/* <td>
                            <Avatar
                              id={meditation._id}
                              alt={meditation.title}
                              src={meditation.meditationImage}
                              sx={{
                                width: 50,
                                height: 50,
                                cursor: 'pointer',
                                borderRadius: 2,
                              }}
                              onClick={() =>
                                showImagePreviewHandler(
                                  meditation?.meditationImage,
                                  meditation?.title
                                )
                              }
                            />
                          </td> */}

                          <td
                            className="music_icon"
                            onClick={() =>
                              playMantraMeditationHandler(meditation)
                            }
                          >
                            {/* {meditation.mantra || '---'} */}
                            <MdLibraryMusic />
                          </td>
                          <td
                            className="music_icon"
                            onClick={() =>
                              playMusicMeditationHandler(meditation)
                            }
                          >
                            {/* {meditation.mantra || '---'} */}
                            <MdLibraryMusic />
                          </td>

                          <td>
                            <div className="action_button">
                              {/* <Link to={`${MUSIC_DETAIL}/${music._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link> */}
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0"
                                onClick={() =>
                                  updateMeditationHandler(meditation)
                                }
                              >
                                <EditIcon className="edit_icon" />
                              </IconButton>
                              <Switch
                                checked={meditation.status}
                                onChange={() =>
                                  statusUpdateHandler(
                                    meditation._id,
                                    meditation.status
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Meditation not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 5 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  itemsCountPerPage={5}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addMeditationModal && (
          <AddMeditation
            show={addMeditationModal}
            close={closeModel}
            listing={fetchMeditationList}
            meditationId={id}
          />
        )}
        {statusModal && (
          <MeditationStatus
            show={statusModal}
            close={closeModel}
            setListing={setMeditationList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editMeditationModel && (
          <EditMeditation
            show={editMeditationModel}
            close={closeModel}
            meditationDetail={selectedMeditationDetail}
            setMeditationDetail={setSelectedMeditationDetail}
            meditationListing={fetchMeditationList}
            meditationCategoryId={id}
          />
        )}
        {playMusicMeditationModel && (
          <PlayMusicMeditationModel
            show={playMusicMeditationModel}
            close={closeModel}
            musicUrl={selectedMeditationDetail?.meditationMusic}
            musicTitle={selectedMeditationDetail?.title}
            setMeditationDetail={setSelectedMeditationDetail}
          />
        )}
        {playMantraMeditationModel && (
          <PlayMusicMeditationModel
            show={playMantraMeditationModel}
            close={closeModel}
            musicUrl={selectedMeditationDetail?.meditationMantra}
            musicTitle={selectedMeditationDetail?.title}
            setMeditationDetail={setSelectedMeditationDetail}
          />
        )}
        {/* {playVideoMeditationModel && (
          <ImagePreviewModel
            show={playVideoMeditationModel}
            close={closeModel}
            imageUrl={selectedMeditationDetail.meditationVideo}
            title={selectedMeditationDetail.title}
          />
        )} */}
        {showImagePreviewModel && (
          <ImagePreviewModel
            show={showImagePreviewModel}
            close={closeModel}
            imageUrl={imagePreview}
            title={imageTitle}
          />
        )}
      </div>
    </>
  )
}

export default MeditationList
