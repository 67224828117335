import React, { useState } from 'react';

export const useOTPModalCNF = () => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    return { open, close, OTPModalCNF: (props) => <OTPModalCNF isOpen={isOpen} close={close} {...props} /> };
};

const OTPModalCNF = ({ isOpen, close, verifiedHandle }) => {
    const [step, setStep] = useState(1); // Step 1: Confirm, Step 2: OTP Input
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');

    const handleConfirm = () => {
        setStep(2); // Move to OTP verification step
        setError(''); // Reset any error messages
    };

    const handleVerify = async () => {
        // Simulate OTP verification (Replace with actual API call)
        const response = await mockVerifyOtp(otp);

        if (response.success) {
            verifiedHandle();
            close(); // Close the modal on success
        } else {
            setError('OTP is invalid'); // Show error message
        }
    };

    if (!isOpen) return null;

    return (
        <div style={styles.modal}>
            <div style={styles.modalContent}>
                {step === 1 && (
                    <>
                        <h2>Confirmation</h2>
                        <p>Do you want to send an OTP?</p>
                        <button onClick={handleConfirm}>Send OTP</button>
                        <button onClick={close}>Cancel</button>
                    </>
                )}
                {step === 2 && (
                    <>
                        <h2>Verify OTP</h2>
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <button onClick={handleVerify}>Verify</button>
                        {error && <p style={styles.error}>{error}</p>}
                        <button onClick={close}>Cancel</button>
                    </>
                )}
            </div>
        </div>
    );
};

// Mock API call to verify OTP
const mockVerifyOtp = async (otp) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ success: otp === '1234' }); // OTP is "1234" for testing
        }, 1000);
    });
};

// Inline styles
const styles = {
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        width: '300px',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
}; 