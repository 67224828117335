import React from 'react'
import ModalBox from '../../components/modal/ModalBox'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { toastMessage } from '../../utils/toastMessage'
import { changeExploreStatusCategory } from '../../services/exploreManagement'

const ExploreStatus = ({ show, close, status, id, setListing }) => {
  const [buttonref, buttonLoader] = useButtonLoader('Add')

  //--------------change-status-sub-category------------------------
  const changeExploreStatusHandler = async () => {
    try {
      buttonLoader(true)
      let sendData = { status: !status, exploreId: id }
      const { data } = await changeExploreStatusCategory(sendData)
      if (data.code === 200) {
        setListing((prevListing) => {
          return prevListing.map((explore) => {
            if (explore._id === id) {
              return { ...explore, status: !status }
            }
            return explore
          })
        })
        toastMessage(data.message, 'success', 'exploreStatus')
        close()
      } else {
        toastMessage(data.message, 'error', 'exploreStatus')
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      buttonLoader(false)
    }
  }
  console.log('button loader', buttonLoader)
  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Change Explore Status"
      description="Do you want to change the status of this?"
      action={changeExploreStatusHandler}
      ref={buttonref}
    />
  )
}

export default ExploreStatus
