import { Avatar, Chip, IconButton } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useEffect, useState } from "react";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import { dateFormat, dateFormatWithTime } from "../../utils/dateFormat";
import profile from "../../Assests/image/image.png";
import CheckboxGroup from "../../components/Form";
import { fetchImageDetalListApi } from "../../services/imageManagement";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import scrollToUp from "../../utils/scrollToUp";
import { toastMessage } from "../../utils/toastMessage";
import ImagePreviewModel from "../../components/imagePreview/ImagePreviewModel";
import ApproveMultipleImage from "./ApproveMultipleImage";
import NotFound from "../../components/not-found/NotFound";
import common from "../../routes/common";
const { NOT_FOUND } = common;

const ImageDetail = () => {
  const { imageType, id } = useParams();
  // handle all state

  const [selectedImageDetail, setSelectedImageDetail] = useState("");
  const [buttonStatus,setButtonStatus]=useState(null)
  const [status, setStatus] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [approveImageModel, setApproveImageModel] = useState(false);

  // handle all hook
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const showImagePreviewHandler = (image) => {
    setShowImagePreviewModel(true);
    setImagePreview(image);
  };

  const closeModel = () => {
    setShowImagePreviewModel(false);
    setApproveImageModel(false);
    setImagePreview("");
    setSelectedImage([]);
  };

  const multipleImageSelectHandler = () => {
    if (selectedImage.length === 0) {
      toastMessage("Please select atleast one image", "error", "imageDetail");
      return;
    }
    setApproveImageModel(true);
  };

  const fetchImageDetailListHandler = async (type, consultantId) => {
    try {
      showLoader();
      const sendData = {
        consultantId: consultantId,
        galleryType: type,
      };
      const { data } = await fetchImageDetalListApi(sendData);
      if (data.code === 200) {
        setSelectedImageDetail(data?.result?.list);
        setImageList(data?.result?.list?.gallery);
        setButtonStatus(data?.result?.list?.gallery.some((image,index)=>image.isApprove===false))
        setStatus(data?.result?.status);
        scrollToUp();
      } else {
        setSelectedImageDetail("");
        toastMessage(data.message, "error", "imageDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // console.log(buttonStatus,"ButtonSTatus");

  const handleImageSelectHandler = (e, imageid) => {
    const isImageSelect = e.target.checked;
    setSelectedImage((prevImageId) => {
      if (isImageSelect) {
        return [...prevImageId, imageid];
      } else {
        return prevImageId.filter((image) => image !== imageid);
      }
    });
  };

  useEffect(() => {
    fetchImageDetailListHandler(imageType, id);
  }, [imageType, id]);

  // handle all hooks

  // handle all fucntion
  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              <Link className="back_icon" to={-2}>
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              {/* <h4 className="Title">Image Management / Details</h4> */}
              <h4 className="Title">
                {imageType === "profile"
                  ? "Gallery Image Details"
                  : imageType === "live"
                  ? "Live Image Details"
                  : null}
              </h4>
            </div>
            {(selectedImageDetail && selectedImageDetail?.isApprove) || (
              <div className="block_user_button">
                <button className={`${!buttonStatus?"disabledBtn":""}`} disabled={!buttonStatus}   onClick={multipleImageSelectHandler}>Approve</button>
              </div>
            )}
          </div>
          <div className="row cstm_row_align">
            <div className="col-3">
              <div className="user_image user_icon">
                <figure>
                  <img
                    src={selectedImageDetail?.profileImageImage || profile}
                    alt={selectedImageDetail?.name}
                  />
                </figure>
              </div>
            </div>
            <div className="col-5 user_detail_container">
              <div className="wrap_new_req_cstm">
                <ul className="selected_user_detail">
                  <li>
                    <p>User Name:</p>
                    <p>{selectedImageDetail?.name || "---"}</p>
                  </li>
                  <li>
                    <p>Total Images:</p>
                    <p>{selectedImageDetail?.totalImage || "---"}</p>
                  </li>
                  <li>
                    <p>Date And Time:</p>
                    <p>
                      {selectedImageDetail?.createdAt
                        ? dateFormatWithTime(selectedImageDetail?.createdAt)
                        : "---"}
                    </p>
                  </li>
                  <li>
                    <p>Status</p>
                    <p><span className="status_tab">{status ? "Approved" : "New Request"}</span></p>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-3 text-center mb-4">
             <p className="cstm_status"> {status ? "Approved" : "New Request"}</p>
            </div> */}
            {selectedImageDetail?.status && (
              <div className="col-3 user_detail_cstm_container">
                <Chip label={"New Request"} className="listing_chip" />
              </div>
            )}
          </div>
          <div className="row">
            {imageList &&
              imageList.length > 0 &&
              imageList.map((image) => {
                if (!image.isApprove) {
                  return (
                    <div className="col-3 mt-3" key={image._id}>
                      <div className="approve_image">
                        <figure
                          onClick={() => showImagePreviewHandler(image.image)}
                        >
                          <img src={image.image} alt="" />
                        </figure>

                        <CheckboxGroup
                          name={"image"}
                          checked={selectedImage.includes(image._id)}
                          onChange={(e) =>
                            handleImageSelectHandler(e, image._id)
                          }
                          className={"image-detail-checkbox"}
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          <div className="row mt-3">
            <h5>Approved Image</h5>

            {imageList &&
            imageList.length > 0 &&
            imageList.some((image) => image.isApprove) ? (
              imageList.map((image) => {
                if (image.isApprove) {
                  return (
                    <div className="col-3 mt-3" key={image._id}>
                      <div className="approve_image">
                        <figure
                          onClick={() => showImagePreviewHandler(image.image)}
                        >
                          <img src={image.image} alt="" />
                        </figure>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="w-100">
                <div className="text-center">
                  <div className="mt-3">{NOT_FOUND}</div>
                  <h5 className="mt-3">Approve image not found</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showImagePreviewModel && (
        <ImagePreviewModel
          show={showImagePreviewModel}
          close={closeModel}
          imageUrl={imagePreview}
        />
      )}
      {approveImageModel && (
        <ApproveMultipleImage
          show={approveImageModel}
          close={closeModel}
          imageId={selectedImage}
          // setListing={setImageList}
          fetchImageDetailListHandler={fetchImageDetailListHandler}
          id={id}
          imageType={imageType}
        />
      )}
    </>
  );
};
export default ImageDetail;
