import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { Link, useParams, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import { Select } from "../../components/Form";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { IoSearch } from "react-icons/io5";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";
import { toastMessage } from "../../utils/toastMessage";
import common from "../../routes/common";
import { dateFormat } from "../../utils/dateFormat";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { block_unblock_Api, fetchFormApi } from "../../services/form16A";
import Switch from "../../components/Switch/Switch";
import consultantPerformance from "../../Assests/image/consultantPerformance.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
// import AddForm from "./AddForm";
// import EditForm from "./EditForm";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  deleteDiscountCouponApi,
  discount_block_unblock_Api,
  fetchShopifyDiscountCouponApi,
} from "../../services/shopify";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchSubadminListApi,
  updateSubadminStatus,
} from "../../services/subAdmin";

const { NOT_FOUND } = common;

const SubAdmin = () => {
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const { consultantId } = useParams();

  const [search, setSearch] = useState(
    getLocalStorage("subadminList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [subadminId, setSubadminId] = useState({});
  const [subadminList, setSubadminList] = useState([]);
  const [eventId, setEventId] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const status = searchParam.get("status") || "";

  useEffect(() => {
    setLocalStorage("subadminList", "search", search);
  }, [search]);

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const editHandler = (data) => {
    setSelectedCoupon(data);
    setEditModal(true);
  };
  //------------------request-listing-Api----------------------------------
  const getsubadminList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setSubadminList([]);
      const { data } = await fetchSubadminListApi(sendData);
      if (data.code === 200) {
        setSubadminList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setSubadminList([]);
        setTotal(0);
        toastMessage(data.message, "error", "subadminList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Change Form Status Handler
  const changeFormStatusHandler = async () => {
    try {
      buttonLoader(true);
      const { subAdminId, status } = subadminId;
      const newStatus = Boolean(status);

      const res = await updateSubadminStatus({
        subAdminId: subAdminId,
        status: newStatus,
      });
      if (res?.data?.code === 200) {
        toastMessage(
          res?.data?.message,
          "success",
          "blockUnblockShopifyDiscount"
        );
        setStatusModal(false);
        setSubadminId({});
        getsubadminList();
      } else {
        toastMessage(
          res?.data?.message,
          "error",
          "blockUnblockShopifyDiscount"
        );
        setSubadminId({});
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  // Delete Discount Coupon
  const deleteCouponHandler = async () => {
    try {
      buttonLoader(true);
      const res = await deleteDiscountCouponApi({
        discountsubadminId: subadminId.discountsubadminId,
      });
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "discountsubadminId");
        setSubadminId("");
        setDeleteModal(false);
        getsubadminList();
      } else {
        toastMessage(res?.data?.message, "error", "discountsubadminId");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!status) {
      searchParam.delete("status");
    }
    setSearchParam(searchParam);
    getsubadminList(debounceValue);
  }, [searchParam, debounceValue]);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              <Link className="back_icon" to="#">
                <IconButton>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              </Link>
              <h4 className="Title">Sub-Admin Management</h4>
            </div>
            {/* <h4 className="Title"></h4> */}
            <div className="block_user_button">
              <Link className=".block_user_button button" to="/subadmin/add">
                <div className="block_user_button">
                  <button>
                    <AddIcon />
                    Add Sub-Admin
                  </button>
                </div>
              </Link>
            </div>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th className="text-center"> Action </th>
                </tr>
              </thead>
              <tbody>
                {subadminList.length > 0 ? (
                  subadminList.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td className="user_profile_image">
                            {data?.name || "---"}
                          </td>
                          <td>{data?.email}</td>
                          <td>
                            {/* {form?.status === 1 && ( */}
                            <div className="action_button">
                              <Link to={`/subadmin/edit/${data?._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <EditIcon className="edit_icon" />
                                </IconButton>
                              </Link>
                              <Link to={`/subadmin/view/${data?._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                              <Switch
                                checked={data.status}
                                onChange={() => {
                                  setStatusModal(true);
                                  setSubadminId({
                                    subAdminId: data._id,
                                    status: !data.status,
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* Status Modal */}
      <ModalBox
        status={statusModal}
        onClose={() => {
          setStatusModal(false);
          setSubadminId({});
        }}
        title={"Change Status"}
        description={"Are you sure want to change status of this sub-admin?"}
        action={changeFormStatusHandler}
        ref={buttonRef}
      />

      {/* <ModalBox
        status={deleteModal}
        onClose={() => setDeleteModal(false)}
        title={"Delete Coupon Discount"}
        description={"Are you sure want to delete this coupon discount?"}
        action={deleteCouponHandler}
        ref={buttonRef}
      /> */}
    </>
  );
};

export default SubAdmin;
