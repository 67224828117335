import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fetch-Query-listing--------------------------
export const fetchQueryListApi = async (data) => {
  const endPoint =
    `/admin/support/query` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// ---------------------fetch-Query--------------------------
export const fetchQueryApi = async (id) => {
  const endPoint = `/admin/support/chat?customerSupportQueryId=${id}&limit=1000`;

  return await axios.get(endPoint, setJwtToken());
};

// ---------------------fetch-Query-status--------------------------
export const fetchQueryStatusApi = async (id) => {
  const endPoint = `/admin/support/query_status?customerSupportQueryId=${id}`;

  return await axios.get(endPoint, setJwtToken());
};

// // ---------------------send-Query---------------------------
export const sendChatApi = async (data) => {
  const endPoint = `/admin/support/chat_send`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};

// ---------------------fetch-Support-listing--------------------------
export const fetchSupportListApi = async (data) => {
  const endPoint =
    `/admin/support` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// // ---------------------Add-Support---------------------------
export const addSupportApi = async (data) => {
  const endPoint = `/admin/support/add`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};

// // ---------------------update-Support---------------------------
export const updateSupportApi = async (data) => {
  const endPoint = `/admin/support/update`;
  return await axios.put(endPoint, data, setMultiPartHeader());
};

// // ---------------------Delete-Support-API---------------------------
export const deleteSupportApi = async (data) => {
  console.log(data,"Data");
  const endPoint = `/admin/support/delete?supportId=${data}`;
  return await axios.delete(endPoint, setJwtToken());
};
