export const regex = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
  imageFileRegex: /\.(jpg|jpeg|png|bmp)$/i,
  excelFileRegex: /\.(xlsx|xls)$/i,
  documentFileRegex: /\.(pdf)$/i,
  audioFileRegex: /\.(mp3|wav|flac|m4a|aac|ogg)$/i,
  videoFileRegex: /\.(mp4|avi|mov|mkv|wmv|flv|webm)$/i,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/,
  youtubeLinkRegex:
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\w+\/|(?:v|e(?:mbed)?)\/|\w+\/)?|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  extractYoutubeLink:
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  youtubeUrlRegex:
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/,
};

export function optimizeFunction(value) {
  return value.replace(/(^\s+)|([^A-Za-z\s])|(\s+)/g, " ").trimStart();
}

export function allowAlphaNumericWithSpace(value) {
  return value.replace(/[^a-zA-Z0-9\s\u0900-\u097F]/g, "");
}

export function allowAlphaNumeric(value) {
  return value.replace(/[^a-zA-Z0-9]/g, "");
}

export function allowNumber(value) {
  return value.replace(/[^\d]/g, "").trimStart();
}

export function allowFloatingNumber(value) {
  // Allow only digits and optionally a single decimal point
  const cleanedValue = value.replace(/[^\d.]/g, "").trimStart();

  // Ensure only one decimal point exists
  const parts = cleanedValue.split(".");

  // If there's more than one decimal point, keep the first one and remove additional points
  if (parts.length > 2) {
    return `${parts[0]}.${parts[1]}`;
  }

  // Limit to 4 digits after the decimal point
  if (parts[1] && parts[1].length > 4) {
    parts[1] = parts[1].slice(0, 4);
  }

  // Reconstruct and return the valid number
  return parts.join(".");
}

