import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { Input } from "../../components/Form";
import ReactDatePicker from "react-datepicker";
import { dateFormatUtc } from "../../utils/dateFormat";
import {
  addDiscountApi,
  editDiscountApi,
} from "../../services/discountManagement";
import useButtonLoader from "../../hooks/UseButtonLoader";

const EditRecharge = ({
  editRechargeModal,
  setEditRechargeModal,
  setDiscountList,
  fetchDiscountHandler,
  selectedData,
  setSelectedData,
  buttonType,
}) => {
  const [error, setError] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  const modalClose = () => {
    setError({});
    setSelectedData({});
    setEditRechargeModal(false);
  };

  const validation = () => {
    let status = true;
    let error = {};

    // if (!selectedData?.title) {
    //   error["title"] = "Name is required *";
    //   status = false;
    // }

    if (!selectedData?.rechargeAmount) {
      error["rechargeAmount"] = "Amount is required *";
      status = false;
    }
    // if (!selectedData?.rechargeDiscount) {
    //   error["rechargeDiscount"] = "Discount is required *";
    //   status = false;
    // }
    if (!selectedData?.rechargeCoins) {
      error["rechargeCoins"] = "Coin is required *";
      status = false;
    }
    setError(error);
    return status;
  };

  const editRechargeHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("type", buttonType);
        formData.append("membershipDiscountId", selectedData._id);
        formData.append("title", selectedData.title);
        formData.append("rechargeAmount", selectedData.rechargeAmount);
        formData.append("rechargeDiscount", selectedData.rechargeDiscount);
        formData.append("rechargeCoins", selectedData.rechargeCoins);
        const { data } = await editDiscountApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "editRecharge");
          setDiscountList((prev) =>
            prev.map((data) => {
              if (data._id === selectedData._id) {
                return selectedData;
              } else {
                return data;
              }
            })
          );

          modalClose();
        } else {
          toastMessage(data.message, "error", "editRecharge");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={editRechargeModal}
      onClose={modalClose}
      title={"Edit Recharge Option"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={editRechargeHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={selectedData.title || ""}
            // required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedData({
                ...selectedData,
                ["title"]: sanitizedText,
              });
            }}
            placeholder="Name"
            className={`form-control`}
            label={"Name"}
            error={!selectedData.title && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="rechargeAmount"
            value={selectedData.rechargeAmount || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedData({
                ...selectedData,
                ["rechargeAmount"]: sanitizedText,
              });
            }}
            placeholder="Recharge Amount"
            className={`form-control`}
            label={"Amount"}
            error={!selectedData.rechargeAmount && error.rechargeAmount}
            maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="rechargeDiscount"
            value={selectedData.rechargeDiscount || ""}
            // required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedData({
                ...selectedData,
                ["rechargeDiscount"]: sanitizedText,
              });
            }}
            placeholder="Discount"
            className={`form-control`}
            label={"Discount"}
            error={!selectedData.rechargeDiscount && error.rechargeDiscount}
            maxLength={5}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="rechargeCoins"
            value={selectedData.rechargeCoins || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setSelectedData({
                ...selectedData,
                ["rechargeCoins"]: sanitizedText,
              });
            }}
            placeholder="Coin"
            className={`form-control`}
            label={"Coin"}
            error={!selectedData.rechargeCoins && error.rechargeCoins}
            maxLength={5}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default EditRecharge;
