import React, { useState } from 'react'
import ModalBox from '../../components/modal/ModalBox'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import {allowAlphaNumericWithSpace} from '../../utils/regex'
import { Avatar } from '@mui/material'
import { toastMessage } from '../../utils/toastMessage'
import { updateConcernApi } from '../../services/concern/Index'



const EditConcern = ({
  show,
  close,
  concernDetail,
  setConcernDetail,
  listing,
}) => {
  // handle all state

  const [error, setError] = useState("")
  const [updateConcern, setUpdateConcern] = useState({
    id: concernDetail._id,
    title: concernDetail.title,
  })

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader('Update')

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {}
    let status = true

    if (!updateConcern.title) {
      errorMessage['title'] = "Title is mandatory"
      status = false
    }

    setError(errorMessage)
    return status
  }

  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target
    let updatedValue = value
    if (name === 'title') {
      updatedValue = value
    }
    setUpdateConcern({ ...updateConcern, [name]: updatedValue })
  }

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setConcernDetail({})
    setError("")
    close()
  }
  //--------------Update-music-category------------------------
  const updateConcernHandler = async () => {
    const status = validation()
    if (status) {
      try {
        buttonLoader(true)
        const formData ={
            title:updateConcern.title,
            areaOfConcernId:updateConcern.id
        }
        const { data } = await updateConcernApi(formData)
        if (data.code === 200) {
          toastMessage(data.message, 'success', 'updateConcern')
          listing()
          modalClose()
        } else {
          toastMessage(data.message, 'error', 'updateConcern')
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={'Edit Concern'}
      closeIcon
      size={'sm'}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={updateConcernHandler}
          >
            Update
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateConcern?.title || ''}
            required
            onChange={(e) => handleChange(e)}
            placeholder="Concern Title"
            className={`form-control `}
            label={'Title'}
            error={!updateConcern.title && error}
          />
        </div>
      </div>
    </ModalBox>
  )
}

export default EditConcern
