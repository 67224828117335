import React, { useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  regex,
} from "../../utils/regex";
import commonMessages from "../../utils/commonMessages";
import { Avatar } from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";
import { Input } from "../../components/Form";
import ReactDatePicker from "react-datepicker";
import { dateFormatUtc } from "../../utils/dateFormat";
import { addDiscountApi } from "../../services/discountManagement";
import useButtonLoader from "../../hooks/UseButtonLoader";

const AddRecharge = ({
  rechargeModal,
  setRechargeModal,
  fetchDiscountHandler,
  buttonType,
}) => {
  const [error, setError] = useState({});
  const [rechargeField, setRechargeField] = useState({});
  const [image, setImage] = useState("");
  const { imageFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  const modalClose = () => {
    setError({});
    setRechargeField({});
    setRechargeModal(false);
  };

  const validation = () => {
    let status = true;
    let error = {};

    // if (!rechargeField?.title) {
    //   error["title"] = "Name is required *";
    //   status = false;
    // }

    if (!rechargeField?.rechargeAmount) {
      error["rechargeAmount"] = "Amount is required *";
      status = false;
    }
    // if (!rechargeField?.rechargeDiscount) {
    //   error["rechargeDiscount"] = "Discount is required *";
    //   status = false;
    // }
    if (!rechargeField?.rechargeCoins) {
      error["rechargeCoins"] = "Coin is required *";
      status = false;
    }
    setError(error);
    return status;
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  const addRechargeHandler = async () => {
    if (validation()) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("type", buttonType);
        formData.append("title", rechargeField.title);
        formData.append("rechargeAmount", rechargeField.rechargeAmount);
        formData.append("rechargeDiscount", rechargeField.rechargeDiscount);
        formData.append("rechargeCoins", rechargeField.rechargeCoins);
        const { data } = await addDiscountApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addCoupon");
          fetchDiscountHandler();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addCoupon");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={rechargeModal}
      onClose={modalClose}
      title={"Add Recharge Option"}
      size={"sm"}
      closeIcon
      custom
      customizedFooter={
        <div className="model_custom_footer">
          <button
            className="btn_size"
            ref={buttonref}
            onClick={addRechargeHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={rechargeField.title || ""}
            // required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setRechargeField({
                ...rechargeField,
                ["title"]: sanitizedText,
              });
            }}
            placeholder="Name"
            className={`form-control`}
            label={"Name"}
            // error={!rechargeField.title && error.title}
            maxLength={40}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="rechargeAmount"
            value={rechargeField.rechargeAmount || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setRechargeField({
                ...rechargeField,
                ["rechargeAmount"]: sanitizedText,
              });
            }}
            placeholder="Recharge Amount"
            className={`form-control`}
            label={"Amount"}
            error={!rechargeField.rechargeAmount && error.rechargeAmount}
            maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="rechargeDiscount"
            value={rechargeField.rechargeDiscount || ""}
            // required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setRechargeField({
                ...rechargeField,
                ["rechargeDiscount"]: sanitizedText,
              });
            }}
            placeholder="Discount"
            className={`form-control`}
            label={"Discount"}
            error={!rechargeField.rechargeDiscount && error.rechargeDiscount}
            maxLength={5}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="rechargeCoins"
            value={rechargeField.rechargeCoins || ""}
            required
            onChange={(e) => {
              const sanitizedText = allowNumber(e.target.value);
              setRechargeField({
                ...rechargeField,
                ["rechargeCoins"]: sanitizedText,
              });
            }}
            placeholder="Coin"
            className={`form-control`}
            label={"Coin"}
            error={!rechargeField.rechargeCoins && error.rechargeCoins}
            maxLength={5}
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default AddRecharge;
