import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import { Select } from "../../components/Form";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { IoSearch } from "react-icons/io5";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import findSerialNumber from "../../utils/findSerialNumber";
import { toastMessage } from "../../utils/toastMessage";
import common from "../../routes/common";
import { dateFormat } from "../../utils/dateFormat";
import scrollToUp from "../../utils/scrollToUp";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import Loader from "../../comman/Loader";
import {
  fetchQueryListApi,
  fetchqueryListApi,
} from "../../services/supportManagement/index";
import ModalBox from "../../components/modal/ModalBox";
import { emitData, socketConnection } from "../../utils/webSocketServices";
import useButtonLoader from "../../hooks/UseButtonLoader";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionModel from "../../components/imagePreview/DescriptionModel";
import { charLimit } from "../../utils/charLimit";
import { SecondaryButton } from "../../components/Button/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const { NOT_FOUND } = common;

const SupportManagement = () => {
  const [buttonRef, buttonLoader] = useButtonLoader("Yes");
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const [search, setSearch] = useState(
    getLocalStorage("queryList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [queryList, setQueryList] = useState([]);
  const [eventId, setEventId] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const status = searchParam.get("status") || "";
  const userType = searchParam.get("userType") || "";
  const [descriptionModel, setDescriptionModel] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    setLocalStorage("queryList", "search", search);
  }, [search]);

  const statusHandler = (value) => {
    if (status === value) {
      searchParam.delete("status");
    } else {
      searchParam.set("status", Boolean(value));
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  const userTypeHandler = (value) => {
    if (userType === value) {
      searchParam.delete("userType");
    } else {
      searchParam.set("userType", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
  //------------------request-listing-Api----------------------------------
  const getQueryList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
      };
      if (debounceValue) {
        sendData.page = "1";
        sendData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      if (status) {
        sendData.status = status;
      }
      setQueryList([]);
      const { data } = await fetchQueryListApi(sendData);
      if (data.code === 200) {
        setQueryList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setQueryList([]);
        setTotal(0);
        toastMessage(data.message, "error", "queryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const closeModel = () => {
    setViewModal(false);
    setEventId("");
  };

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!status) {
      searchParam.delete("status");
    }
    setSearchParam(searchParam);
    getQueryList(debounceValue);
  }, [searchParam, debounceValue]);

  useEffect(() => {
    if (viewModal) {
      socketConnection();
    }
  }, [viewModal]);

  const endBroadcastHandler = (_id) => {
    setEventId(_id);
    setViewModal(true);
  };

  //*********Handel refresh icon********* */
  const refreshHandler = (event) => {
    setSearch("");
    setSearchParam(searchParam);
    getQueryList();
  };

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Support Management</h4>
            <div className="block_user_button">
              <Link to="/support-management/add">
                <button>
                  <AddIcon />
                  Add Suppport Category
                </button>
              </Link>
            </div>
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>

            <div className="group_filter_value">
              {/* User Type Filter */}
              {/* <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${status && "active"}`}
                  value={userType || ""}
                  onChange={(e) => userTypeHandler(e.target.value)}
                >
                  <option value="user">User</option>
                  <option value="cons">Consultant</option>
                </Select>
              </div> */}
              {/* Status-Filter */}
              {/* <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${status && "active"}`}
                  value={status || ""}
                  onChange={(e) => statusHandler(Boolean(e.target.value))}
                >
                  <option value="">Select Status</option>
                  <option value={0}>Pending</option>
                  <option value={1}>Completed</option>

                </Select>
              </div> */}

              <div className="form-group refresh_button">
                <SecondaryButton onClick={refreshHandler}>
                  <RotateLeftIcon />
                </SecondaryButton>
              </div>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>

                  {/* <th>Consultant ID</th> */}
                  <th>Ticket Number</th>
                  <th>User Name</th>
                  <th>Reported On</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Mobile Number</th>
                  <th>User Type</th>
                  {/* <th>Support Type</th> */}
                  <th>Status</th>
                  <th className="text-center"> Action </th> 
                </tr>
              </thead>
              <tbody>
                {queryList.length > 0 ? (
                  queryList.map((query, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{query?.ticketNumber || "---"}</td>
                          <td className="user_profile_image">
                            {query?.userType === "cons"
                              ? query?.consultantDetails?.name || "---"
                              : query?.userDetails?.name || "---"}
                          </td>
                          {/* <td className="user_profile_image">
                            {query?.userType === "cons"
                              ? query?.consultantDetails?._id || "---"
                              : query?.userDetails?._id || "---"}
                          </td> */}

                          <td>{dateFormat(query.createdAt) || "---"}</td>
                          <td>{query?.title || "---"}</td>
                          {/* <td>{query?.description || "---"}</td> */}
                          <td
                            onClick={() => {
                              if (
                                query.description &&
                                query.description.length > 50
                              ) {
                                setDescriptionModel(true);
                                setDescription(query?.description);
                              }
                            }}
                          >
                            {query?.description &&
                              (query.description.length > 50
                                ? charLimit(query?.description, 50)
                                : query?.description || "---")}
                          </td>
                          <td>
                            {query?.userType === "cons" &&
                              (query?.consultantDetails?.countryCode ||
                                query?.consultantDetails?.mobile)
                              ? query?.consultantDetails?.countryCode +
                              query?.consultantDetails?.mobile || "---"
                              : query?.userDetails?.countryCode +
                              query?.userDetails?.mobile || "---"}
                          </td>
                          <td>
                            {query?.userType === "cons"
                              ? "Consultant"
                              : "User" || "---"}
                          </td>
                          <td>
                            {query?.isResolved === false
                              ? "Pending"
                              : query?.isResolved === true
                                ? "Completed"
                                : ""}
                          </td>

                          <td>
                            <div className="action_button">
                              <Link
                                to={`/support-management/view/${query?.isResolved
                                  }/${query?._id}/${query?.userType === "cons"
                                    ? query?.consultantDetails?.name || "Consultant"
                                    : query?.userDetails?.name || "User"
                                  }`}
                              >
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>
                            </div>
                          </td>
                        {/* {query?<th>{new Date().toLocaleString()}</th> :null
} */}
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={8}
                    msg="Request not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <ModalBox
        status={viewModal}
        onClose={closeModel}
        title="End Live Broadcast"
        description="Are you sure you want to end this live broadcast?"
        // action={endBroadcast}
        ref={buttonRef}
      />

      {DescriptionModel && (
        <DescriptionModel
          show={descriptionModel}
          close={() => {
            setDescriptionModel(false);
          }}
          description={description}
          title={"Description"}
        />
      )}
    </>
  );
};

export default SupportManagement;
