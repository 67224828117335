import React, { useEffect, useState } from "react";
import { block_delete_ContentApi, getContentApi } from "../../../../services/cms";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import findSerialNumber from "../../../../utils/findSerialNumber";
import { CapitalizeFirstWord } from "../../../../utils/CapitalizeFirstWord";
import { dateFormat } from "../../../../utils/dateFormat";
import NotFound from "../../../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import { useSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddSkills from "./AddSkills";
import EditSkill from "./EditSkill";
import AddIcon from '@mui/icons-material/Add';
import ModalBox from "../../../../components/modal/ModalBox";
import Switch from "../../../../components/Switch/Switch";
import { toastMessage } from "../../../../utils/toastMessage";
import useButtonLoader from "../../../../hooks/UseButtonLoader";


const Skills = ({
  userType,
  type,
  title,
  contentData,
  setContentData,
  total,
  setTotal,
}) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [buttonRef,buttonLoader]=useButtonLoader("Yes")
  const [skillId,setSkillId]=useState({});
  const [statusModal,setStatusModal]=useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState({});
  let activePage = +searchParam.get("page") || 1;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const getCmsDetails = async () => {
    try {
      showLoader();
      const { data } = await getContentApi({ contentType: type, userType });
      if (data?.code === 200) {
        setContentData(data?.result.list);
        setTotal(data?.result?.total);
      } else {
        setContentData([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

    // Change Skill Status Handler
    const changeSkillStatusHandler = async () => {
      try {
        buttonLoader(true);
        const res = await block_delete_ContentApi({
          skillsId: skillId.skillId,
          status: (!skillId.status).toString(),
          contentType: type,
        });
        if (res?.data?.code === 200) {
          toastMessage(res?.data?.message, "success", "languageStatus");
          setStatusModal(false);
          setSkillId({});
          getCmsDetails();
        } else {
          toastMessage(res?.data?.message, "error", "languageStatus");
          setSkillId({});
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        buttonLoader(false);
      }
    };

  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const editHandler = (data) => {
    setSelectedSkill(data);
    setEditModal(true);
  };

  useEffect(() => {
    getCmsDetails();
  }, []);
  return (
    <>
      <div className="TitleBox mt-4">
        <h4 className="Title">Skills</h4>
        <div className="block_user_button">
          <button onClick={() => setAddModal(true)}><AddIcon/>Add Skills</button>
        </div>
      </div>
      <div className="content-table">
        <table>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Skills</th>
              <th>Image</th>
              <th className="text-center"> Action </th>
            </tr>
          </thead>
          <tbody>
            {contentData.length > 0 ? (
              contentData.map((user, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>{findSerialNumber(index, activePage, 10)}</td>
                      <td>{user?.title}</td>
                      <td className="user_profile_image">
                        <img
                          src={user.skillImage || user?.skillImage}
                          alt={`${user?.title} profile image`}
                        />
                      </td>
                      <td>
                        <div className="action_button">
                          <IconButton
                            aria-label="update"
                            className="px-2 py-0"
                            onClick={() => editHandler(user)}
                          >
                            <EditIcon className="edit_icon" />
                          </IconButton>
                          <Switch
                            checked={user.status}
                            onChange={() => {
                              setStatusModal(true);
                              setSkillId({
                                skillId: user._id,
                                status: user.status,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <NotFound
                cols={9}
                msg="Skills not found!"
                // icon={NOT_FOUND}
                loader={loader}
              />
            )}
          </tbody>
        </table>
      </div>
      {total > 10 ? (
        <div className="pagination-wrapper">
          <div className="Pagination">
            <Pagination
              activePage={activePage}
              previousLabel={"previous"}
              nextLabel={"next"}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      ) : null}

      <AddSkills
        setAddModal={setAddModal}
        addModal={addModal}
        getCmsDetails={getCmsDetails}
        userType={userType}
        type={type}
      />
      <EditSkill
        setEditModal={setEditModal}
        editModal={editModal}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
        getCmsDetails={getCmsDetails}
        type={type}
      />
            <ModalBox
        status={statusModal}
        onClose={() => setStatusModal(false)}
        title={"Change Status"}
        description={"Are you sure want to change status of this skill?"}
        action={changeSkillStatusHandler}
        ref={buttonRef}
      />
    </>
  );
};

export default Skills;
