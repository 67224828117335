import React, { useState } from "react";
// import NotFound from '../../../components/not-found/NotFound'
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
// import { toastMessage } from '../../../utils/toastMessage'
import { useSearchParams } from "react-router-dom";
// import { getConsultantOrderHistoryApi } from '../../../services/consultantManagement'
// import useFullPageLoader from '../../../hooks/useFullPageLoader'
import Pagination from "react-js-pagination";
import { dateFormatWithTime } from "../../utils/dateFormat";
import NotFound from "../../components/not-found/NotFound";
import { charLimit } from "../../utils/charLimit";
import DescriptionModel from "../../components/imagePreview/DescriptionModel";
// import { dateFormatWithTime } from '../../../utils/dateFormat'

const RemedyListing = ({ handlePageChange, total, activePage, orderList }) => {
  const [description, setDescription] = useState("");
  const [descriptionModel, setDescriptionModel] = useState(false);

  return (
    <div className="content-table">
      <table>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Title</th>
            <th>Area Of Concern</th>
            <th>Remedy</th>
            <th>Description</th>
            <th>Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {orderList.length > 0 ? (
            orderList.map((remedy, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{++index}</td>
                    <td>Title</td>
                    <td>{remedy.areaOfConcern || "---"}</td>
                    <td>{remedy?.remedy}</td>
                    {/* <td>{remedy?.description}</td> */}
                    <td
                      onClick={() => {
                        if (
                          remedy.description &&
                          remedy.description.length > 50
                        ) {
                          setDescriptionModel(true);
                          setDescription(remedy.description);
                        }
                      }}
                    >
                      {remedy?.description &&
                        (remedy.description.length > 50
                          ? charLimit(remedy.description, 50)
                          : remedy.description || "---")}
                    </td>
                    <td>{dateFormatWithTime(remedy?.createdAt) || "---"}</td>
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <NotFound
              cols={6}
              msg="Not Found!"
              icon={<ViewListRoundedIcon sx={{ fontSize: 48 }} />}
              // loader={loader}
            />
          )}
        </tbody>
      </table>
      {total > 1 ? (
        <div className="pagination-wrapper">
          <div className="Pagination">
            <Pagination
              activePage={activePage}
              previousLabel={"previous"}
              nextLabel={"next"}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      ) : null}
      {DescriptionModel && (
        <DescriptionModel
          show={descriptionModel}
          close={() => {
            setDescriptionModel(false);
          }}
          description={description}
          title={"Description"}
        />
      )}
    </div>
  );
};
export default RemedyListing;
