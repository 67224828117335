import React, { useState } from "react";
import ModalBox from "../../../components/modal/ModalBox";
import { Input, TextArea } from "../../../components/Form";
import useButtonLoader from "../../../hooks/UseButtonLoader";
import {
  regex,
  optimizeFunction,
  allowAlphaNumericWithSpace,
} from "../../../utils/regex";
import { toastMessage } from "../../../utils/toastMessage";
import configData from "../../../config/configData";
import {
  addMeditationApiHandler,
  updateMeditationApiHandler,
} from "../../../services/meditationManagement";
import VideoThumbnail from "react-video-thumbnail";
import { TbPlayerPlayFilled } from "react-icons/tb";
import VideoPreview from "../../../components/videoPreview/VideoPreview";
import AudioPreview from "../../../components/audioPreview/AudioPreview";
import {
  checkImageAspectRatio,
  checkImageDimensions,
} from "../../../utils/checkImageDimension";
import commonMessages from "../../../utils/commonMessages";
import ImagePreview from "../../../components/imagePreview/ImagePreview";

// const { imageFileRegex, audioFileRegex, videoFileRegex } = regex;
const { IMAGE_GIF_FORMAT, MESSAGE_SIZE, IMAGE_FORMAT } = commonMessages;
const { imageFileRegex, audioFileRegex, videoFileRegex } = regex;

const EditMeditation = ({
  show,
  close,
  meditationDetail,
  meditationListing,
  meditationCategoryId,
}) => {
  // handle all state

  const [updateMeditation, setUpdateMeditation] = useState({
    id: meditationDetail._id,
    title: meditationDetail.title,
    video: meditationDetail.meditationVideo,
    mantra: meditationDetail.meditationMantra,
    music: meditationDetail.meditationMusic,
    description: meditationDetail?.description,
  });
  const [music, setMusic] = useState("");
  // const [image, setImage] = useState(
  //   meditationDetail?.meditationVideo ? meditationDetail?.meditationVideo : ""
  // );
  const [meditationImage, setMeditationImage] = useState(
    meditationDetail?.meditationVideo ? meditationDetail?.meditationVideo : ""
  );
  const [mantra, setMantra] = useState("");
  const [meditationVideo, setMeditationVideo] = useState({
    video: "",
    thumbnailImage: "",
  });
  const [error, setError] = useState({});
  const [showVideo, setShowVideo] = useState(false);

  console.log(meditationDetail, "Meditation Detail");

  // handle all hooks
  const [buttonref, buttonLoader] = useButtonLoader("Save");

  // handle all function

  // console.log("AddSubCategory",input)
  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!updateMeditation.title) {
      errorMessage["title"] = "Please add meditation title";
      status = false;
    }
    if (!updateMeditation.description) {
      errorMessage["description"] = "Please add meditation description";
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  const resetInputAndMusic = (e, name) => {
    e.target.value = "";
    if (name === "meditationMantra") {
      setMantra("");
    } else if (name === "meditationMusic") {
      setMusic("");
    }
  };

  const resetInputAndVideo = (e) => {
    e.target.value = "";
    setMeditationVideo({
      video: "",
      thumbnailImage: "",
    });
  };

  // Image and gif handler
  // Handlers
  // const gifHandler = async (e) => {
  //   e.preventDefault();
  //   if (e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const fileName = file.name.toLowerCase();
  //     if (fileName.endsWith(".gif")) {
  //       const validAsectRatio = await checkImageAspectRatio(e.target.files[0]);
  //       if (validAsectRatio) {
  //         setImage(file);
  //       } else {
  //         e.target.value = "";
  //         setImage("");
  //       }
  //     }
  //     //  else if (imageFileRegex.test(e.target.files[0].name)) {
  //     //   const validSize = await checkImageDimensions(e.target.files[0]);
  //     //   if (validSize) {
  //     //     setImage(e.target.files[0]);
  //     //   } else {
  //     //     e.target.value = "";
  //     //     setImage("");
  //     //   }
  //     // }
  //     else {
  //       e.target.value = "";
  //       setImage("");
  //       toastMessage(IMAGE_GIF_FORMAT, "error", "imageFormat");
  //     }
  //   } else {
  //     e.target.value = "";
  //     setImage("");
  //   }
  // };

  const imageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      e.target.value = "";
      setMeditationImage("");
      return;
    }

    const fileName = file.name.toLowerCase();
    const isGif = fileName.endsWith(".gif");
    const isValidImage = isGif || imageFileRegex.test(fileName);

    if (!isValidImage) {
      e.target.value = "";
      setMeditationImage("");
      toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      return;
    }

    const isValidAspectRatio = await checkImageAspectRatio(file);

    if (isValidAspectRatio) {
      setMeditationImage(file);
    } else {
      e.target.value = "";
      setMeditationImage("");
    }
  };
  // file handler

  const fileHandler = (e, name, rest) => {
    e.preventDefault();

    const maxFileSizeInBytes =
      name === "music" ? configData.audio_legth : configData.video_legth;
    const maxDurationInSeconds =
      name === "music" ? configData.audio_duration : configData.video_duration;

    const file = e.target.files[0];

    if (!file) {
      name === "music" && resetInputAndMusic(e, rest);
      name === "meditationVideo" && resetInputAndVideo(e);
      return;
    }

    const fileType = name === "music" ? "audio" : "video";
    const validFileTypes =
      fileType === "audio" ? audioFileRegex : videoFileRegex;

    if (!validFileTypes.test(file.name)) {
      name === "music" && resetInputAndMusic(e, rest);
      name === "meditationVideo" && resetInputAndVideo(e);
      toastMessage(
        `Please select a valid ${fileType} file (${
          fileType === "audio" ? "MP3, WAV, or OGG" : "MP4, AVI, or MOV"
        }).`,
        "error",
        "addfile"
      );
      return;
    }

    if (file.size > maxFileSizeInBytes) {
      name === "music" && resetInputAndMusic(e, rest);
      name === "meditationVideo" && resetInputAndVideo(e);
      toastMessage(
        "File size exceeds the limit. Please select a smaller file.",
        "error",
        "addfile"
      );
      return;
    }

    const element = document.createElement(fileType);
    element.src = URL.createObjectURL(file);
    element.onloadedmetadata = () => {
      if (element.duration > maxDurationInSeconds) {
        name === "music" && resetInputAndMusic(e, rest);
        name === "meditationVideo" && resetInputAndVideo(e);
        toastMessage(
          "file duration exceeds the limit. Please select a shorter file.",
          "error",
          "addfile"
        );
      } else {
        if (name === "music") {
          if (rest === "meditationMantra") {
            setMantra(file);
          } else if (rest === "meditationMusic") {
            setMusic(file);
          }
        }
        if (name === "meditationVideo") {
          console.log(file);
          setMeditationVideo({ video: file, thumbnailImage: "" });
          setShowVideo(false);
        }
      }
    };
    element.onerror = () => {
      name === "music" && resetInputAndMusic(e, rest);
      name === "meditationVideo" && resetInputAndVideo(e);
      toastMessage(
        "Error in  loading file. Please try again.",
        "error",
        "addfile"
      );
    };
  };

  //------------------mpdal-close--------------------------
  const modalClose = () => {
    setMusic("");
    setMantra("");
    setUpdateMeditation("");
    setMeditationVideo({
      video: "",
      thumbnailImage: "",
    });
    setError({});
    close();
  };
  //--------------Add-sub-category------------------------
  const updateMeditationHandler = async () => {
    const status = validation();
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("meditationMediaId", updateMeditation.id);
        formData.append("title", updateMeditation.title);
        formData.append("meditationCategoryId", meditationCategoryId);
        if (meditationImage && meditationImage?.name) {
          formData.append("meditationVideo", meditationImage);
        }
        if (music) {
          formData.append("meditationMusic", music);
        }
        if (mantra) {
          formData.append("meditationMantra", mantra);
        }
        formData.append("description", updateMeditation?.description);
        // if (meditationImage && meditationImage?.name) {
        //   formData.append("meditationImage", meditationImage);
        // }
        const { data } = await updateMeditationApiHandler(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "updateMeditationHandler");
          meditationListing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "updateMeditationHandler");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Update Video / Mantra"}
      closeIcon
      custom
      customizedFooter={
        <button
          className="btn_size"
          ref={buttonref}
          onClick={updateMeditationHandler}
        >
          Update
        </button>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={updateMeditation.title || ""}
            required
            onChange={(e) => {
              const sanitizedText = e.target.value;
              setUpdateMeditation({
                ...updateMeditation,
                title: sanitizedText,
              });
            }}
            placeholder="Meditation Title"
            className={`form-control`}
            label={"Meditation Title"}
            error={!updateMeditation.title && error.title}
            maxLength={40}
          />
        </div>

        {/* Adding Image and gif field*/}
        {/* <div className="form-group my-2">
          <Input
            type="file"
            accept="image/gif"
            // name="meditationVideo"
            // required
            onChange={(e) => gifHandler(e)}
            placeholder=""
            className={`form-control `}
            label={"Meditation GIF"}
            error={!image.name && error.video}
          />
        </div>

        {image && (
          <div className="video-container">
            <ImagePreview
              imageUrl={image?.name ? URL.createObjectURL(image) : image}
            />
          </div>
        )} */}

        <div className="form-group my-2">
          <Input
            type="file"
            accept="image/*"
            name="meditationImage"
            // required
            onChange={(e) => imageHandler(e)}
            placeholder=""
            className={`form-control `}
            label={"Meditation Image"}
            error={!meditationImage.name && error.image}
          />
        </div>

        {meditationImage && (
          <div className="video-container">
            <ImagePreview
              imageUrl={
                meditationImage?.name
                  ? URL.createObjectURL(meditationImage)
                  : meditationImage
              }
            />
          </div>
        )}

        {/* <div className="form-group my-2">
          <Input
            type="file"
            accept="video/*"
            name="meditationVideo"
            // value={image.name || ''}
            required
            onChange={(e) => fileHandler(e, 'meditationVideo')}
            placeholder=""
            className={`form-control `}
            label={'Meditation Video'}
            error={!meditationVideo.video && error.video}
            // maxLength={30}
          />
        </div> */}
        {/* {meditationVideo.video &&
          (!showVideo ? (
            <div className="thumbnail-container">
              <div className="video_thumbail">
                <VideoThumbnail
                  key={meditationVideo.video.lastModified}
                  videoUrl={URL.createObjectURL(meditationVideo.video)}
                  thumbnailHandler={(thumb) => {
                    setMeditationVideo((prevMeditationVideo) => ({
                      ...prevMeditationVideo,
                      thumbnailImage: thumb,
                    }))
                  }}
                  height={140}
                />
              </div>
              <span
                className="video_playbutton"
                onClick={() => setShowVideo(true)}
              >
                <TbPlayerPlayFilled className="play-icon" />
              </span>
            </div>
          ) : ( */}

        <div className="video-container">
          {/* <video
            key={meditationVideo.video.lastModified}
            controls
            // className="video"
            // autoPlay
            loop
            controlsList="nodownload noremoteplayback noplaybackrate foobar"
          >
            <source
              src={
                meditationVideo.video
                  ? URL.createObjectURL(meditationVideo.video)
                  : updateMeditation.video
              }
              type="video/mp4"
            />
          </video> */}
          {/* <VideoPreview
            videoUrl={
              meditationVideo.video
                ? URL.createObjectURL(meditationVideo.video)
                : updateMeditation.video
            }
          /> */}
        </div>

        {/* ))} */}

        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="meditationMantra"
            required
            onChange={(e) => fileHandler(e, "music", "meditationMantra")}
            placeholder="Meditation Mantra"
            className={`form-control `}
            label={"Meditation Mantra"}
            // error={!music && error.music}
          />
        </div>

        <div className="music-player">
          {/* <audio
            src={music ? URL.createObjectURL(music) : updateMeditation.music}
            // autoPlay={true}
            controls
            controlsList="nodownload"
          /> */}
          <AudioPreview
            musicUrl={
              mantra ? URL.createObjectURL(mantra) : updateMeditation.mantra
            }
          />
        </div>

        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="meditationMusic"
            required
            onChange={(e) => fileHandler(e, "music", "meditationMusic")}
            placeholder="Meditation Music"
            className={`form-control `}
            label={"Meditation Music"}
            // error={!music && error.music}
          />
        </div>

        <div className="music-player">
          {/* <audio
            src={music ? URL.createObjectURL(music) : updateMeditation.music}
            // autoPlay={true}
            controls
            controlsList="nodownload"
          /> */}
          <AudioPreview
            musicUrl={
              music ? URL.createObjectURL(music) : updateMeditation.music
            }
          />
        </div>

        <div className="form-group my-2">
          <TextArea
            value={updateMeditation?.description}
            onChange={(e) =>
              setUpdateMeditation({
                ...updateMeditation,
                description: e.target.value,
              })
            }
            label={"Medication Description"}
            className={"meditation_description"}
            error={!updateMeditation?.description && error.description}
            required
          />
        </div>
      </div>
    </ModalBox>
  );
};

export default EditMeditation;
