import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { category } from "../../routes/route";
import common from "../../routes/common";
import AddCategory from "./AddCategory";
import {
  fatchCategoryApi,
  fetchCategoryListApi,
} from "../../services/categoryManagement";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import CategoryStatus from "./CategoryStatus";
import AddIcon from "@mui/icons-material/Add";
import { getLocalStorage, setLocalStorage } from "../../config/config";

const { ADD_MEDIA } = category;
const { NOT_FOUND } = common;

const Category = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [search, setSearch] = useState(
    getLocalStorage("category", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");

  const [updatingExpertise, setUpdatingExpertise] = useState(null);


  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  useEffect(() => {
    setLocalStorage("category", "search", search);
  }, [search]);

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // close model

  const closeModel = () => {
    setStatusValue("");
    setStatusId("");
    setStatusModal(false);
  };

  // //------------------listing-Category-Api----------------------------------
  const fetchCategoryHandler = async () => {
    try {
      const reqData = {};
      reqData.page = activePage;
      reqData.limit = 10;
      if (debounceValue) {
        reqData.page = 1;
        reqData.search = debounceValue;
        searchParam.delete("page");
        setSearchParam(searchParam);
      }
      setCategoryList([]);
      showLoader();
      const { data } = await fetchCategoryListApi(reqData);
      if (data.code === 200) {
        setCategoryList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
      } else {
        setCategoryList([]);
        setTotal(0);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchCategoryHandler();
  }, [searchParam, debounceValue]);

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Expertise Management</h4>
            <div className="block_user_button">
              <button onClick={() => setAddCategoryModal(true)}>
                <AddIcon />
                Add Expertise
              </button>
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Expertise Title</th>
                  <th>Expertise Image</th>
                  {/* <th>Category Id</th> */}
                  <th className="text-center"> Action </th>
                </tr>
              </thead>

              <tbody>
                {categoryList.length > 0 ? (
                  categoryList.map((category, index) => {
                    return (
                      <React.Fragment key={category._id}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          <td>{charLimit(category.title, 40) || "---"}</td>
                          <td>
                            <Avatar
                              alt={category.title}
                              src={category.categoryImage}
                              sx={{
                                width: 50,
                                height: 50,
                              }}
                            />
                          </td>
                          {/* <td>{category._id || "---"}</td> */}
                          <td>
                            <div className="action_button">
                              {/* <Link to={`${ADD_MEDIA}/${category._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link> */}
                              <IconButton
                                aria-label="update"
                                className="px-2 py-0 mx-2"
                                onClick={() => {
                                  setUpdatingExpertise(category)
                                }}
                              >
                                <EditNoteIcon className="eye_icon" />
                              </IconButton>
                              <Switch
                                checked={category.status}
                                onChange={() =>
                                  statusUpdateHandler(
                                    category._id,
                                    category.status
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={7}
                    msg="Categories not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
          ) : null}

          {/* <FloatingButton
            onClick={() => setAddModalShow(true)}
            icon={<AddIcon />}
          /> */}
        </div>
        {addCategoryModal && (
          <AddCategory
            show={addCategoryModal}
            close={() => setAddCategoryModal(false)}
            listing={fetchCategoryHandler}
          />
        )}

        {updatingExpertise && (
          <AddCategory
            updatingExpertise={updatingExpertise}
            show={updatingExpertise}
            close={() => setUpdatingExpertise(null)}
            listing={fetchCategoryHandler}
          />
        )}

        {statusModal && (
          <CategoryStatus
            show={statusModal}
            close={closeModel}
            listing={categoryList}
            setListing={setCategoryList}
            status={statusValue}
            id={statusId}
          />
        )}
        {/* <EditSubCategory
          show={updateModal}
          close={() => setUpdateModal(false)}
          data={updateSubCategoryData}
          listing={fatchSubCategory}
        /> */}
      </div>
    </>
  );
};

export default Category;
