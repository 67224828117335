import React from "react";
import { Link, IconButton as MUIIconButton } from "@mui/material";

const IconButton = ({ children, type, role, ...rest }) => {
  const roles = [];
  return (
    <>
      {/* if type is non acl */}
      {type === "acl" &&
        (roles.includes(role) ? (
          <MUIIconButton {...rest}>{children}</MUIIconButton>
        ) : (
          <Link className="disabled-link">
            <MUIIconButton {...rest} onClick={() => {}}>
              {children}
            </MUIIconButton>
          </Link>
        ))}

      {/* if type is acl */}
      {type !== "acl" && <MUIIconButton {...rest}>{children}</MUIIconButton>}
    </>
  );
};

export default IconButton;
