import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from "../../config/axiosInstance";
  
  // ---------------------fetch-request-listing--------------------------
  export const fetchFeedbackListApi = async (data) => {
    const endPoint =
      `/admin/feedback` + "?" + new URLSearchParams({ ...data }).toString();
  
    return await axios.get(endPoint, setJwtToken());
  };

  //  Change Feedback Status
export const block_unblock_Api=async(data)=>{
    const endPoint= `/admin/feedback/block_unblock`
    return await axios.put(endPoint,data,setJwtToken())
  }