export function generateMonthsWithFilterRange(monthsToGenerate) {
    if (monthsToGenerate <= 0) {
        throw new Error("The number of months to generate must be greater than 0.");
    }

    const startMonth = new Date("2024-11-01");
    const currentDate = new Date(); // Get the current date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-based index

    const monthList = [];
    let currentMonthObj = null;

    for (let i = 0; i < monthsToGenerate; i++) {
        const currentMonthDate = new Date(startMonth);
        currentMonthDate.setMonth(startMonth.getMonth() + i);

        const year = currentMonthDate.getFullYear();
        const month = currentMonthDate.getMonth(); // 0-based index

        const filterRangeStart = new Date(year, month, 1);
        const filterRangeEnd = new Date(year, month + 1, 0); // Last day of the month

        const monthObj = {
            monthName: currentMonthDate.toLocaleString('default', { month: 'long' }) + " " + year,
            filterRangeStart: filterRangeStart.toISOString(),
            filterRangeEnd: filterRangeEnd.toISOString(),
            daysInMonth: filterRangeEnd.getDate(),
            isCurrentMonth: year === currentYear && month === currentMonth // Check if the month is the current month
        };

        // Add the month object to the list
        monthList.push(monthObj);

        // Set the currentMonthObj if it's the current month
        if (monthObj.isCurrentMonth) {
            currentMonthObj = monthObj;
        }
    }

    return { currentMonth: currentMonthObj, monthList };
}

export function ShowMonthList({ currentMonth, monthList, selectedMonth, setSelectedMonth }) {
    return <div style={{ width: 200, display: 'flex', flexDirection: 'column', gap: 4, height: '80vh', overflow: 'scroll' }}>
        {monthList?.map(_month => {
            return <div onClick={() => { setSelectedMonth(_month) }}>
                <p style={{
                    border: '1px solid #eee', padding: '7px 12px', borderRadius: '0.3em', fontWeight: '600', fontSize: 17, cursor: 'pointer', color: 'slategray',
                    ...(selectedMonth?.monthName == _month?.monthName ? { backgroundColor: 'blue', color: 'white' } : {})
                }}>{_month.monthName} {_month?.monthName == currentMonth?.monthName && <span style={{ fontWeight: '500', fontSize: 12 }}>***</span>}</p>
            </div>
        })}
    </div>
}