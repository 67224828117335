import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from '../../config/axiosInstance'
  
  // ---------------------fatch-music-listing--------------------------
  export const fetchVastuCategoryApi = async (data) => {
    const endPoint =
      `/admin/vastu` + '?' + new URLSearchParams({ ...data }).toString()
    return await axios.get(endPoint, setJwtToken())
  }

  // // ---------------------add-vastu-category---------------------------
export const addVastuCategoryApi = async (data) => {
    const endPoint = `/admin/vastu/add`
    return await axios.post(endPoint, data, setMultiPartHeader())
  }

  // update vastu cateogry compass
export const updateVastuCategoryApi = async (data) => {
    const endPoint = `/admin/vastu/update`
    return await axios.put(endPoint, data, setMultiPartHeader())
  }

    // view vastu cateogry compass
export const viewVastuCategoryApi = async (data) => {
  const endPoint= `/admin/vastu/zone/details` + '?' + new URLSearchParams({ ...data }).toString()
  return await axios.get(endPoint, setJwtToken())
}
  

  // // ---------------------change-status-category---------------------------
export const changeVastuStatusCategory = async (data) => {
    const endPoint = `/admin/vastu/block_unblock`
    return await axios.put(endPoint, data, setJwtToken())
  }

  // ---------------------------Vastu Zone API -------------------
  export const vastuZoneApi = async (data) => {
    const endPoint = `/admin/vastu/zone`
    return await axios.post(endPoint, data, setMultiPartHeader())
  }