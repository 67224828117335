import React, { useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import usePasswordToggle from '../../hooks copy/usePasswordToggle'
import useMuiButtonLoader from '../../hooks copy/useMuiButtonLoader'
import { Input } from '../../components/Form'
import useButtonLoader from '../../hooks/UseButtonLoader'
import { resetPasswordApi } from '../../services/auth/Auth'
import { toast } from 'sonner'
import commonMessages from '../../utils/commonMessages'
import { regex } from '../../utils/regex'
import { toastMessage } from '../../utils/toastMessage'
const {
  NEW_PASSWORD_NOT_FOUND,
  CONFIRM_PASSWORD_NOT_FOUND,
  MATCH_PASSWORD,
  VALID_PASSWORD,
} = commonMessages
const { password } = regex
console.log('password', password)

const ResetPassword = () => {
  const navigate = useNavigate()

  const [inputType, Icon] = usePasswordToggle('small')
  const [inputType1, Icon1] = usePasswordToggle('small')

  const [error, setError] = useState({})
  const [login, setLogin] = useState({ new_password: '', confirm_password: '' })
  const [buttonref, buttonLoader] = useButtonLoader('Reset password')

  const { token } = useParams()

  // console.log("token",token)
  const handleValidation = () => {
    const error = {}
    let status = true

    if (!login.new_password) {
      error['newPassword'] = NEW_PASSWORD_NOT_FOUND
      status = false
    }

    if (!login.new_password.match(password)) {
      error['matchNewPassword'] = VALID_PASSWORD
      status = false
    }

    if (!login.confirm_password) {
      error['confirmPassword'] = CONFIRM_PASSWORD_NOT_FOUND
      status = false
    }

    if (!login.confirm_password.match(password)) {
      error['matchConfirmPassword'] = VALID_PASSWORD
      status = false
    }

    if (
      login.new_password &&
      login.confirm_password &&
      login.new_password !== login.confirm_password
    ) {
      toastMessage(MATCH_PASSWORD, 'error', 'matchNewConfirm')
      status = false
    }

    setError(error)
    return status
  }

  const onChangeHandler = (event) => {
    const { name, value } = event.target
    setLogin({ ...login, [name]: value })
  }

  const resetPassword = async (e) => {
    e.preventDefault()
    const isValid = handleValidation()
    if (isValid) {
      try {
        buttonLoader(true)
        const sendData = {
          token: token,
          password: login.new_password,
          confirmPassword: login.confirm_password,
        }
        const { data } = await resetPasswordApi(sendData)
        if (data.code === 200) {
          toastMessage(data?.message, 'success', 'resetPassword')
          // buttonLoader(false)
          navigate('/password-reset-success')
        } else {
          toast.success(data?.message, 'error', 'resetPassword')
          // buttonLoader(false)
        }
      } catch (error) {
        // buttonLoader(false)
        console.log('error', error)
      } finally {
        buttonLoader(false)
      }
    }
  }

  return (
    <>
      <div className="LoginArea">
        <h1 className="login_head">Vedic Meet</h1>
        <div className="LoginBox">
          <h5 className="mt-2">Reset Password</h5>
          <form>
            <div className="form-group">
              <Input
                type={inputType}
                name="new_password"
                value={login.password}
                onChange={onChangeHandler}
                placeholder="Enter Password"
                className={`form-control `}
                label={'New Password'}
                error={
                  !login.new_password
                    ? error.newPassword
                    : !login.new_password.match(password) &&
                      error.matchNewPassword
                }
                maxLength={15}
                leftIcon={
                  <span className="Icon">
                    <i className="fa fa-unlock-alt" />
                  </span>
                }
              />
              {/* <span className={error.password ? "error_icon" : "icon"}>{Icon}</span> */}
              <span className="icon">{Icon}</span>
            </div>

            <div className="form-group">
              <Input
                type={inputType1}
                name="confirm_password"
                value={login.password_confirm}
                onChange={onChangeHandler}
                placeholder="Enter Password"
                className={`form-control `}
                label={'Confirm Password'}
                error={
                  !login.confirm_password
                    ? error.confirmPassword
                    : !login.confirm_password.match(password) &&
                      error.matchConfirmPassword
                }
                maxLength={15}
                leftIcon={
                  <span className="Icon">
                    <i className="fa fa-unlock-alt" />
                  </span>
                }
              />
              <span className="icon">{Icon1}</span>
            </div>

            <div className="form_btn">
              <button
                className="login_btn"
                ref={buttonref}
                onClick={resetPassword}
              >
                Reset password <i className="fa fa-sign-in"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
