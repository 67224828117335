import React from 'react'
import ModalBox from '../../../components/modal/ModalBox'
import { charLimit } from '../../../utils/charLimit'
import AudioPreview from '../../../components/audioPreview/AudioPreview'

const PlayMusicMeditation = ({ show, close, musicUrl, musicTitle }) => {
  // handle all function

  return (
    <ModalBox
      status={show}
      onClose={close}
      closeIcon
      title={charLimit(musicTitle, 32)}
      custom
      customizedFooter={
        <button className="btn_size" onClick={close}>
          Close
        </button>
      }
    >
      {musicUrl && <AudioPreview musicUrl={musicUrl} />}
    </ModalBox>
  )
}

export default PlayMusicMeditation
