import { useEffect, useRef, useState } from "react";

const AudioPreview = ({ musicUrl }) => {
  return (
    <div className="music-player">
      <audio
        src={musicUrl}
        // autoPlay={true}
        controls
        controlsList="nodownload"
      />
    </div>
  )
}
export default AudioPreview



export const AudioPlayer2 = ({ mediaUrl }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Pause the audio when the src changes
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, [mediaUrl]);

  return <div>
    <p>Audio</p>
    <audio
      ref={audioRef}
      src={mediaUrl}
      autoPlay={false}
      controls
      controlsList="nodownload"
    />
  </div>
};


export const AudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [formData, setFormData] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        setAudioBlob(audioBlob);
        createFormData(audioBlob);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const createFormData = (blob) => {
    const formData = new FormData();
    formData.append('audio', blob, 'recording.wav');
    setFormData(formData);
  };

  return (
    <div>
      {/* <button onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button> */}

      {audioBlob && (
        <audio controls src={URL.createObjectURL(audioBlob)} />
      )}

      {formData && (
        <div>
          <p>Audio recorded and saved in FormData:</p>
          <p>{formData.get('audio').name}</p>
        </div>
      )}
    </div>
  );
}; 