// import CryptoJS from 'crypto-js'
// import toast from 'sonner'

// export const normalEncryptData = (value) => {
//   const data = CryptoJS.AES.encrypt(
//     value,
//     process.env.REACT_APP_CRYPTO_SECRET_KEY
//   ).toString()

//   return data
// }

// export const normalDecryptData = (data) => {
//   if (data) {
//     const bytes = CryptoJS.AES.decrypt(
//       data,
//       process.env.REACT_APP_CRYPTO_SECRET_KEY
//     )
//     const finalValue = bytes.toString(CryptoJS.enc.Utf8)
//     return finalValue
//   } else {
//     return data
//   }
// }

// export function disableReactDevTools() {
//   // Check if the React Developer Tools global hook exists
//   if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') {
//     return
//   }

//   for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
//     if (prop === 'renderers') {
//       // initialize this with an empty `Map`,
//       // else it will throw an error in console

//       window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
//     } else {
//       // Replace all of its properties with a no-op function or a null value
//       // depending on their types

//       window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
//         typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function'
//           ? () => {}
//           : null
//     }
//   }
// }

// // __________________________  Helper Responses  _______________________________//
// export const failResponse = (res) => {
//   toast.error(res.data?.message || res.message, { id: 'fail' })
// }

// export const errorResponse = (err) => {
//   toast.error(err?.response?.data?.message || err.message)
// }

export const getDataFromLocal = (key) => {
  return localStorage.getItem(key)
}

export function setLocalStorage(parentKey, childKey, value) {
  let localData = localStorage.getItem('admin')
    ? JSON.parse(localStorage.getItem('admin'))
    : {}
  localData = {
    ...localData,
    [parentKey]: { ...localData[parentKey], [childKey]: value },
  }
  localStorage.setItem('admin', JSON.stringify(localData))
}

export function getLocalStorage(parentKey, childKey) {
  let localData = JSON.parse(localStorage.getItem('admin')) || {}
  return localData[parentKey]?.[childKey]
}
