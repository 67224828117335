import moment from "moment";
import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import MultiSelect from "react-select";
import { charLimit } from "../../utils/charLimit";

import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { toastMessage } from "../../utils/toastMessage";

export const Input = ({
  type,
  value,
  onChange,
  className,
  label,
  error,
  required,
  optional,
  loading,
  leftIcon,
  ...rest
}) => {
  return (
    <>
      {label && (
        <label className="my-1 text-dark">
          {label}
          {required && <span className="text-danger"> *</span>}
          {optional && <span className="text-secondary"> (optional)</span>}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={`${className} ${error && "is-invalid"}`}
        {...rest}
        loading={loading ? "" : ""}
      />
      {leftIcon}
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const Select = ({
  label,
  onChange,
  value,
  error,
  className,
  children,
  required,
  loading,
  ...rest
}) => {
  return (
    <>
      {label && <label className="my-1 text-dark">{label}</label>}
      {required && <span className="text-danger"> *</span>}
      <select
        onChange={onChange}
        value={value}
        className={`${className} ${!value && error && "is-invalid"}`}
        {...rest}
        loading={loading ? "" : ""}
      >
        {children}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const TextArea = ({
  value,
  onChange,
  label,
  className,
  error,
  required,
  loading,
  ...rest
}) => {
  return (
    <>
      <div>
        {label && <label className="my-1">{label}</label>}
        {required && <span className="text-danger"> *</span>}
      </div>
      <textarea
        value={value}
        onChange={onChange}
        rows="5"
        className={`${className} form-control ${
          !value && error && "is-invalid"
        }`}
        {...rest}
        loading={loading ? "" : ""}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
};

export const DatePicker = ({
  label,
  required,
  placeholder,
  error,
  className,
  name,
  selected,
  onChange,
  maxDate,
  minDate,
  ...rest
}) => {
  return (
    <>
      {label && <label className="col-form-label">{label}</label>}
      {required && <span className="text-danger"> *</span>}

      <ReactDatePicker
        placeholderText={placeholder}
        className={`${className} ${!selected && error && "is-invalid"}`}
        name={name}
        showIcon
        icon={<SlCalender className="calender_icon" />}
        // isClearable
        showYearDropdown
        yearDropdownItemNumber={10}
        scrollableYearDropdown
        showMonthDropdown
        // useShortMonthInDropdown
        selected={selected ? new Date(selected) : null}
        onChangeRaw={(e) => e.preventDefault()}
        onChange={(date) =>
          onChange(date) ? (date) => onChange(date) : onChange
        }
        dateFormat="dd-MM-yyyy"
        maxDate={maxDate ? new Date(maxDate) : null}
        minDate={minDate ? new Date(minDate) : null}
        {...rest}
      />
      {error && (
        <p className={`invalid-feedback ${!selected && "d-block"}`}>{error}</p>
      )}
    </>
  );
};

// const options = [
//   { value: 'apple', label: 'Apple' },
//   { value: 'banana', label: 'Banana' },
//   { value: 'orange', label: 'Orange' },
// ]

export const CommonSelect = ({
  label,
  onChange,
  value,
  error,
  // format="value",
  className = "",
  options = [],
  isMulti = false,
  isSearchable = false,
  noOptionsMessage = () => "No options found",
  required = false,
  loading = false,
  menuDisable = false,
  ...rest
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FF8989" : "white", // Change background color of selected option
      color: state.isSelected ? "white" : "black", // Change text color of selected option
      "&:hover": {
        backgroundColor: "#FFF2F4", // Change background color of option on hover
        color: "#222", // Change text color of option on hover
      },
    }),
    loadingIndicator: (base, state) => ({
      ...base,
      color: "#FF8989", // Set the color to red
    }),
  };

  const handleChange = (selectedOptions) => {
    console.log(selectedOptions,"SelectedOptions");
    const selectedValues = isMulti
      ? selectedOptions.map((option) => option.value)
      : selectedOptions?.value || null;

    onChange(selectedValues);
  };

  return (
    <div>
      {label && <label className="my-1 text-dark">{label}</label>}
      {required && <span className="text-danger"> *</span>}

      <MultiSelect
        onChange={handleChange}
        value={charLimit(value, 20)}
        className={`${className} ${!value && error && "is-invalid"}`}
        options={options}
        isMulti={isMulti}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        isLoading={loading}
        styles={customStyles}
        openMenuOnClick={!menuDisable}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

// Edit case commonSelect
export const EditCommonSelect = ({
  label,
  onChange,
  value,
  error,
  // format="value",
  className = "",
  options = [],
  isMulti = false,
  isSearchable = false,
  noOptionsMessage = () => "No options found",
  initialSelectedValues = [], 
  required = false,
  loading = false,
  menuDisable = false,
  hideSelectedOptions = false,
  ...rest
}) => {
  // console.log(initialSelectedValues, "IntialSelectedValues");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FF8989" : "white", // Change background color of selected option
      color: state.isSelected ? "white" : "black", // Change text color of selected option
      "&:hover": {
        backgroundColor: "#FFF2F4", // Change background color of option on hover
        color: "#222", // Change text color of option on hover
      },
    }),
    loadingIndicator: (base, state) => ({
      ...base,
      color: "#FF8989", // Set the color to red
    }),
  };

  const handleChange = (selectedOptions) => {
    // console.log("selected option", selectedOptions);
    // const selectedValues =
    //  isMulti
    //   ? 
      // selectedOptions
      // : selectedOptions||null;

    onChange(selectedOptions);
  };

  return (
    <div>
      {label && <label className="my-1 text-dark">{label}</label>}
      {required && <span className="text-danger"> *</span>}

      <MultiSelect
        onChange={handleChange}
        value={charLimit(initialSelectedValues, 20)}
        className={`${className} ${!value && error && "is-invalid"}`}
        options={options}
        isMulti={isMulti}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        isLoading={loading}
        styles={customStyles}
        openMenuOnClick={!menuDisable}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

// mui checkbox

function CheckboxGroup({
  name = "",
  label = "",
  checked,
  onChange,
  className = "",
  value,
}) {
  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              className={className}
              checked={checked}
              onChange={onChange}
              value={value}
              name={name}
              sx={{
                color: "#FF8989",
                "&.Mui-checked": {
                  color: "#FF8989",
                },
              }}
            />
          }
          label={label}
        />
      </FormGroup>
    </FormControl>
  );
}

export default CheckboxGroup;

// export const dateFormat = (date) => {
//   return date ? moment(date).format('DD-MM-yyyy') : '---'
// }

export const dateMonthFormat = (date) => {
  return date ? moment(date).format("DD MMM yyyy") : "---";
};
export const timeFormat = (time) => {
  return time ? moment(time, "HH:mm").format("h:mm A") : "---";
};
export const searchDateFormat = (date) => {
  // return date ? moment(date).format("DD-MM-yyyy") : ""

  return date ? moment(date).format("yyyy-MM-DD") : "";
};
